import { useMemo } from 'react'
import useRoles from './useRoles'

const roleToSelectOption = ({ id, name }) => ({
  value: id,
  label: name
})

const useRolesAsSelectOptions = () => {
  const roles = useRoles()

  return useMemo(() => roles.map(roleToSelectOption), [roles])
}

export default useRolesAsSelectOptions
