import { useMemo } from 'react'
import useNormalizedZoneTypes from './useNormalizedZoneTypes'
import { useTranslation } from 'react-i18next'

const useZoneTypesAsSelectOptions = () => {
  const { t } = useTranslation()
  const zoneTypes = useNormalizedZoneTypes()

  return useMemo(
    () => zoneTypes.map(({ id, name }) => ({
      value: id,
      label: t(`ZoneTypeLabels[${name}]`, name)
    })),
    [zoneTypes, t]
  )
}

export default useZoneTypesAsSelectOptions
