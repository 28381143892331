import { useMemo } from 'react'
import useNormalizedZoneDevices from './useNormalizedZoneDevices'
import useNormalizedDevices from './useNormalizedDevices'

/**
 * Returns assigned devices of the specified zone from state.data.zoneDevices in a non-normalized fashion.
 * @param zoneId {string}: id of zone the assigned devices are requested for.
 * @returns {StoreZoneDevice[]}
 */
const useZoneDevices = zoneId => {
  const normalizedZoneDevices = useNormalizedZoneDevices(zoneId)
  const relatedIds = useMemo(
    () => normalizedZoneDevices?.reduce(
      (relatedIds, zoneDevice) => {
        relatedIds.deviceIds.push(zoneDevice.deviceId)
        return relatedIds
      },
      { deviceIds: [] }
    ),
    [normalizedZoneDevices]
  )
  const devices = useNormalizedDevices(relatedIds.deviceIds, true)

  return useMemo(
    () => normalizedZoneDevices.map(zoneDevice => {
      zoneDevice.device = devices[zoneDevice.deviceId]
      return zoneDevice
    }),
    [normalizedZoneDevices, devices]
  )
}

export default useZoneDevices
