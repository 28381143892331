import DeviceCoordinateService from '../../../services/DeviceCoordinate'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const DeviceCoordinateDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'deviceCoordinate',
  DeviceCoordinateService,
  RESTModelNormalizationSchemas.deviceCoordinate.schema,
  [
    RESTModelNormalizationSchemas.deviceCoordinate.entityName,
    RESTModelNormalizationSchemas.device.entityName
  ]
)

export default DeviceCoordinateDataStoreSagaHandlers
