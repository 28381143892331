import useDataStoreItems from './helpers/useDataStoreItems'
import UserActionDataStoreSelectors from '../selectors/data/UserActions'
import UserActionDataStoreSlice from '../slices/data/UserActionDataStoreSlice'
import StoreUserAction from '../models/UserAction'

const JSONStoreUserActionToStoreUserAction = JSONStoreUserAction => StoreUserAction.FromJSON(JSONStoreUserAction)

/**
 * Returns requested-/all user action(s) from state.data.userActions.
 * @param userActionIds {string | string[] | null}: id of requested user actions. If null, all user actions are
 * requested.
 * @param userIds {string[] | null}: only actions of the specified users are returned. If null, actions of all users are
 * returned.
 * @param actionTypeNames {string[] | null}: only actions with the specified action types are returned. If null, actions
 * of all action types are returned.
 * @param asMap {boolean}: when multiple user actions are requested, return user actions as an object mapping
 * user actions by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all messages, when no message id is passed.
 * @returns {StoreUserAction | StoreUserAction[]}
 */
const useNormalizedUserActions = (
  userActionIds = null,
  userIds = null,
  actionTypeNames = null,
  asMap = false,
  fetchAllEnabled = false
) => useDataStoreItems(
  userActionIds,
  UserActionDataStoreSelectors.userActions,
  UserActionDataStoreSlice.actions.fetch,
  JSONStoreUserActionToStoreUserAction,
  asMap,
  fetchAllEnabled,
  [
    { userIds, actionTypeNames }
  ]
)

export default useNormalizedUserActions
