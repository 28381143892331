import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Search for LoginUserActionDetails.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.LOGIN_USER_ACTION_DETAILS,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

const LoginUserActionDetailService = {
  FindMany
}

export default LoginUserActionDetailService
