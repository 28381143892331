import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import ZoneDeviceDataStoreSagaHandlers from '../../sagas/data/ZoneDeviceDataStore'

const sliceName = 'zoneDevicesStore'

const ZoneDeviceDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  ZoneDeviceDataStoreSagaHandlers
)

export default ZoneDeviceDataStoreSlice
