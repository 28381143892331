import { all, fork } from 'redux-saga/effects'
import ErrorSaga from './Error'
import UserDataStoreSlice from '../slices/data/UserDataStoreSlice'
import FamilyDataStoreSlice from '../slices/data/FamilyDataStoreSlice'
import LanguageDataStoreSlice from '../slices/data/LanguageDataStoreSlice'
import ImageDataStoreSlice from '../slices/data/ImageDataStoreSlice'
import DeviceTypeDataStoreSlice from '../slices/data/DeviceTypeDataStoreSlice'
import DeviceStatusChangeCodeDataStoreSlice from '../slices/data/DeviceStatusChangeCodeDataStoreSlice'
import DeviceStatusDataStoreSlice from '../slices/data/DeviceStatusDataStoreSlice'
import DeviceMarkerTypeDataStoreSlice from '../slices/data/DeviceMarkerTypeDataStoreSlice'
import DeviceAttributeDataStoreSlice from '../slices/data/DeviceAttributeDataStoreSlice'
import DeviceAttributeValueDataStoreSlice from '../slices/data/DeviceAttributeValueDataStoreSlice'
import DeviceCoordinateDataStoreSlice from '../slices/data/DeviceCoordinateDataStoreSlice'
import DeviceInputDataStoreSlice from '../slices/data/DeviceInputDataStoreSlice'
import DeviceModelDataStoreSlice from '../slices/data/DeviceModelDataStoreSlice'
import DeviceModelAttributeDataStoreSlice from '../slices/data/DeviceModelAttributeDataStoreSlice'
import DeviceModelInputDataStoreSlice from '../slices/data/DeviceModelInputDataStoreSlice'
import DeviceModelOutputDataStoreSlice from '../slices/data/DeviceModelOutputDataStoreSlice'
import DeviceOutputDataStoreSlice from '../slices/data/DeviceOutputDataStoreSlice'
import DeviceDataStoreSlice from '../slices/data/DeviceDataStoreSlice'
import FormDataStoreSlice from '../slices/data/FormDataStoreSlice'
import FormFillDataDataStoreSlice from '../slices/data/FormFillDataDataStoreSlice'
import ZoneShapeTypeDataStoreSlice from '../slices/data/ZoneShapeTypeDataStoreSlice'
import ZoneTypeDataStoreSlice from '../slices/data/ZoneTypeDataStoreSlice'
import ZoneDataStoreSlice from '../slices/data/ZoneDataStoreSlice'
import ZoneDeviceDataStoreSlice from '../slices/data/ZoneDeviceDataStoreSlice'
import ServiceDataStoreSlice from '../slices/data/ServiceDataStoreSlice'
import TrackingGroupDataStoreSlice from '../slices/data/TrackingGroupDataStoreSlice'
import MissionDataStoreSlice from '../slices/data/MissionDataStoreSlice'
import MissionObjectiveDataStoreSlice from '../slices/data/MissionObjectiveDataStoreSlice'
import PredefinedEventDataStoreSlice from '../slices/data/PredefinedEventDataStoreSlice'
import MessageDataStoreSlice from '../slices/data/MessageDataStoreSlice'
import MessageCategoryDataStoreSlice from '../slices/data/MessageCategoryDataStoreSlice'
import UserActionDataStoreSlice from '../slices/data/UserActionDataStoreSlice'
import UserActionTypeDataStoreSlice from '../slices/data/UserActionTypeDataStoreSlice'
import LoginUserActionDetailDataStoreSlice from '../slices/data/LoginUserActionDetailDataStoreSlice'
import ChatGroupDataStoreSlice from '../slices/data/ChatGroupDataStoreSlice'
import MapRegionDataStoreSlice from '../slices/data/MapRegionDataStoreSlice'
import DataStoresSaga from './data/DataStores'
import AuthExperienceSaga from './experiences/Auth'
import ImageManagementExperienceSaga from './experiences/ImageManagement'
import UserManagementExperienceSaga from './experiences/UserManagement'
import DeviceManagementExperienceSaga from './experiences/DeviceManagement'
import FamilyManagementExperienceSaga from './experiences/FamilyManagement'
import FormManagementExperienceSaga from './experiences/FormManagement'
import ZoneManagementExperienceSaga from './experiences/ZoneManagement'
import ChatManagementExperienceSaga from './experiences/ChatManagement'
import ServiceManagementExperienceSaga from './experiences/ServiceManagement'
import PredefinedEventManagementExperienceSaga from './experiences/PredefinedEventManagement'
import AudioManagementExperienceSaga from './experiences/AudioManagement'
import MapRegionManagementExperienceSaga from './experiences/MapRegionManagement'
import TrackingGroupManagementExperienceSaga from './experiences/TrackingGroupManagement'

export default function * rootSaga () {
  yield all([
    fork(ErrorSaga),
    fork(UserDataStoreSlice.saga),
    fork(FamilyDataStoreSlice.saga),
    fork(LanguageDataStoreSlice.saga),
    fork(ImageDataStoreSlice.saga),
    fork(DeviceTypeDataStoreSlice.saga),
    fork(DeviceStatusChangeCodeDataStoreSlice.saga),
    fork(DeviceStatusDataStoreSlice.saga),
    fork(DeviceMarkerTypeDataStoreSlice.saga),
    fork(DeviceAttributeDataStoreSlice.saga),
    fork(DeviceAttributeValueDataStoreSlice.saga),
    fork(DeviceCoordinateDataStoreSlice.saga),
    fork(DeviceInputDataStoreSlice.saga),
    fork(DeviceModelDataStoreSlice.saga),
    fork(DeviceModelAttributeDataStoreSlice.saga),
    fork(DeviceModelInputDataStoreSlice.saga),
    fork(DeviceModelOutputDataStoreSlice.saga),
    fork(DeviceOutputDataStoreSlice.saga),
    fork(DeviceDataStoreSlice.saga),
    fork(FormDataStoreSlice.saga),
    fork(FormFillDataDataStoreSlice.saga),
    fork(ZoneShapeTypeDataStoreSlice.saga),
    fork(ZoneTypeDataStoreSlice.saga),
    fork(ZoneDataStoreSlice.saga),
    fork(ZoneDeviceDataStoreSlice.saga),
    fork(ServiceDataStoreSlice.saga),
    fork(TrackingGroupDataStoreSlice.saga),
    fork(MissionDataStoreSlice.saga),
    fork(MissionObjectiveDataStoreSlice.saga),
    fork(PredefinedEventDataStoreSlice.saga),
    fork(MessageDataStoreSlice.saga),
    fork(MessageCategoryDataStoreSlice.saga),
    fork(UserActionDataStoreSlice.saga),
    fork(UserActionTypeDataStoreSlice.saga),
    fork(LoginUserActionDetailDataStoreSlice.saga),
    fork(ChatGroupDataStoreSlice.saga),
    fork(MapRegionDataStoreSlice.saga),
    fork(AuthExperienceSaga),
    fork(DataStoresSaga),
    fork(UserManagementExperienceSaga),
    fork(ImageManagementExperienceSaga),
    fork(DeviceManagementExperienceSaga),
    fork(FamilyManagementExperienceSaga),
    fork(ZoneManagementExperienceSaga),
    fork(FormManagementExperienceSaga),
    fork(ChatManagementExperienceSaga),
    fork(ServiceManagementExperienceSaga),
    fork(PredefinedEventManagementExperienceSaga),
    fork(AudioManagementExperienceSaga),
    fork(MapRegionManagementExperienceSaga),
    fork(TrackingGroupManagementExperienceSaga)
  ])
}
