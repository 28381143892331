import { combineReducers } from 'redux'
import ChatManagementMessageListSlice from '../../slices/experiences/ChatManagementMessageListSlice'
import ChatManagementChatGroupListSlice from '../../slices/experiences/ChatManagementChatGroupListSlice'

const ChatManagementExperienceStore = combineReducers({
  messageList: ChatManagementMessageListSlice.reducer,
  chatGroupList: ChatManagementChatGroupListSlice.reducer
})

export default ChatManagementExperienceStore
