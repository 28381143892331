import TrackingGroupDataStoreSelectors from '../selectors/data/TrackingGroups'
import TrackingGroupDataStoreSlice from '../slices/data/TrackingGroupDataStoreSlice'
import StoreTrackingGroup from '../models/TrackingGroup'
import useDataStoreItems from './helpers/useDataStoreItems'

const JSONStoreTrackingGroupToStoreTrackingGroup = JSONStoreTrackingGroup => StoreTrackingGroup.FromJSON(JSONStoreTrackingGroup)

/**
 * Returns requested-/all tracking group(s) from state.data.trackingGroups.
 * @param trackingGroupIds {string | string[]}: id of requested tracking groups.
 * @param asMap {boolean}: when multiple tracking groups are requested, return tracking groups as an object mapping
 *                        tracking groups by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all tracking groups, when no tracking group id is passed.
 * @returns {StoreTrackingGroup | StoreTrackingGroup[]}
 */
const useNormalizedTrackingGroups = (
  trackingGroupIds = null,
  asMap = false,
  fetchAllEnabled = false
) => useDataStoreItems(
  trackingGroupIds,
  TrackingGroupDataStoreSelectors.trackingGroups,
  TrackingGroupDataStoreSlice.actions.fetch,
  JSONStoreTrackingGroupToStoreTrackingGroup,
  asMap,
  fetchAllEnabled
)

export default useNormalizedTrackingGroups
