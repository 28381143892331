class StoreZone {
  constructor (
    id = undefined,
    name,
    description,
    typeId,
    shapeTypeId,
    centerLatitude,
    centerLongitude,
    radius,
    points,
    corridorWidth,
    corridorPoints,
    active,
    createdAt
  ) {
    this.id = id
    this.name = name
    this.description = description
    this.typeId = typeId
    this.type = null
    this.shapeTypeId = shapeTypeId
    this.shapeType = null
    this.centerLatitude = centerLatitude
    this.centerLongitude = centerLongitude
    this.radius = radius
    this.points = points
    this.corridorWidth = corridorWidth
    this.corridorPoints = corridorPoints
    this.active = active
    this.createdAt = new Date(createdAt)
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      name: this.name,
      description: this.description,
      typeId: this.typeId,
      shapeTypeId: this.shapeTypeId,
      centerLatitude: this.centerLatitude,
      centerLongitude: this.centerLongitude,
      radius: this.radius,
      points: this.points && JSON.stringify(this.points),
      corridorWidth: this.corridorWidth,
      corridorPoints: this.corridorPoints && JSON.stringify(this.corridorPoints),
      active: this.active
      // createdAt is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    name,
    description,
    typeId,
    shapeTypeId,
    centerLatitude,
    centerLongitude,
    radius,
    points,
    corridorWidth,
    corridorPoints,
    active,
    createdAt
  }) {
    return new StoreZone(
      id,
      name,
      description,
      typeId,
      shapeTypeId,
      centerLatitude,
      centerLongitude,
      radius,
      points && JSON.parse(points),
      corridorWidth,
      corridorPoints && JSON.parse(corridorPoints),
      active,
      createdAt
    )
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      typeId: this.typeId,
      shapeTypeId: this.shapeTypeId,
      centerLatitude: this.centerLatitude,
      centerLongitude: this.centerLongitude,
      radius: this.radius,
      points: this.points,
      corridorWidth: this.corridorWidth,
      corridorPoints: this.corridorPoints,
      active: this.active,
      createdAt: this.createdAt
    }
  }

  static FromJSON ({
    id,
    name,
    description,
    typeId,
    shapeTypeId,
    centerLatitude,
    centerLongitude,
    radius,
    points,
    corridorWidth,
    corridorPoints,
    active,
    createdAt
  }) {
    return new StoreZone(
      id,
      name,
      description,
      typeId,
      shapeTypeId,
      centerLatitude,
      centerLongitude,
      radius,
      points,
      corridorWidth,
      corridorPoints,
      active,
      createdAt
    )
  }
}

export default StoreZone
