import DeviceInputService from '../../../services/DeviceInput'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const DeviceInputDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'deviceInput',
  DeviceInputService,
  RESTModelNormalizationSchemas.deviceInput.schema,
  [
    RESTModelNormalizationSchemas.deviceInput.entityName,
    RESTModelNormalizationSchemas.deviceModelInput.entityName,
    RESTModelNormalizationSchemas.predefinedEvent.entityName
  ]
)

export default DeviceInputDataStoreSagaHandlers
