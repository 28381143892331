import MessageService from '../../../services/Message'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const MessageDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'message',
  MessageService,
  RESTModelNormalizationSchemas.message.schema,
  [
    RESTModelNormalizationSchemas.message.entityName,
    RESTModelNormalizationSchemas.messageCategory.entityName,
    RESTModelNormalizationSchemas.device.entityName,
    RESTModelNormalizationSchemas.user.entityName,
    RESTModelNormalizationSchemas.chatGroup.entityName
  ]
)

export default MessageDataStoreSagaHandlers
