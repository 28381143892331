import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Create a new chatGroup.
 * @param chatGroupData {object}: ChatGroup data.
 * @returns {Promise<AxiosResponse<object>>}: newly created chatGroup.
 * @constructor
 */
const Create = async chatGroupData => mainHTTPClient.post(
  MAIN_HTTP_API_ENDPOINTS.CHAT_GROUPS,
  chatGroupData
)

/**
 * Get data of specified chatGroup.
 * @param chatGroupId {string}
 * @returns {Promise<AxiosResponse<object>>}: chatGroup data.
 * @constructor
 */
const GetChatGroupInfo = async chatGroupId => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.CHAT_GROUP.replace(':id', chatGroupId)
)

/**
 * Update the specified chatGroup's data.
 * @param chatGroupId {string}
 * @param chatGroupData {object}
 * @returns {Promise<AxiosResponse<any>>}: updated chatGroup data.
 * @constructor
 */
const Update = async (chatGroupId, chatGroupData) => mainHTTPClient.put(
  MAIN_HTTP_API_ENDPOINTS.CHAT_GROUP.replace(':id', chatGroupId),
  chatGroupData
)

/**
 * Delete specified chatGroup.
 * @param chatGroupId {string}
 * @returns {Promise<AxiosResponse<string>>}: OK in case the deletion was successful.
 * @constructor
 */
const Delete = async chatGroupId => mainHTTPClient.delete(
  MAIN_HTTP_API_ENDPOINTS.CHAT_GROUP.replace(':id', chatGroupId)
)

/**
 *
 * Search for chatGroups.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = async listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.CHAT_GROUPS,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

const ChatGroupService = {
  Create,
  GetChatGroupInfo,
  Update,
  Delete,
  FindMany
}

export default ChatGroupService
