import ZoneDeviceDataStoreSelectors from '../selectors/data/ZoneDevices'
import ZoneDeviceDataStoreSlice from '../slices/data/ZoneDeviceDataStoreSlice'
import StoreZoneDevice from '../models/ZoneDevice'
import useDataStoreItems from './helpers/useDataStoreItems'

const JSONStoreZoneDevicesToStoreZoneDevices = JSONStoreZoneDevices => JSONStoreZoneDevices
  .map(JSONStoreZoneDevice => StoreZoneDevice.FromJSON(JSONStoreZoneDevice))

/**
 * Returns assigned devices of the specified zone from state.data.zoneDevices.
 * @param zoneId {string}: id of zone the assigned devices are requested for.
 * @returns {StoreZoneDevice[]}
 */
const useNormalizedZoneDevices = zoneId => useDataStoreItems(
  zoneId,
  ZoneDeviceDataStoreSelectors.zoneDevices,
  ZoneDeviceDataStoreSlice.actions.fetch,
  JSONStoreZoneDevicesToStoreZoneDevices,
  false
)

export default useNormalizedZoneDevices
