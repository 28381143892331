import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import DeviceInputDataStoreSagaHandlers from '../../sagas/data/DeviceInputDataStore'

const sliceName = 'deviceInputsStore'

const DeviceInputDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  DeviceInputDataStoreSagaHandlers
)

export default DeviceInputDataStoreSlice
