import useDataStoreItems from './helpers/useDataStoreItems'
import DeviceInputDataStoreSelectors from '../selectors/data/DeviceInputs'
import DeviceInputDataStoreSlice from '../slices/data/DeviceInputDataStoreSlice'
import StoreDeviceInput from '../models/DeviceInput'

const JSONStoreDeviceInputToStoreDeviceInput = JSONStoreDeviceInput => StoreDeviceInput.FromJSON(JSONStoreDeviceInput)

/**
 * Returns requested-/all deviceInput(s) from state.data.deviceInputs.
 * @param deviceInputIds {string | string[] | null}: id of requested deviceInputs. If null, all deviceInputs are
 * requested.
 * @param asMap {boolean}: when multiple deviceInputs are requested, return deviceInputs as an object mapping
 * deviceInputs by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all devices, when no device id is passed.
 * @returns {StoreDeviceInput | StoreDeviceInput[]}
 */
const useNormalizedDeviceInputs = (
  deviceInputIds = null,
  asMap = false,
  fetchAllEnabled = false
) => useDataStoreItems(
  deviceInputIds,
  DeviceInputDataStoreSelectors.deviceInputs,
  DeviceInputDataStoreSlice.actions.fetch,
  JSONStoreDeviceInputToStoreDeviceInput,
  asMap,
  fetchAllEnabled
)

export default useNormalizedDeviceInputs
