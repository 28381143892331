class StoreDeviceAttribute {
  constructor (
    id = undefined,
    name,
    configurationJSON
  ) {
    this.id = id
    this.name = name
    this.configurationJSON = configurationJSON
    this.configuration = null
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      name: this.name,
      configurationJSON: this.configurationJSON
    })
  }

  static FromNormalizedRESTModel ({
    id,
    name,
    configurationJSON
  }) {
    return new StoreDeviceAttribute(
      id,
      name,
      configurationJSON
    )
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name,
      configurationJSON: this.configurationJSON
    }
  }

  static FromJSON ({
    id,
    name,
    configurationJSON
  }) {
    return new StoreDeviceAttribute(
      id,
      name,
      configurationJSON
    )
  }
}

export default StoreDeviceAttribute
