import useDataStoreItemsFetchedOnce from './helpers/useDataStoreItemsFetchedOnce'
import DeviceAttributeDataStoreSelectors from '../selectors/data/DeviceAttributes'
import DeviceAttributeDataStoreSlice from '../slices/data/DeviceAttributeDataStoreSlice'
import StoreDeviceAttribute from '../models/DeviceAttribute'

const JSONStoreDeviceAttributeToStoreDeviceAttribute = JSONStoreDeviceAttribute => StoreDeviceAttribute.FromJSON(JSONStoreDeviceAttribute)

/**
 * Returns requested-/all deviceAttribute(s) from state.data.deviceAttributes.
 * @param deviceAttributeIds {string | string[] | null}: id of requested deviceAttributes. If null, all deviceAttributes are requested.
 * @param asMap {boolean}: when multiple deviceAttributes are requested, return deviceAttributes as an object mapping deviceAttributes by their id.
 * @returns {StoreDeviceAttribute | StoreDeviceAttribute[]}
 */
const useNormalizedDeviceAttributes = (deviceAttributeIds = null, asMap = false) => useDataStoreItemsFetchedOnce(
  deviceAttributeIds,
  DeviceAttributeDataStoreSelectors.deviceAttributes,
  DeviceAttributeDataStoreSlice.actions.fetch,
  JSONStoreDeviceAttributeToStoreDeviceAttribute,
  asMap
)

export default useNormalizedDeviceAttributes
