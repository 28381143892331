const DEVICE_MANAGEMENT_EXPERIENCE_TYPES = {
  CREATE: '[deviceManagementExperience] createDevice',
  SET_CREATED: '[deviceManagementExperience] setCreated',
  EDIT: '[deviceManagementExperience] editDevice',
  DELETE: '[deviceManagementExperience] deleteDevice',
  OPEN_DEVICE_CONTROL_PANEL: '[deviceManagementExperience] openDeviceControlPanel',
  CLOSE_DEVICE_CONTROL_PANEL: '[deviceManagementExperience] closeDeviceControlPanel',
  SEND_OUTPUT_TO_DEVICE: '[deviceManagementExperience] sendOutputToDevice',
  LISTEN: '[deviceManagementExperience] listenDevice',
  UPDATE_ATTRIBUTE_ACTIVE: '[deviceManagementExperience] updateAttributeActive',
  DETACH_USER: '[deviceManagementExperience] detachUser',
  SEND_COMMAND: '[deviceManagementExperience] sendCommand',
  FETCH_LOCATION_STRING_FOR_COORDINATE: '[deviceManagementExperience] fetchLocationStringForCoordinate'
}

export default DEVICE_MANAGEMENT_EXPERIENCE_TYPES
