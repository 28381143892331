import RESTModelNormalizationSchemas from './models/RESTModelNormalizationSchemas'
import ChatGroupDataStoreSlice from './slices/data/ChatGroupDataStoreSlice'
import DeviceAttributeDataStoreSlice from './slices/data/DeviceAttributeDataStoreSlice'
import DeviceMarkerTypeDataStoreSlice from './slices/data/DeviceMarkerTypeDataStoreSlice'
import DeviceStatusChangeCodeDataStoreSlice from './slices/data/DeviceStatusChangeCodeDataStoreSlice'
import DeviceStatusDataStoreSlice from './slices/data/DeviceStatusDataStoreSlice'
import DeviceTypeDataStoreSlice from './slices/data/DeviceTypeDataStoreSlice'
import DeviceAttributeValueDataStoreSlice from './slices/data/DeviceAttributeValueDataStoreSlice'
import DeviceCoordinateDataStoreSlice from './slices/data/DeviceCoordinateDataStoreSlice'
import DeviceDataStoreSlice from './slices/data/DeviceDataStoreSlice'
import DeviceInputDataStoreSlice from './slices/data/DeviceInputDataStoreSlice'
import DeviceModelDataStoreSlice from './slices/data/DeviceModelDataStoreSlice'
import DeviceModelAttributeDataStoreSlice from './slices/data/DeviceModelAttributeDataStoreSlice'
import DeviceModelInputDataStoreSlice from './slices/data/DeviceModelInputDataStoreSlice'
import DeviceModelOutputDataStoreSlice from './slices/data/DeviceModelOutputDataStoreSlice'
import DeviceOutputDataStoreSlice from './slices/data/DeviceOutputDataStoreSlice'
import FamilyDataStoreSlice from './slices/data/FamilyDataStoreSlice'
import FormDataStoreSlice from './slices/data/FormDataStoreSlice'
import FormFillDataDataStoreSlice from './slices/data/FormFillDataDataStoreSlice'
import ImageDataStoreSlice from './slices/data/ImageDataStoreSlice'
import LanguageDataStoreSlice from './slices/data/LanguageDataStoreSlice'
import LoginUserActionDetailDataStoreSlice from './slices/data/LoginUserActionDetailDataStoreSlice'
import MapRegionDataStoreSlice from './slices/data/MapRegionDataStoreSlice'
import MessageDataStoreSlice from './slices/data/MessageDataStoreSlice'
import MessageCategoryDataStoreSlice from './slices/data/MessageCategoryDataStoreSlice'
import MissionDataStoreSlice from './slices/data/MissionDataStoreSlice'
import MissionObjectiveDataStoreSlice from './slices/data/MissionObjectiveDataStoreSlice'
import PredefinedEventDataStoreSlice from './slices/data/PredefinedEventDataStoreSlice'
import ServiceDataStoreSlice from './slices/data/ServiceDataStoreSlice'
import TrackingGroupDataStoreSlice from './slices/data/TrackingGroupDataStoreSlice'
import UserActionDataStoreSlice from './slices/data/UserActionDataStoreSlice'
import UserActionTypeDataStoreSlice from './slices/data/UserActionTypeDataStoreSlice'
import UserDataStoreSlice from './slices/data/UserDataStoreSlice'
import ZoneDataStoreSlice from './slices/data/ZoneDataStoreSlice'
import ZoneTypeDataStoreSlice from './slices/data/ZoneTypeDataStoreSlice'
import ZoneShapeTypeDataStoreSlice from './slices/data/ZoneShapeTypeDataStoreSlice'
import ZoneDeviceDataStoreSlice from './slices/data/ZoneDeviceDataStoreSlice'

/** Data-store slices mapped by their associated store normalization entity name  */
export const NORMALIZATION_ENTITY_NAME_TO_DATA_STORE_SLICE_MAPPING = {
  [RESTModelNormalizationSchemas.chatGroup.entityName]: ChatGroupDataStoreSlice,
  [RESTModelNormalizationSchemas.deviceAttribute.entityName]: DeviceAttributeDataStoreSlice,
  [RESTModelNormalizationSchemas.deviceAttributeValue.entityName]: DeviceAttributeValueDataStoreSlice,
  [RESTModelNormalizationSchemas.deviceCoordinate.entityName]: DeviceCoordinateDataStoreSlice,
  [RESTModelNormalizationSchemas.deviceInput.entityName]: DeviceInputDataStoreSlice,
  [RESTModelNormalizationSchemas.deviceMarkerType.entityName]: DeviceMarkerTypeDataStoreSlice,
  [RESTModelNormalizationSchemas.deviceStatusChangeCode.entityName]: DeviceStatusChangeCodeDataStoreSlice,
  [RESTModelNormalizationSchemas.deviceStatus.entityName]: DeviceStatusDataStoreSlice,
  [RESTModelNormalizationSchemas.deviceType.entityName]: DeviceTypeDataStoreSlice,
  [RESTModelNormalizationSchemas.deviceModel.entityName]: DeviceModelDataStoreSlice,
  [RESTModelNormalizationSchemas.deviceModelAttribute.entityName]: DeviceModelAttributeDataStoreSlice,
  [RESTModelNormalizationSchemas.deviceModelInput.entityName]: DeviceModelInputDataStoreSlice,
  [RESTModelNormalizationSchemas.deviceModelOutput.entityName]: DeviceModelOutputDataStoreSlice,
  [RESTModelNormalizationSchemas.deviceOutput.entityName]: DeviceOutputDataStoreSlice,
  [RESTModelNormalizationSchemas.device.entityName]: DeviceDataStoreSlice,
  [RESTModelNormalizationSchemas.family.entityName]: FamilyDataStoreSlice,
  [RESTModelNormalizationSchemas.form.entityName]: FormDataStoreSlice,
  [RESTModelNormalizationSchemas.formFillData.entityName]: FormFillDataDataStoreSlice,
  [RESTModelNormalizationSchemas.image.entityName]: ImageDataStoreSlice,
  [RESTModelNormalizationSchemas.language.entityName]: LanguageDataStoreSlice,
  [RESTModelNormalizationSchemas.loginUserActionDetail.entityName]: LoginUserActionDetailDataStoreSlice,
  [RESTModelNormalizationSchemas.mapRegion.entityName]: MapRegionDataStoreSlice,
  [RESTModelNormalizationSchemas.message.entityName]: MessageDataStoreSlice,
  [RESTModelNormalizationSchemas.messageCategory.entityName]: MessageCategoryDataStoreSlice,
  [RESTModelNormalizationSchemas.mission.entityName]: MissionDataStoreSlice,
  [RESTModelNormalizationSchemas.missionObjective.entityName]: MissionObjectiveDataStoreSlice,
  [RESTModelNormalizationSchemas.predefinedEvent.entityName]: PredefinedEventDataStoreSlice,
  [RESTModelNormalizationSchemas.service.entityName]: ServiceDataStoreSlice,
  [RESTModelNormalizationSchemas.trackingGroup.entityName]: TrackingGroupDataStoreSlice,
  [RESTModelNormalizationSchemas.user.entityName]: UserDataStoreSlice,
  [RESTModelNormalizationSchemas.userAction.entityName]: UserActionDataStoreSlice,
  [RESTModelNormalizationSchemas.userActionType.entityName]: UserActionTypeDataStoreSlice,
  [RESTModelNormalizationSchemas.zone.entityName]: ZoneDataStoreSlice,
  [RESTModelNormalizationSchemas.zoneDevice.entityName]: ZoneDeviceDataStoreSlice,
  [RESTModelNormalizationSchemas.zoneShapeType.entityName]: ZoneShapeTypeDataStoreSlice,
  [RESTModelNormalizationSchemas.zoneType.entityName]: ZoneTypeDataStoreSlice
}
