import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Update the specified mission's data.
 * @param missionId {string}
 * @param missionData {object}
 * @returns {Promise<AxiosResponse<any>>}: updated mission data.
 * @constructor
 */
const Update = async (missionId, missionData) => mainHTTPClient.put(
  MAIN_HTTP_API_ENDPOINTS.MISSION.replace(':id', missionId),
  missionData
)

/**
 *
 * Search for missions.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = async listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.MISSIONS,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

const MissionService = {
  Update,
  FindMany
}

export default MissionService
