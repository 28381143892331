import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Create a new tracking group.
 * @param trackingGroupData {object}: Tracking group data.
 * @returns {Promise<AxiosResponse<object>>}: newly created tracking group.
 * @constructor
 */
const Create = async trackingGroupData => mainHTTPClient.post(
  MAIN_HTTP_API_ENDPOINTS.TRACKING_GROUPS,
  trackingGroupData
)

/**
 * Delete specified tracking group.
 * @param trackingGroupId {string}
 * @returns {Promise<AxiosResponse<string>>}: OK in case the deletion was successful.
 * @constructor
 */
const Delete = async trackingGroupId => mainHTTPClient.delete(
  MAIN_HTTP_API_ENDPOINTS.TRACKING_GROUP.replace(':id', trackingGroupId)
)

/**
 * Update specified tracking group's `active` attribute.
 * @param trackingGroupId {string}
 * @param active {boolean}
 * @returns {Promise<AxiosResponse<any>>}: updated tracking group data.
 * @constructor
 */
const UpdateAttributeActive = async (trackingGroupId, active) => mainHTTPClient.put(
  MAIN_HTTP_API_ENDPOINTS.ZONE_ACTIVE_ATTRIBUTE.replace(':id', trackingGroupId),
  {
    active
  }
)

/**
 *
 * Search for tracking groups.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = async listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.TRACKING_GROUPS,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

const TrackingGroupService = {
  Create,
  Delete,
  UpdateAttributeActive,
  FindMany
}

export default TrackingGroupService
