import useDataStoreItems from './helpers/useDataStoreItems'
import MissionDataStoreSelectors from '../selectors/data/Missions'
import MissionDataStoreSlice from '../slices/data/MissionDataStoreSlice'
import StoreMission from '../models/Mission'

const JSONStoreMissionToStoreMission = JSONStoreMission => StoreMission.FromJSON(JSONStoreMission)

/**
 * Returns requested-/all mission(s) from state.data.missions.
 * @param missionIds {string | string[]}: id of requested missions.
 * @param asMap {boolean}: when multiple missions are requested, return missions as an object mapping missions by their id.
 * @returns {StoreMission | StoreMission[]}
 */
const useNormalizedMissions = (missionIds = null, asMap = false) => useDataStoreItems(
  missionIds,
  MissionDataStoreSelectors.missions,
  MissionDataStoreSlice.actions.fetch,
  JSONStoreMissionToStoreMission,
  asMap
)

export default useNormalizedMissions
