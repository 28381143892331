import { markupRoutesWithPathHelperProperties } from './utilities'

/**
 * Define all routes in the ROUTE_DEFINITIONS constant.
 * Each route:
 *  - should have a path, relative to it's parent, defined inside the `_path` property
 *  - can have one or many sub-routes, defined inside an attribute with their own name
 *  Route definition example:
 *  {
 *    _path: 'routepath',
 *    SUB_ROUTE_1: {
 *      _path: 'subRoute1'
 *    },
 *    SUB_ROUTE_2: {
 *      _path: 'subRoute2',
 *      SUB_ROUTE_1: {
 *        _path: 'subRoute1',
 *      }
 *    }
 *  }
 *  !IMPORTANT:
 *  - Each route has the following property names reserved: `_path`, `_parent`, `RELATIVE_PATH`, `ABSOLUTE_PATH`.
 *  All other properties are considered sub-routes.
 */
const ROUTE_DEFINITIONS = {
  _path: '/',
  LOGIN: {
    _path: 'login'
  },
  ADMIN: {
    _path: 'admin',
    DASHBOARD: {
      _path: 'dashboard'
    },
    MAP: {
      _path: 'map'
    },
    USERS: {
      _path: 'users',
      CREATE: {
        _path: 'create'
      },
      EDIT: {
        _path: ':userId/edit'
      }
    },
    DEVICES: {
      _path: 'devices',
      EDIT: {
        _path: ':deviceId/edit'
      },
      LOGS: {
        _path: ':deviceId/logs'
      }
    },
    TRACKERS: {
      _path: 'trackers',
      EDIT: {
        _path: ':trackerId/edit'
      },
      CREATE: {
        _path: 'create'
      },
      SEND_COMMAND: {
        _path: ':trackerId/send-command'
      }
    },
    FAMILIES: {
      _path: 'families',
      EDIT: {
        _path: ':familyId/edit'
      },
      CREATE: {
        _path: 'create'
      }
    },
    PROFILE: {
      _path: 'profile',
      EDIT: {
        _path: 'edit'
      }
    },
    ZONES: {
      _path: 'zones',
      EDIT: {
        _path: ':zoneId/edit'
      },
      CREATE: {
        _path: 'create'
      },
      DEVICE_ASSIGNATION: {
        _path: ':zoneId/device-assignation'
      }
    },
    FORMS: {
      _path: 'forms',
      EDIT: {
        _path: ':formId/edit'
      },
      CREATE: {
        _path: 'create'
      }
    },
    CHATS: {
      _path: 'chats',
      GROUPS: {
        _path: 'groups',
        CREATE: {
          _path: 'create'
        },
        EDIT: {
          _path: ':chatGroupId/edit'
        }
      }
    },
    SERVICES: {
      _path: 'services',
      SERVICE_DETAILS: {
        _path: ':serviceId'
      }
    },
    PREDEFINED_EVENTS: {
      _path: 'predefined-events',
      EDIT: {
        _path: ':predefinedEventId/edit'
      },
      CREATE: {
        _path: 'create'
      }
    },
    SETTINGS: {
      _path: 'settings',
      EDIT_MESSAGE_CATEGORY: {
        _path: 'message-categories/:messageCategoryId/edit'
      }
    },
    MAP_REGIONS: {
      _path: 'map-regions',
      EDIT: {
        _path: ':mapRegionId/edit'
      },
      CREATE: {
        _path: 'create'
      }
    },
    TRACKING_GROUPS: {
      _path: 'tracking-groups',
      CREATE: {
        _path: 'create'
      }
    }
  }
}

export const ROUTES = markupRoutesWithPathHelperProperties(ROUTE_DEFINITIONS)
