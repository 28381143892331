class StoreFamily {
  constructor (
    id = undefined,
    name,
    description,
    enabled
  ) {
    this.id = id
    this.name = name
    this.description = description
    this.enabled = enabled
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      name: this.name,
      description: this.description,
      enabled: this.enabled
    })
  }

  static FromNormalizedRESTModel ({
    id,
    name,
    description,
    enabled
  }) {
    return new StoreFamily(
      id,
      name,
      description,
      enabled
    )
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      enabled: this.enabled
    }
  }

  static FromJSON ({
    id,
    name,
    description,
    enabled
  }) {
    return new StoreFamily(
      id,
      name,
      description,
      enabled
    )
  }
}

export default StoreFamily
