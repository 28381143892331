import FormFillDataDataStoreSelectors from '../selectors/data/FormFillData'
import FormFillDataDataStoreSlice from '../slices/data/FormFillDataDataStoreSlice'
import StoreFormFillData from '../models/Form/FormFillData'
import useDataStoreItems from './helpers/useDataStoreItems'

const JSONStoreFormFillDataToStoreFormFillData = JSONStoreFormFillData => StoreFormFillData.FromJSON(JSONStoreFormFillData)

/**
 * Returns requested-/all form fill data from state.data.formFillData.
 * @param formFillDataIds {string | string[]}: id of requested formFillData.
 * @param asMap {boolean}: when multiple formFillData are requested, return formFillData as an object mapping formFillData by their id.
 * @returns {StoreFormFillData | StoreFormFillData[]}
 */
const useNormalizedFormFillData = (formFillDataIds = null, asMap = false) => useDataStoreItems(
  formFillDataIds,
  FormFillDataDataStoreSelectors.formFillData,
  FormFillDataDataStoreSlice.actions.fetch,
  JSONStoreFormFillDataToStoreFormFillData,
  asMap
)

export default useNormalizedFormFillData
