import LIST_DATA_FETCH_MANAGEMENT_TYPES from './types'
import createListDataFetchManagementActions from './actions'
import createListDataFetchManagementReducer from './reducer'

/**
 * Creates a redux store slice that manages list data fetching.
 * @param sliceName {string}: unique identifier of the slice.
 * @param initialState {{data: [], isLoading: boolean, totalNumberOfItems: {int}}}: overwrite default initial state.
 * @returns {{types: {FETCH: string, SET_IS_LOADING: string, SET_LIST_DATA: string, RESET_LIST_DATA: string}, reducer: ((function(*=, *): *)|*), actions: {fetch: (function(int, int, SortParameter[], FilterParameter[], FilterExpression.EXPRESSION_TYPE): Object), setIsLoading: (function(boolean): Object), setData: (function([], int, boolean): Object), reset: (function(): Object)}}}
 */
const createListDataFetchManagementStoreSlice = (
  sliceName,
  initialState = ({
    data: [],
    isLoading: false,
    totalNumberOfItems: 0
  })
) => {
  const types = LIST_DATA_FETCH_MANAGEMENT_TYPES(sliceName)

  return {
    reducer: createListDataFetchManagementReducer(types, initialState),
    actions: createListDataFetchManagementActions(types),
    types
  }
}

export default createListDataFetchManagementStoreSlice
