import ImageService from '../../../services/Image'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const ImageDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'image',
  ImageService,
  RESTModelNormalizationSchemas.image.schema,
  [RESTModelNormalizationSchemas.image.entityName]
)

export default ImageDataStoreSagaHandlers
