import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Create a new user.
 * @param deviceAttributeValueData {object}
 * @returns {Promise<AxiosResponse<object>>}: newly created deviceAttributeValue.
 * @constructor
 */
const Create = async deviceAttributeValueData => mainHTTPClient.post(
  MAIN_HTTP_API_ENDPOINTS.DEVICE_ATTRIBUTE_VALUES,
  deviceAttributeValueData
)

/**
 * Get data of specified deviceAttributeValue.
 * @param deviceAttributeValueId {string}
 * @returns {Promise<AxiosResponse<object>>}: deviceAttributeValue data.
 * @constructor
 */
const GetDeviceAttributeValueInfo = async deviceAttributeValueId => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.DEVICE_ATTRIBUTE_VALUE.replace(':id', deviceAttributeValueId)
)

/**
 * Update the specified deviceAttributeValue's data.
 * @param deviceAttributeValueId {string}
 * @param deviceAttributeValueData {object}
 * @returns {Promise<AxiosResponse<any>>}: updated device attribute value data.
 * @constructor
 */
const Update = async (deviceAttributeValueId, deviceAttributeValueData) => mainHTTPClient.put(
  MAIN_HTTP_API_ENDPOINTS.DEVICE_ATTRIBUTE_VALUE.replace(':id', deviceAttributeValueId),
  deviceAttributeValueData
)

/**
 * Search for deviceAttributeValues.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.DEVICE_ATTRIBUTE_VALUES,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

const DeviceAttributeValueService = {
  Create,
  GetDeviceAttributeValueInfo,
  Update,
  FindMany
}

export default DeviceAttributeValueService
