import { useMemo } from 'react'
import useNormalizedDeviceOutputs from './useNormalizedDeviceOutputs'
import uniq from 'lodash/uniq'
import useNormalizedDevices from './useNormalizedDevices'
import useNormalizedDeviceModelOutputs from './useNormalizedDeviceModelOutputs'

/**
 * Returns requested device output(s) from state.data.device outputs in a normalized fashion.
 * @param deviceOutputIds {string | string[]}: id of requested device outputs.
 * @param asMap {boolean}: when multiple device outputs are requested, return device outputs as an object mapping device outputs by their id.
 * @param deNormalizationConfig {{device: boolean, deviceModelOutput: boolean}}: specify which
 * related models are merged into the device output object. Default config returns the same result as useNormalizedDeviceOutputs.
 * @param fetchAllEnabled {boolean}: enable fetching of all device outputs, when no device output id is passed.
 * @returns {StoreDeviceOutput | StoreDeviceOutput[]}
 */
const useDeviceOutputs = (
  deviceOutputIds = null,
  asMap = false,
  deNormalizationConfig = {
    device: false,
    deviceModelOutput: true
  },
  fetchAllEnabled = false
) => {
  const normalizedDeviceOutputs = useNormalizedDeviceOutputs(deviceOutputIds, asMap, fetchAllEnabled)
  const relatedIds = useMemo(
    () => {
      const reducedDeviceOutputs = typeof deviceOutputIds === 'string'
        ? asMap
          ? normalizedDeviceOutputs && { [normalizedDeviceOutputs.id]: normalizedDeviceOutputs }
          : normalizedDeviceOutputs && [normalizedDeviceOutputs]
        : normalizedDeviceOutputs

      const relatedIds = (
        asMap
          ? Object.values(reducedDeviceOutputs || {})
          : reducedDeviceOutputs || []
      ).reduce(
        (relatedIds, deviceOutput) => {
          deviceOutput.deviceId && relatedIds.deviceIds.push(deviceOutput.deviceId)
          deviceOutput.deviceModelOutputId && relatedIds.deviceModelOutputIds.push(deviceOutput.deviceModelOutputId)
          return relatedIds
        },
        { deviceIds: [], deviceModelOutputIds: [] }
      )
      relatedIds.photoIds = uniq(relatedIds.photoIds)

      return relatedIds
    },
    [normalizedDeviceOutputs, asMap, deviceOutputIds]
  )
  const devices = useNormalizedDevices(
    deNormalizationConfig.device
      ? relatedIds?.deviceIds
      : null,
    true,
    false
  )
  const deviceModelOutputs = useNormalizedDeviceModelOutputs(
    deNormalizationConfig.deviceModelOutput
      ? relatedIds?.deviceModelOutputIds
      : null,
    true,
    false
  )

  return useMemo(
    () => {
      const populateDeviceOutput = deviceOutput => {
        if (deNormalizationConfig.device) {
          deviceOutput.device = devices[deviceOutput.deviceId]
        }
        if (deNormalizationConfig.deviceModelOutput) {
          deviceOutput.deviceModelOutput = deviceModelOutputs[deviceOutput.deviceModelOutputId]
        }
        return deviceOutput
      }

      return normalizedDeviceOutputs && (
        typeof deviceOutputIds === 'string' || typeof deviceOutputIds === 'number'
          ? populateDeviceOutput(normalizedDeviceOutputs)
          : asMap
            ? Object.keys(normalizedDeviceOutputs)
              .reduce((deviceOutputs, deviceOutputId) => {
                deviceOutputs[deviceOutputId] = populateDeviceOutput(normalizedDeviceOutputs[deviceOutputId])
                return deviceOutputs
              }, {})
            : normalizedDeviceOutputs.map(populateDeviceOutput)
      )
    },
    [
      normalizedDeviceOutputs, deviceOutputIds, devices, deviceModelOutputs, asMap, deNormalizationConfig
    ]
  )
}

export default useDeviceOutputs
