import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import DeviceModelInputDataStoreSagaHandlers from '../../sagas/data/DeviceModelInputDataStore'

const sliceName = 'deviceModelInputsStore'

const DeviceModelInputDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  DeviceModelInputDataStoreSagaHandlers
)

export default DeviceModelInputDataStoreSlice
