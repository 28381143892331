import axios from 'axios'
import { MAIN_HTTP_API_CONFIG } from '../constants/api/mainHttp'
import mainAuth0Client from './mainAuth0Client'

const mainHTTPClient = axios.create({
  baseURL: MAIN_HTTP_API_CONFIG.URL
})

// original auth
let authInterceptor = null

export const authorizeHttpClient = authToken => {
  authInterceptor = mainHTTPClient.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${authToken}`
    return config
  })
}

// auth0 auth
let auth0Interceptor = null

const auth0InterceptingHandler = async config => {
  const authToken = await mainAuth0Client.instance.getTokenSilently({})
  config.headers.Authorization = `Bearer ${authToken}`

  return config
}

export const authorizeHttpClientWithAuth0 = () => {
  auth0Interceptor = mainHTTPClient.interceptors.request.use(auth0InterceptingHandler)
}

// unauthorizing all types of auth
export const unAuthorizeHttpClient = () => {
  typeof authInterceptor !== 'undefined' &&
  authInterceptor !== null &&
  mainHTTPClient.interceptors.request.eject(authInterceptor)
  // unauthorize auth0
  typeof auth0Interceptor !== 'undefined' &&
  auth0Interceptor !== null &&
  mainHTTPClient.interceptors.request.eject(auth0Interceptor)
}

// TODO: implement general response handling by registering interceptors
export const registerInterceptor = () => {}

export default mainHTTPClient
