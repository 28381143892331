import useNormalizedFamilies from './useNormalizedFamilies'
import useNormalizedLanguages from './useNormalizedLanguages'
import useRoles from './useRoles'
import { useMemo } from 'react'
import useNormalizedProfile from './useNormalizedProfile'
import useDevices from './useDevices'
import useNormalizedImages from './useNormalizedImages'

/**
 * Logged in user's profile.
 * @returns {StoreProfile | null}
 */
const useProfile = () => {
  const normalizedProfile = useNormalizedProfile()
  const families = useNormalizedFamilies(null, true)
  const languages = useNormalizedLanguages(null, true)
  const roles = useRoles(null, true)
  const photo = useNormalizedImages(normalizedProfile?.photoId)
  const devices = useDevices(normalizedProfile?.deviceIds, false, false)

  return useMemo(
    () => {
      if (normalizedProfile) {
        normalizedProfile.role = roles[normalizedProfile.roleId]
        normalizedProfile.family = families[normalizedProfile.familyId]
        normalizedProfile.language = languages[normalizedProfile.languageId]
        normalizedProfile.photo = photo
        normalizedProfile.devices = devices
      }

      return normalizedProfile
    },
    [normalizedProfile, families, languages, roles, photo, devices]
  )
}

export default useProfile
