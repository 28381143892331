import UserActionTypeService from '../../../services/UserActionType'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const UserActionTypeDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'userActionType',
  UserActionTypeService,
  RESTModelNormalizationSchemas.userActionType.schema,
  [RESTModelNormalizationSchemas.userActionType.entityName]
)

export default UserActionTypeDataStoreSagaHandlers
