import useDataStoreItems from './helpers/useDataStoreItems'
import StoreForm from '../models/Form/Form'
import FormDataStoreSelectors from '../selectors/data/Forms'
import FormDataStoreSlice from '../slices/data/FormDataStoreSlice'

const JSONStoreFormToStoreForm = JSONStoreForm => StoreForm.FromJSON(JSONStoreForm)

/**
 * Returns requested-/all form(s) from state.data.forms.
 * @param formIds {string | string[]}: id of requested forms.
 * @param asMap {boolean}: when multiple forms are requested, return forms as an object mapping forms by their id.
 * @returns {StoreForm | StoreForm[]}
 */
const useNormalizedForms = (formIds = null, asMap = false) => useDataStoreItems(
  formIds,
  FormDataStoreSelectors.forms,
  FormDataStoreSlice.actions.fetch,
  JSONStoreFormToStoreForm,
  asMap
)

export default useNormalizedForms
