import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import FamilyDataStoreSagaHandlers from '../../sagas/data/FamilyDataStore'

const sliceName = 'familiesStore'

const FamilyDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  FamilyDataStoreSagaHandlers
)

export default FamilyDataStoreSlice
