import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import DeviceTypeDataStoreSagaHandlers from '../../sagas/data/DeviceTypeDataStore'

const sliceName = 'deviceTypesStore'

const DeviceTypeDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  DeviceTypeDataStoreSagaHandlers
)

export default DeviceTypeDataStoreSlice
