import useNormalizedFamilies from './useNormalizedFamilies'
import useNormalizedLanguages from './useNormalizedLanguages'
import useRoles from './useRoles'
import { useMemo } from 'react'
import useNormalizedUsers from './useNormalizedUsers'
import uniq from 'lodash/uniq'
import useNormalizedImages from './useNormalizedImages'
import useNormalizedDevices from './useNormalizedDevices'

/**
 * Returns requested user(s) from state.data.users in a normalized fashion.
 * @param userIds {string | string[]}: id of requested users.
 * @param asMap {boolean}: when multiple users are requested, return users as an object mapping users by their id.
 * @param deNormalizationConfig {{family: boolean, language: boolean, role: boolean, photo: boolean, devices: boolean}}:
 * specify which related models are merged into the user object. Default config returns the same result as
 * useNormalizedUsers.
 * @param fetchAllEnabled {boolean}: enable fetching of all users, when no user id is passed.
 * @returns {StoreUser | StoreUser[]}
 */
const useUsers = (
  userIds = null,
  asMap = false,
  deNormalizationConfig = {
    family: false,
    language: false,
    role: false,
    photo: false,
    devices: false
  },
  fetchAllEnabled = false
) => {
  const normalizedUsers = useNormalizedUsers(userIds, asMap, fetchAllEnabled)
  const families = useNormalizedFamilies(null, true)
  const languages = useNormalizedLanguages(null, true)
  const roles = useRoles(null, true)
  const relatedIds = useMemo(
    () => {
      const reducedUsers = typeof userIds === 'string'
        ? asMap
          ? normalizedUsers && { [normalizedUsers.id]: normalizedUsers }
          : normalizedUsers && [normalizedUsers]
        : normalizedUsers

      const relatedIds = (
        asMap
          ? Object.values(reducedUsers || {})
          : reducedUsers || []
      ).reduce(
        (relatedIds, user) => {
          user.photoId && relatedIds.photoIds.push(user.photoId)
          user.deviceIds?.length && (relatedIds.deviceIds = relatedIds.deviceIds.concat(user.deviceIds))
          return relatedIds
        },
        { photoIds: [], deviceIds: [] }
      )
      relatedIds.photoIds = uniq(relatedIds.photoIds)
      relatedIds.deviceIds = uniq(relatedIds.deviceIds)

      return relatedIds
    },
    [normalizedUsers, asMap, userIds]
  )
  const photos = useNormalizedImages(
    deNormalizationConfig.photo ? relatedIds?.photoIds : null,
    true,
    false
  )
  const devices = useNormalizedDevices(
    deNormalizationConfig.devices ? relatedIds?.deviceIds : null,
    true,
    false
  )

  return useMemo(
    () => {
      const populateUser = user => {
        if (deNormalizationConfig.role) {
          user.role = roles[user.roleId]
        }
        if (deNormalizationConfig.family) {
          user.family = families[user.familyId]
        }
        if (deNormalizationConfig.language) {
          user.language = languages[user.languageId]
        }
        if (deNormalizationConfig.photo) {
          user.photo = photos[user.photoId]
        }
        if (deNormalizationConfig.devices) {
          user.devices = user.deviceIds?.map(deviceId => devices[deviceId])
        }
        return user
      }

      return normalizedUsers && (
        typeof userIds === 'string' || typeof userIds === 'number'
          ? populateUser(normalizedUsers)
          : asMap
            ? Object.keys(normalizedUsers)
              .reduce((users, userId) => {
                users[userId] = populateUser(normalizedUsers[userId])
                return users
              }, {})
            : normalizedUsers.map(populateUser)
      )
    },
    [normalizedUsers, userIds, families, languages, roles, photos, devices, asMap, deNormalizationConfig]
  )
}

export default useUsers
