import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Search for UserActions.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.USER_ACTIONS,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

const UserActionService = {
  FindMany
}

export default UserActionService
