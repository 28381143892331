class StoreForm {
  constructor (
    id = undefined,
    name,
    jsonSchema,
    enabled,
    createdAt
  ) {
    this.id = id
    this.name = name
    this.jsonSchema = jsonSchema
    this.configuration = null
    this.enabled = enabled
    this.createdAt = new Date(createdAt)
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      name: this.name,
      jsonSchema: this.jsonSchema,
      enabled: this.enabled
      // createdAt is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    name,
    jsonSchema,
    enabled,
    createdAt
  }) {
    return new StoreForm(
      id,
      name,
      jsonSchema,
      enabled,
      createdAt
    )
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name,
      jsonSchema: this.jsonSchema,
      enabled: this.enabled,
      createdAt: this.createdAt
    }
  }

  static FromJSON ({
    id,
    name,
    jsonSchema,
    enabled,
    createdAt
  }) {
    return new StoreForm(
      id,
      name,
      jsonSchema,
      enabled,
      createdAt
    )
  }
}

export default StoreForm
