class StoreService {
  constructor (
    id = undefined,
    creatorId,
    dataId,
    startedAt,
    finishedAt,
    missionIds
  ) {
    this.id = id
    this.creatorId = creatorId
    this.creator = null
    this.dataId = dataId
    this.data = null
    this.startedAt = startedAt && new Date(startedAt)
    this.finishedAt = finishedAt && new Date(finishedAt)
    this.missionIds = missionIds
    this.missions = null
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      // creatorId is read-only
      // dataId is read-only
      // startedAt is read-only
      // finishedAt is read-only
      // missionIds is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    creatorId,
    dataId,
    startedAt,
    finishedAt,
    missionIds
  }) {
    return new StoreService(
      id,
      creatorId,
      dataId,
      startedAt,
      finishedAt,
      missionIds
    )
  }

  toJSON () {
    return {
      id: this.id,
      creatorId: this.creatorId,
      dataId: this.dataId,
      startedAt: this.startedAt,
      finishedAt: this.finishedAt,
      missionIds: this.missionIds
    }
  }

  static FromJSON ({
    id,
    creatorId,
    dataId,
    startedAt,
    finishedAt,
    missionIds
  }) {
    return new StoreService(
      id,
      creatorId,
      dataId,
      startedAt,
      finishedAt,
      missionIds
    )
  }
}

export default StoreService
