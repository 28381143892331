import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Get assigned devices of specified zone.
 * @param zoneId {string}
 * @returns {Promise<AxiosResponse<object>>}: ZoneDevice data.
 * @constructor
 */
const GetByZoneId = async zoneId => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.ZONE_DEVICE.replace(':zoneId', zoneId)
)

/**
 * Update zone-assigned state of specified devices.
 * @param zoneId {string}
 * @param assignationData {{ deviceIds: "all" | string[], assigned: boolean }}
 * @returns {Promise<AxiosResponse<any>>}: updated zone data.
 * @constructor
 */
const UpdateZoneDeviceAssignationState = async (zoneId, assignationData) => mainHTTPClient.put(
  MAIN_HTTP_API_ENDPOINTS.ZONE_DEVICE.replace(':zoneId', zoneId),
  assignationData
)

const ZoneDeviceService = {
  GetByZoneId,
  UpdateZoneDeviceAssignationState
}

export default ZoneDeviceService
