import ZoneService from '../../../services/Zone'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const ZoneDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'zone',
  ZoneService,
  RESTModelNormalizationSchemas.zone.schema,
  [RESTModelNormalizationSchemas.zone.entityName]
)

export default ZoneDataStoreSagaHandlers
