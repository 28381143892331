import { combineReducers } from 'redux'
import ZoneManagementZoneListSlice from '../../slices/experiences/ZoneManagementZoneListSlice'
import ZoneManagementZoneListExportSlice from '../../slices/experiences/ZoneManagementZoneListExportSlice'

const ZoneManagementExperienceStore = combineReducers({
  list: ZoneManagementZoneListSlice.reducer,
  listExport: ZoneManagementZoneListExportSlice.reducer
})

export default ZoneManagementExperienceStore
