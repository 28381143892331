import { useMemo } from 'react'
import useNormalizedDeviceInputs from './useNormalizedDeviceInputs'
import uniq from 'lodash/uniq'
import useNormalizedDevices from './useNormalizedDevices'
import useNormalizedDeviceModelInputs from './useNormalizedDeviceModelInputs'
import useNormalizedPredefinedEvents from './useNormalizedPredefinedEvents'

/**
 * Returns requested device input(s) from state.data.device inputs in a normalized fashion.
 * @param deviceInputIds {string | string[]}: id of requested device inputs.
 * @param asMap {boolean}: when multiple device inputs are requested, return device inputs as an object mapping device inputs by their id.
 * @param deNormalizationConfig {{device: boolean, deviceModelInput: boolean, predefinedEvent: boolean}}: specify which
 * related models are merged into the device input object. Default config returns the same result as useNormalizedDeviceInputs.
 * @param fetchAllEnabled {boolean}: enable fetching of all device inputs, when no device input id is passed.
 * @returns {StoreDeviceInput | StoreDeviceInput[]}
 */
const useDeviceInputs = (
  deviceInputIds = null,
  asMap = false,
  deNormalizationConfig = {
    device: false,
    deviceModelInput: true,
    predefinedEvent: true
  },
  fetchAllEnabled = false
) => {
  const normalizedDeviceInputs = useNormalizedDeviceInputs(deviceInputIds, asMap, fetchAllEnabled)
  const relatedIds = useMemo(
    () => {
      const reducedDeviceInputs = typeof deviceInputIds === 'string'
        ? asMap
          ? normalizedDeviceInputs && { [normalizedDeviceInputs.id]: normalizedDeviceInputs }
          : normalizedDeviceInputs && [normalizedDeviceInputs]
        : normalizedDeviceInputs

      const relatedIds = (
        asMap
          ? Object.values(reducedDeviceInputs || {})
          : reducedDeviceInputs || []
      ).reduce(
        (relatedIds, deviceInput) => {
          deviceInput.deviceId && relatedIds.deviceIds.push(deviceInput.deviceId)
          deviceInput.deviceModelInputId && relatedIds.deviceModelInputIds.push(deviceInput.deviceModelInputId)
          deviceInput.predefinedEventId && relatedIds.predefinedEventIds.push(deviceInput.predefinedEventId)
          return relatedIds
        },
        { deviceIds: [], deviceModelInputIds: [], predefinedEventIds: [] }
      )
      relatedIds.photoIds = uniq(relatedIds.photoIds)

      return relatedIds
    },
    [normalizedDeviceInputs, asMap, deviceInputIds]
  )
  const devices = useNormalizedDevices(
    deNormalizationConfig.device
      ? relatedIds?.deviceIds
      : null,
    true,
    false
  )
  const deviceModelInputs = useNormalizedDeviceModelInputs(
    deNormalizationConfig.deviceModelInput
      ? relatedIds?.deviceModelInputIds
      : null,
    true,
    false
  )
  const predefinedEvents = useNormalizedPredefinedEvents(
    deNormalizationConfig.predefinedEvent
      ? relatedIds?.predefinedEventIds
      : null,
    true,
    false
  )

  return useMemo(
    () => {
      const populateDeviceInput = deviceInput => {
        if (deNormalizationConfig.device) {
          deviceInput.device = devices[deviceInput.deviceId]
        }
        if (deNormalizationConfig.deviceModelInput) {
          deviceInput.deviceModelInput = deviceModelInputs[deviceInput.deviceModelInputId]
        }
        if (deNormalizationConfig.predefinedEvent) {
          deviceInput.predefinedEvent = predefinedEvents[deviceInput.predefinedEventId]
        }
        return deviceInput
      }

      return normalizedDeviceInputs && (
        typeof deviceInputIds === 'string' || typeof deviceInputIds === 'number'
          ? populateDeviceInput(normalizedDeviceInputs)
          : asMap
            ? Object.keys(normalizedDeviceInputs)
              .reduce((deviceInputs, deviceInputId) => {
                deviceInputs[deviceInputId] = populateDeviceInput(normalizedDeviceInputs[deviceInputId])
                return deviceInputs
              }, {})
            : normalizedDeviceInputs.map(populateDeviceInput)
      )
    },
    [
      normalizedDeviceInputs, deviceInputIds, devices, deviceModelInputs, predefinedEvents, asMap, deNormalizationConfig
    ]
  )
}

export default useDeviceInputs
