import useDataStoreItemsFetchedOnce from './helpers/useDataStoreItemsFetchedOnce'
import DeviceMarkerTypeDataStoreSelectors from '../selectors/data/DeviceMarkerTypes'
import DeviceMarkerTypeDataStoreSlice from '../slices/data/DeviceMarkerTypeDataStoreSlice'
import StoreDeviceMarkerType from '../models/DeviceMarkerType'

const JSONStoreDeviceMarkerTypeToStoreDeviceMarkerType = JSONStoreDeviceMarkerType => StoreDeviceMarkerType.FromJSON(JSONStoreDeviceMarkerType)

/**
 * Returns requested-/all deviceMarkerType(s) from state.data.deviceMarkerTypes.
 * @param deviceMarkerTypeIds {string | string[] | null}: id of requested deviceMarkerTypes. If null, all deviceMarkerTypes are requested.
 * @param asMap {boolean}: when multiple deviceMarkerTypes are requested, return deviceMarkerTypes as an object mapping deviceMarkerTypes by their id.
 * @returns {StoreDeviceMarkerType | StoreDeviceMarkerType[]}
 */
const useNormalizedDeviceMarkerTypes = (deviceMarkerTypeIds = null, asMap = false) => useDataStoreItemsFetchedOnce(
  deviceMarkerTypeIds,
  DeviceMarkerTypeDataStoreSelectors.deviceMarkerTypes,
  DeviceMarkerTypeDataStoreSlice.actions.fetch,
  JSONStoreDeviceMarkerTypeToStoreDeviceMarkerType,
  asMap
)

export default useNormalizedDeviceMarkerTypes
