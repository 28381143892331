import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import DeviceDataStoreSagaHandlers from '../../sagas/data/DeviceDataStore'

const sliceName = 'devicesStore'

const DeviceDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  DeviceDataStoreSagaHandlers
)

export default DeviceDataStoreSlice
