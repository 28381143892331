import useDataStoreItemsFetchedOnce from './helpers/useDataStoreItemsFetchedOnce'
import DeviceModelDataStoreSelectors from '../selectors/data/DeviceModels'
import DeviceModelDataStoreSlice from '../slices/data/DeviceModelDataStoreSlice'
import StoreDeviceModel from '../models/DeviceModel'

const JSONStoreDeviceModelToStoreDeviceModel = JSONStoreDeviceModel => StoreDeviceModel.FromJSON(JSONStoreDeviceModel)

/**
 * Returns requested-/all deviceModel(s) from state.data.deviceModels.
 * @param deviceModelIds {string | string[] | null}: id of requested deviceModels. If null, all deviceModels are requested.
 * @param asMap {boolean}: when multiple deviceModels are requested, return deviceModels as an object mapping deviceModels by their id.
 * @returns {StoreDeviceModel | StoreDeviceModel[]}
 */
const useNormalizedDeviceModels = (deviceModelIds = null, asMap = false) => useDataStoreItemsFetchedOnce(
  deviceModelIds,
  DeviceModelDataStoreSelectors.deviceModels,
  DeviceModelDataStoreSlice.actions.fetch,
  JSONStoreDeviceModelToStoreDeviceModel,
  asMap
)

export default useNormalizedDeviceModels
