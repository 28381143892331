import { useMemo } from 'react'
import useNormalizedDevices from './useNormalizedDevices'
import useNormalizedUsers from './useNormalizedUsers'
import useNormalizedDeviceTypes from './useNormalizedDeviceTypes'
import useNormalizedDeviceStatusChangeCodes from './useNormalizedDeviceStatusChangeCodes'
import useNormalizedDeviceMarkerTypes from './useNormalizedDeviceMarkerTypes'
import useNormalizedDeviceStatuses from './useNormalizedDeviceStatuses'
import uniq from 'lodash/uniq'
import useNormalizedDeviceAttributeValues from './useNormalizedDeviceAttributeValues'
import useNormalizedDeviceCoordinates from './useNormalizedDeviceCoordinates'
import useNormalizedDeviceInputs from './useNormalizedDeviceInputs'
import useNormalizedDeviceOutputs from './useNormalizedDeviceOutputs'
import useNormalizedDeviceModels from './useNormalizedDeviceModels'

/**
 * Returns requested-/all device(s) from state.data.devices in a non-normalized fashion.
 * @param deviceIds {string | string[] | null}: id of requested devices.
 * @param asMap {boolean}: when multiple devices are requested, return devices as an object mapping devices by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all devices, when no device id is passed.
 * @returns {StoreDevice | StoreDevice[]}
 */
const useDevices = (deviceIds = null, asMap = false, fetchAllEnabled = false) => {
  const normalizedDevices = useNormalizedDevices(deviceIds, asMap, fetchAllEnabled)
  const relatedIds = useMemo(
    () => {
      const reducedDevices = typeof deviceIds === 'string'
        ? asMap
          ? normalizedDevices && { [normalizedDevices.id]: normalizedDevices }
          : normalizedDevices && [normalizedDevices]
        : normalizedDevices

      const relatedIds = (
        asMap
          ? Object.values(reducedDevices || {})
          : reducedDevices || []
      ).reduce(
        (relatedIds, device) => {
          device.userId && relatedIds.userIds.push(device.userId)
          device.coordinateIds && (
            relatedIds.coordinateIds = relatedIds.coordinateIds.concat(device.coordinateIds)
          )
          device.attributeValueIds && (
            relatedIds.attributeValueIds = relatedIds.attributeValueIds.concat(device.attributeValueIds)
          )
          device.inputIds && (
            relatedIds.deviceInputIds = relatedIds.deviceInputIds.concat(device.inputIds)
          )
          device.outputIds && (
            relatedIds.deviceOutputIds = relatedIds.deviceOutputIds.concat(device.outputIds)
          )
          return relatedIds
        },
        { userIds: [], coordinateIds: [], attributeValueIds: [], deviceInputIds: [], deviceOutputIds: [] }
      )
      relatedIds.userIds = uniq(relatedIds.userIds)
      relatedIds.coordinateIds = uniq(relatedIds.coordinateIds)
      relatedIds.attributeValueIds = uniq(relatedIds.attributeValueIds)
      relatedIds.deviceInputIds = uniq(relatedIds.deviceInputIds)
      relatedIds.deviceOutputIds = uniq(relatedIds.deviceOutputIds)

      return relatedIds
    },
    [normalizedDevices, asMap, deviceIds]
  )
  const users = useNormalizedUsers(relatedIds?.userIds, true)
  const coordinates = useNormalizedDeviceCoordinates(relatedIds?.coordinateIds, true)
  const attributeValues = useNormalizedDeviceAttributeValues(relatedIds?.attributeValueIds, true)
  const deviceInputs = useNormalizedDeviceInputs(relatedIds?.deviceInputIds, true)
  const deviceOutputs = useNormalizedDeviceOutputs(relatedIds?.deviceOutputIds, true)
  const types = useNormalizedDeviceTypes(null, true)
  const statuses = useNormalizedDeviceStatuses(null, true)
  const statusChangeCodes = useNormalizedDeviceStatusChangeCodes(null, true)
  const markerTypes = useNormalizedDeviceMarkerTypes(null, true)
  const models = useNormalizedDeviceModels(null, true)

  return useMemo(
    () => {
      const populateDevice = device => {
        device.user = users[device.userId]
        device.type = types[device.typeId]
        device.status = statuses[device.statusId]
        device.coordinates = device.coordinateIds?.map(coordinateId => coordinates[coordinateId])
        device.statusChangeCode = statusChangeCodes[device.statusChangeCodeId]
        device.markerType = markerTypes[device.markerTypeId]
        device.attributeValues = device.attributeValueIds?.map(attributeValueId => attributeValues[attributeValueId])
        device.inputs = device.inputIds?.map(inputId => deviceInputs[inputId])
        device.outputs = device.outputIds?.map(outputId => deviceOutputs[outputId])
        device.model = models[device.modelId]

        return device
      }

      return normalizedDevices && (
        typeof deviceIds === 'string'
          ? populateDevice(normalizedDevices)
          : asMap
            ? Object.keys(normalizedDevices)
              .reduce((devices, deviceId) => {
                devices[deviceId] = populateDevice(normalizedDevices[deviceId])
                return devices
              }, {})
            : normalizedDevices?.map(populateDevice)
      )
    },
    [
      normalizedDevices, users, attributeValues, deviceInputs, deviceOutputs, types, statuses, coordinates,
      statusChangeCodes, markerTypes, models, asMap, deviceIds
    ]
  )
}

export default useDevices
