import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import DeviceMarkerTypeDataStoreSagaHandlers from '../../sagas/data/DeviceMarkerTypeDataStore'

const sliceName = 'deviceMarkerTypesStore'

const DeviceMarkerTypeDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  DeviceMarkerTypeDataStoreSagaHandlers
)

export default DeviceMarkerTypeDataStoreSlice
