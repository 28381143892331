import useDataStoreItems from './helpers/useDataStoreItems'
import StoreImage from '../models/Image'
import ImageDataStoreSelectors from '../selectors/data/Images'
import ImageDataStoreSlice from '../slices/data/ImageDataStoreSlice'

const JSONStoreImageToStoreImage = JSONStoreImage => StoreImage.FromJSON(JSONStoreImage)

/**
 * Returns requested-/all image(s) from state.data.images.
 * @param imageIds {string | string[]}: id of requested images.
 * @param asMap {boolean}: when multiple images are requested, return images as an object mapping images by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all devices, when no device id is passed.
 * @returns {StoreImage | StoreImage[]}
 */
const useNormalizedImages = (imageIds, asMap = false, fetchAllEnabled = false) => useDataStoreItems(
  imageIds,
  ImageDataStoreSelectors.images,
  ImageDataStoreSlice.actions.fetch,
  JSONStoreImageToStoreImage,
  asMap,
  fetchAllEnabled
)

export default useNormalizedImages
