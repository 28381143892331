import PredefinedEventManagementPredefinedEventListSlice
  from '../../slices/experiences/PredefinedEventManagementPredefinedEventListSlice'
import PREDEFINED_EVENT_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/PredefinedEventManagement'
import { makeActionCreator } from '../../../modules/utilities/store'

const PredefinedEventManagementExperienceActions = {
  list: PredefinedEventManagementPredefinedEventListSlice.actions,
  /**
   * @param predefinedEventItem {StorePredefinedEvent}: StorePredefinedEvent instance containing edited predefinedEvent data.
   * @param onSuccess {function}: executed if creation was successful.
   * @returns {{ type: string, data: { predefinedEventItem: StorePredefinedEvent, onSuccess: function } }}
   */
  create: makeActionCreator(PREDEFINED_EVENT_MANAGEMENT_EXPERIENCE_TYPES.CREATE, 'predefinedEventItem', 'onSuccess'),
  /**
   * @param predefinedEventItem {StorePredefinedEvent}: StorePredefinedEvent instance containing edited predefinedEvent data.
   * @param onSuccess {function}: executed if editing was successful.
   * @returns {{ type: string, data: { predefinedEventItem: StorePredefinedEvent, onSuccess: function } }}
   */
  edit: makeActionCreator(PREDEFINED_EVENT_MANAGEMENT_EXPERIENCE_TYPES.EDIT, 'predefinedEventItem', 'onSuccess'),
  /**
   * @param predefinedEventId {string}: id of predefinedEvent to delete.
   * @returns {{ type: string, data: { predefinedEventId: string } }}
   */
  delete: makeActionCreator(PREDEFINED_EVENT_MANAGEMENT_EXPERIENCE_TYPES.DELETE, 'predefinedEventId')
}

export default PredefinedEventManagementExperienceActions
