import { useMemo } from 'react'
import useNormalizedMessages from './useNormalizedMessages'
import uniq from 'lodash/uniq'
import useNormalizedDevices from './useNormalizedDevices'
import useNormalizedChatGroups from './useNormalizedChatGroups'
import useUsers from './useUsers'
import useNormalizedMessageCategories from './useNormalizedMessageCategories'

/**
 * Returns requested-/all message(s) from state.data.messages in a normalized fashion.
 * @param messageIds {string | string[]}: id of requested messages.
 * @param asMap {boolean}: when multiple messages are requested, return messages as an object mapping messages by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all messages, when no message id is passed.
 * @returns {StoreMessage | StoreMessage[]}
 */
const useMessages = (messageIds, asMap, fetchAllEnabled) => {
  const normalizedMessages = useNormalizedMessages(messageIds, asMap, fetchAllEnabled)
  const relatedIds = useMemo(
    () => {
      const reducedUsers = typeof messageIds === 'string'
        ? asMap
          ? normalizedMessages && { [normalizedMessages.id]: normalizedMessages }
          : normalizedMessages && [normalizedMessages]
        : normalizedMessages

      const relatedIds = (
        asMap
          ? Object.values(reducedUsers || {})
          : reducedUsers || []
      ).reduce(
        (relatedIds, message) => {
          message.categoryId && relatedIds.messageCategoryIds.push(message.categoryId)
          message.receiverUserId && relatedIds.userIds.push(message.receiverUserId)
          message.senderUserId && relatedIds.userIds.push(message.senderUserId)
          message.receiverDeviceId && relatedIds.deviceIds.push(message.receiverDeviceId)
          message.senderDeviceId && relatedIds.deviceIds.push(message.senderDeviceId)
          message.receiverChatGroupId && relatedIds.chatGroupIds.push(message.receiverChatGroupId)

          return relatedIds
        },
        { messageCategoryIds: [], userIds: [], deviceIds: [], chatGroupIds: [] }
      )
      relatedIds.messageCategoryIds = uniq(relatedIds.messageCategoryIds)
      relatedIds.userIds = uniq(relatedIds.userIds)
      relatedIds.coordinateIds = uniq(relatedIds.coordinateIds)
      relatedIds.attributeValueIds = uniq(relatedIds.attributeValueIds)

      return relatedIds
    },
    [normalizedMessages, asMap, messageIds]
  )
  const messageCategories = useNormalizedMessageCategories(relatedIds?.messageCategoryIds, true)
  const users = useUsers(
    relatedIds?.userIds,
    true,
    { role: false, language: false, family: false, photo: true }
  )
  const devices = useNormalizedDevices(relatedIds?.deviceIds, true)
  const chatGroups = useNormalizedChatGroups(relatedIds?.chatGroupIds, true)

  return useMemo(
    () => {
      const populateMessage = message => {
        message.category = message.categoryId && messageCategories[message.categoryId]
        message.receiverUser = message.receiverUserId && users[message.receiverUserId]
        message.senderUser = message.senderUserId && users[message.senderUserId]
        message.receiverDevice = message.receiverDeviceId && devices[message.receiverDeviceId]
        message.senderDevice = message.senderDeviceId && devices[message.senderDeviceId]
        message.receiverChatGroup = message.receiverChatGroupId && chatGroups[message.receiverChatGroupId]

        return message
      }

      return normalizedMessages && (
        typeof messageIds === 'string' || typeof messageIds === 'number'
          ? populateMessage(normalizedMessages)
          : asMap
            ? Object.keys(normalizedMessages)
              .reduce((users, userId) => {
                users[userId] = populateMessage(normalizedMessages[userId])
                return users
              }, {})
            : normalizedMessages.map(populateMessage)
      )
    },
    [normalizedMessages, messageIds, asMap, messageCategories, users, devices, chatGroups]
  )
}

export default useMessages
