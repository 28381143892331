import { combineReducers } from 'redux'
import AuthExperienceStore from './Auth'
import UserManagementExperienceStore from './UserManagement'
import DeviceManagementExperienceStore from './DeviceManagement'
import FormManagementExperienceStore from './FormManagement'
import ZoneManagementExperienceStore from './ZoneManagement'
import ChatManagementExperienceStore from './ChatManagement'
import ServiceManagementExperienceStore from './ServiceManagement'
import PredefinedEventManagementExperienceStore from './PredefinedEventManagement'
import TrackingGroupManagementExperienceStore from './TrackingGroupManagement'

const ExperiencesStore = combineReducers({
  auth: AuthExperienceStore,
  userManagement: UserManagementExperienceStore,
  deviceManagement: DeviceManagementExperienceStore,
  formManagement: FormManagementExperienceStore,
  zoneManagement: ZoneManagementExperienceStore,
  chatManagement: ChatManagementExperienceStore,
  serviceManagement: ServiceManagementExperienceStore,
  predefinedEventManagement: PredefinedEventManagementExperienceStore,
  trackingGroupManagement: TrackingGroupManagementExperienceStore
})

export default ExperiencesStore
