import { commonHandlers, createReducer } from '../../../../modules/utilities/store'
import SERVICE_MANAGEMENT_EXPERIENCE_TYPES from '../../../types/experiences/ServiceManagement'
import GLOBAL_TYPES from '../../../types/Global'

const initialState = {
  serviceIds: []
}

const handlers = {
  [SERVICE_MANAGEMENT_EXPERIENCE_TYPES.ADD_PRISE_DE_SERVICE]: (state, action) => {
    if (state.serviceIds.includes(action.data.serviceId)) {
      return state
    }
    return ({
      ...state,
      serviceIds: [
        ...state.serviceIds,
        action.data.serviceId
      ]
    })
  },
  [SERVICE_MANAGEMENT_EXPERIENCE_TYPES.REMOVE_PRISE_DE_SERVICE]: (state, action) => ({
    ...state,
    serviceIds: state.serviceIds.filter(serviceId => serviceId !== action.data.serviceId)
  }),
  [GLOBAL_TYPES.RESET_STORE]: commonHandlers.resetState(initialState)
}

const PriseDeServiceStore = createReducer(initialState, handlers)

export default PriseDeServiceStore
