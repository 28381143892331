import { put, takeLatest } from 'redux-saga/effects'
import MapRegionService from '../../../services/MapRegion'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import MAP_REGION_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/MapRegionManagement'
import LayoutActions from '../../actions/Layout'
import MessageActions from '../../actions/Messages'
import { AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT, DISPLAYED_MESSAGE_TYPES } from '../../../constants/general'

function * createMapRegion (action) {
  const fetchId = 'MapRegionManagementExperienceSaga.createMapRegion'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { mapRegionItem, onSuccess } } = action

    const result = yield MapRegionService.Create(mapRegionItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['CREATE_MAP_REGION_SUCCESS', 'MapRegion successfully created'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[MapRegionManagementExperience->createMapRegion]: Failed to create map region', 'Failed to create map region', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * editMapRegion (action) {
  const fetchId = 'MapRegionManagementExperienceSaga.editMapRegion'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { mapRegionItem, onSuccess } } = action

    const result = yield MapRegionService.Update(mapRegionItem.id, mapRegionItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['EDIT_MAP_REGION_SUCCESS', 'MapRegion successfully updated'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[MapRegionManagementExperience->editMapRegion]: Failed to update map region', 'Failed to update map region', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * deleteMapRegion (action) {
  const fetchId = 'MapRegionManagementExperienceSaga.deleteMapRegion'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { mapRegionId } } = action

    const result = yield MapRegionService.Delete(mapRegionId)
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['DELETE_MAP_REGION_SUCCESS', 'MapRegion deleted'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[MapRegionManagementExperience->deleteMapRegion]: Failed to delete map region', 'Failed to delete map region', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * updateAttributeActive (action) {
  const fetchId = 'MapRegionManagementExperienceSaga.updateAttributeActive'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { mapRegionId, active } } = action

    const result = yield MapRegionService.UpdateAttributeActive(mapRegionId, active)
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['MAP_REGION_ACTIVE_STATE_CHANGE_SUCCESS', 'MapRegion active state successfully changed'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[MapRegionManagementExperience->updateAttributeActive]: Failed to change map region active state',
          'Failed to change map region active state',
          e
        ),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * MapRegionManagementExperienceSaga () {
  yield takeLatest(MAP_REGION_MANAGEMENT_EXPERIENCE_TYPES.CREATE, createMapRegion)
  yield takeLatest(MAP_REGION_MANAGEMENT_EXPERIENCE_TYPES.EDIT, editMapRegion)
  yield takeLatest(MAP_REGION_MANAGEMENT_EXPERIENCE_TYPES.DELETE, deleteMapRegion)
  yield takeLatest(MAP_REGION_MANAGEMENT_EXPERIENCE_TYPES.UPDATE_ATTRIBUTE_ACTIVE, updateAttributeActive)
}

export default MapRegionManagementExperienceSaga
