import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import MapRegionDataStoreSagaHandlers from '../../sagas/data/MapRegionDataStore'

const sliceName = 'mapRegionsStore'

const MapRegionDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  MapRegionDataStoreSagaHandlers
)

export default MapRegionDataStoreSlice
