import useDataStoreItems from './helpers/useDataStoreItems'
import DeviceModelInputDataStoreSelectors from '../selectors/data/DeviceModelInputs'
import DeviceModelInputDataStoreSlice from '../slices/data/DeviceModelInputDataStoreSlice'
import StoreDeviceModelInput from '../models/DeviceModelInput'

const JSONStoreDeviceModelInputToStoreDeviceModelInput = JSONStoreDeviceModelInput => StoreDeviceModelInput.FromJSON(JSONStoreDeviceModelInput)

/**
 * Returns requested-/all deviceModelInput(s) from state.data.deviceModelInputs.
 * @param deviceModelInputIds {string | string[] | null}: id of requested deviceModelInputs. If null, all deviceModelInputs are requested.
 * @param asMap {boolean}: when multiple deviceModelInputs are requested, return deviceModelInputs as an object mapping deviceModelInputs by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all devices, when no device id is passed.
 * @returns {StoreDeviceModelInput | StoreDeviceModelInput[]}
 */
const useNormalizedDeviceModelInputs = (
  deviceModelInputIds = null,
  asMap = false,
  fetchAllEnabled = false
) => useDataStoreItems(
  deviceModelInputIds,
  DeviceModelInputDataStoreSelectors.deviceModelInputs,
  DeviceModelInputDataStoreSlice.actions.fetch,
  JSONStoreDeviceModelInputToStoreDeviceModelInput,
  asMap,
  fetchAllEnabled
)

export default useNormalizedDeviceModelInputs
