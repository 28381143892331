import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import TrackingGroupDataStoreSagaHandlers from '../../sagas/data/TrackingGroupDataStore'

const sliceName = 'trackingGroupStore'

const TrackingGroupDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  TrackingGroupDataStoreSagaHandlers
)

export default TrackingGroupDataStoreSlice
