import { useMemo } from 'react'
import useNormalizedDeviceAttributes from './useNormalizedDeviceAttributes'
import DeviceAttributeFormConfiguration from '../models/DeviceAttributeFormConfiguration'

/**
 * Returns requested-/all device-attribute(s) from state.data.deviceAttributes in a non-normalized fashion.
 * @param deviceAttributeIds {string | string[] | null}: id of requested device-attributes.
 * @param asMap {boolean}: when multiple device-attributes are requested, return device-attributes as an object mapping
 * device-attributes by their id.
 * @returns {StoreDeviceAttribute | StoreDeviceAttribute[]}
 */
const useDeviceAttributes = (deviceAttributeIds = null, asMap = false) => {
  const normalizedDeviceAttributes = useNormalizedDeviceAttributes(deviceAttributeIds, asMap)

  return useMemo(
    () => {
      const populateDeviceAttribute = deviceAttribute => {
        deviceAttribute.configuration = DeviceAttributeFormConfiguration.FromJSON(
          JSON.parse(deviceAttribute.configurationJSON)
        )
        return deviceAttribute
      }
      return asMap
        ? Object.keys(normalizedDeviceAttributes)
          .reduce((deviceAttributes, deviceAttributeId) => {
            deviceAttributes[deviceAttributeId] = populateDeviceAttribute(normalizedDeviceAttributes[deviceAttributeId])
            return deviceAttributes
          }, {})
        : normalizedDeviceAttributes.map(populateDeviceAttribute)
    },
    [normalizedDeviceAttributes, asMap]
  )
}

export default useDeviceAttributes
