class StoreMission {
  constructor (
    id = undefined,
    serviceId,
    startedAt,
    finishedAt,
    observations,
    dataId,
    objectiveIds
  ) {
    this.id = id
    this.serviceId = serviceId
    this.service = null
    this.startedAt = startedAt && new Date(startedAt)
    this.finishedAt = finishedAt && new Date(finishedAt)
    this.observations = observations
    this.dataId = dataId
    this.data = null
    this.objectiveIds = objectiveIds
    this.objectives = null
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      // serviceId is read-only
      // startedAt is read-only
      // finishedAt is read-only
      observations: this.observations
      // dataId is read-only
      // objectiveIds is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    serviceId,
    startedAt,
    finishedAt,
    observations,
    dataId,
    objectiveIds
  }) {
    return new StoreMission(
      id,
      serviceId,
      startedAt,
      finishedAt,
      observations,
      dataId,
      objectiveIds
    )
  }

  toJSON () {
    return {
      id: this.id,
      serviceId: this.serviceId,
      startedAt: this.startedAt,
      finishedAt: this.finishedAt,
      observations: this.observations,
      dataId: this.dataId,
      objectiveIds: this.objectiveIds
    }
  }

  static FromJSON ({
    id,
    serviceId,
    startedAt,
    finishedAt,
    observations,
    dataId,
    objectiveIds
  }) {
    return new StoreMission(
      id,
      serviceId,
      startedAt,
      finishedAt,
      observations,
      dataId,
      objectiveIds
    )
  }
}

export default StoreMission
