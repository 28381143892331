import MessageCategoryService from '../../../services/MessageCategory'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const MessageCategoryDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'messageCategory',
  MessageCategoryService,
  RESTModelNormalizationSchemas.messageCategory.schema,
  [
    RESTModelNormalizationSchemas.messageCategory.entityName
  ]
)

export default MessageCategoryDataStoreSagaHandlers
