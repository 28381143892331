import PredefinedEventService from '../../../services/PredefinedEvent'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const PredefinedEventDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'predefinedEvent',
  PredefinedEventService,
  RESTModelNormalizationSchemas.predefinedEvent.schema,
  [RESTModelNormalizationSchemas.predefinedEvent.entityName]
)

export default PredefinedEventDataStoreSagaHandlers
