import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import UserActionTypeDataStoreSagaHandlers from '../../sagas/data/UserActionTypeDataStore'

const sliceName = 'userActionTypesStore'

const UserActionTypeDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  UserActionTypeDataStoreSagaHandlers
)

export default UserActionTypeDataStoreSlice
