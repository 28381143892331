import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Create a new user.
 * @param audioBlob {blob}
 * @returns {Promise<AxiosResponse<object>>}: newly created audio.
 * @constructor
 */
const Create = audioBlob => {
  const formData = new FormData()
  formData.append('audio', audioBlob)

  return mainHTTPClient.post(
    MAIN_HTTP_API_ENDPOINTS.AUDIOS,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

/**
 * Update the specified audio's data.
 * @param audioPath {string}
 * @param audioBlob {blob}
 * @returns {Promise<AxiosResponse<any>>}: updated audio data.
 * @constructor
 */
const Update = (audioPath, audioBlob) => {
  const formData = new FormData()
  formData.append('audioPath', audioPath)
  formData.append('audio', audioBlob)

  return mainHTTPClient.put(
    MAIN_HTTP_API_ENDPOINTS.AUDIOS,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

const AudioService = {
  Create,
  Update
}

export default AudioService
