import useDataStoreItems from './helpers/useDataStoreItems'
import DeviceAttributeValueDataStoreSelectors from '../selectors/data/DeviceAttributeValues'
import DeviceAttributeValueDataStoreSlice from '../slices/data/DeviceAttributeValueDataStoreSlice'
import StoreDeviceAttributeValue from '../models/DeviceAttributeValue'

const JSONStoreDeviceAttributeValueToStoreDeviceAttributeValue = JSONStoreDeviceAttributeValue => StoreDeviceAttributeValue.FromJSON(JSONStoreDeviceAttributeValue)

/**
 * Returns requested-/all device attribute value(s) from state.data.deviceAttributeValues.
 * @param deviceAttributeValueIds {string | string[]}: id of requested device attribute values.
 * @param asMap {boolean}: when multiple device attribute values are requested, return device attribute values as an object mapping device attribute values by their id.
 * @returns {StoreDeviceAttributeValue | StoreDeviceAttributeValue[]}
 */
const useNormalizedDeviceAttributeValues = (deviceAttributeValueIds = null, asMap = false) => useDataStoreItems(
  deviceAttributeValueIds,
  DeviceAttributeValueDataStoreSelectors.deviceAttributeValues,
  DeviceAttributeValueDataStoreSlice.actions.fetch,
  JSONStoreDeviceAttributeValueToStoreDeviceAttributeValue,
  asMap
)

export default useNormalizedDeviceAttributeValues
