import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import DeviceAttributeValueDataStoreSagaHandlers from '../../sagas/data/DeviceAttributeValueDataStore'

const sliceName = 'deviceAttributeValuesStore'

const DeviceAttributeValueDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  DeviceAttributeValueDataStoreSagaHandlers
)

export default DeviceAttributeValueDataStoreSlice
