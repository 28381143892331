import useAppBarTitle from './useAppBarTitle'
import useAuthState from './useAuthState'
import useAvailableThemeNamesAsSelectOptions from './useAvailableThemeNamesAsSelectOptions'
import useChatGroups from './useChatGroups'
import useDeviceAttributes from './useDeviceAttributes'
import useDeviceMarkerTypesAsSelectOptions from './useDeviceMarkerTypesAsSelectOptions'
import useDeviceModelAttributes from './useDeviceModelAttributes'
import useDeviceModels from './useDeviceModels'
import useDeviceModelsAsSelectOptions from './useDeviceModelsAsSelectOptions'
import useDeviceOutputs from './useDeviceOutputs'
import useDevices from './useDevices'
import useDeviceInputs from './useDeviceInputs'
import useDeviceStatusesAsSelectOptions from './useDeviceStatusesAsSelectOptions'
import useDeviceType from './useDeviceType'
import useFamiliesAsSelectOptions from './useFamiliesAsSelectOptions'
import useFormFillData from './useFormFillData'
import useForms from './useForms'
import useFramelessModeTurnedOn from './useFramelessModeTurnedOn'
import useLanguagesAsSelectOptions from './useLanguagesAsSelectOptions'
import useLoading from './useLoading'
import useMapRegions from './useMapRegions'
import useMapRegionsAsSelectOptions from './useMapRegionsAsSelectOptions'
import useMessages from './useMessages'
import useMissionObjectives from './useMissionObjectives'
import useMissions from './useMissions'
import useNormalizedChatGroups from './useNormalizedChatGroups'
import useNormalizedDeviceAttributes from './useNormalizedDeviceAttributes'
import useNormalizedDeviceAttributeValues from './useNormalizedDeviceAttributeValues'
import useNormalizedDeviceCoordinates from './useNormalizedDeviceCoordinates'
import useNormalizedDeviceInputs from './useNormalizedDeviceInputs'
import useNormalizedDeviceMarkerTypes from './useNormalizedDeviceMarkerTypes'
import useNormalizedDeviceModelAttributes from './useNormalizedDeviceModelAttributes'
import useNormalizedDeviceModelInputs from './useNormalizedDeviceModelInputs'
import useNormalizedDeviceModelOutputs from './useNormalizedDeviceModelOutputs'
import useNormalizedDeviceModels from './useNormalizedDeviceModels'
import useNormalizedDeviceOutputs from './useNormalizedDeviceOutputs'
import useNormalizedDevices from './useNormalizedDevices'
import useNormalizedDeviceStatusChangeCodes from './useNormalizedDeviceStatusChangeCodes'
import useNormalizedDeviceStatuses from './useNormalizedDeviceStatuses'
import useNormalizedDeviceTypes from './useNormalizedDeviceTypes'
import useNormalizedFamilies from './useNormalizedFamilies'
import useNormalizedFormFillData from './useNormalizedFormFillData'
import useNormalizedForms from './useNormalizedForms'
import useNormalizedImages from './useNormalizedImages'
import useNormalizedLanguages from './useNormalizedLanguages'
import useNormalizedLoginUserActionDetails from './useNormalizedLoginUserActionDetails'
import useNormalizedMapRegions from './useNormalizedMapRegions'
import useNormalizedMessageCategories from './useNormalizedMessageCategories'
import useNormalizedMessages from './useNormalizedMessages'
import useNormalizedMissionObjectives from './useNormalizedMissionObjectives'
import useNormalizedMissions from './useNormalizedMissions'
import useNormalizedPredefinedEvents from './useNormalizedPredefinedEvents'
import useNormalizedProfile from './useNormalizedProfile'
import useNormalizedServices from './useNormalizedServices'
import useNormalizedTrackingGroups from './useNormalizedTrackingGroups'
import useNormalizedUserActions from './useNormalizedUserActions'
import useNormalizedUserActionTypes from './useNormalizedUserActionTypes'
import useNormalizedUsers from './useNormalizedUsers'
import useNormalizedZoneDevices from './useNormalizedZoneDevices'
import useNormalizedZones from './useNormalizedZones'
import useNormalizedZoneShapeTypes from './useNormalizedZoneShapeTypes'
import useNormalizedZoneTypes from './useNormalizedZoneTypes'
import usePredefinedEvents from './usePredefinedEvents'
import usePredefinedEventsAsSelectOptions from './usePredefinedEventsAsSelectOptions'
import useProfile from './useProfile'
import useRoles from './useRoles'
import useRolesAsSelectOptions from './useRolesAsSelectOptions'
import useServiceDetails from './useServiceDetails'
import useServices from './useServices'
import useTrackingGroups from './useTrackingGroups'
import useUserActions from './useUserActions'
import useUserActionTypesAsSelectOptions from './useUserActionTypesAsSelectOptions'
import useUsers from './useUsers'
import useZoneDevices from './useZoneDevices'
import useZones from './useZones'
import useZoneShapeTypesAsSelectOptions from './useZoneShapeTypesAsSelectOptions'
import useZoneTypesAsSelectOptions from './useZoneTypesAsSelectOptions'

// TODO: refactor non-normalized data hooks to use a deNormalizationConfig parameter(look at how useUsers is implemented)
const storeHooks = {
  useAppBarTitle,
  useAuthState,
  useAvailableThemeNamesAsSelectOptions,
  useChatGroups,
  useDeviceAttributes,
  useDeviceMarkerTypesAsSelectOptions,
  useDeviceModelAttributes,
  useDeviceModels,
  useDeviceModelsAsSelectOptions,
  useDeviceOutputs,
  useDevices,
  useDeviceInputs,
  useDeviceStatusesAsSelectOptions,
  useDeviceType,
  useFamiliesAsSelectOptions,
  useFormFillData,
  useForms,
  useFramelessModeTurnedOn,
  useLanguagesAsSelectOptions,
  useLoading,
  useMapRegions,
  useMapRegionsAsSelectOptions,
  useMessages,
  useMissionObjectives,
  useMissions,
  useNormalizedChatGroups,
  useNormalizedDeviceAttributes,
  useNormalizedDeviceAttributeValues,
  useNormalizedDeviceCoordinates,
  useNormalizedDeviceInputs,
  useNormalizedDeviceMarkerTypes,
  useNormalizedDeviceModelAttributes,
  useNormalizedDeviceModelInputs,
  useNormalizedDeviceModelOutputs,
  useNormalizedDeviceModels,
  useNormalizedDeviceOutputs,
  useNormalizedDevices,
  useNormalizedDeviceStatusChangeCodes,
  useNormalizedDeviceStatuses,
  useNormalizedDeviceTypes,
  useNormalizedFamilies,
  useNormalizedFormFillData,
  useNormalizedForms,
  useNormalizedImages,
  useNormalizedLanguages,
  useNormalizedLoginUserActionDetails,
  useNormalizedMapRegions,
  useNormalizedMessageCategories,
  useNormalizedMessages,
  useNormalizedMissions,
  useNormalizedPredefinedEvents,
  useNormalizedMissionObjectives,
  useNormalizedProfile,
  useNormalizedServices,
  useNormalizedTrackingGroups,
  useNormalizedUserActions,
  useNormalizedUserActionTypes,
  useNormalizedUsers,
  useNormalizedZoneDevices,
  useNormalizedZones,
  useNormalizedZoneShapeTypes,
  useNormalizedZoneTypes,
  usePredefinedEvents,
  usePredefinedEventsAsSelectOptions,
  useProfile,
  useRoles,
  useRolesAsSelectOptions,
  useServiceDetails,
  useServices,
  useTrackingGroups,
  useUserActions,
  useUserActionTypesAsSelectOptions,
  useUsers,
  useZoneDevices,
  useZones,
  useZoneShapeTypesAsSelectOptions,
  useZoneTypesAsSelectOptions
}

export default storeHooks
