import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import MessageDataStoreSagaHandlers from '../../sagas/data/MessageDataStore'

const sliceName = 'messagesStore'

const MessageDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  MessageDataStoreSagaHandlers
)

export default MessageDataStoreSlice
