import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Create a new user.
 * @param formData {object}
 * @returns {Promise<AxiosResponse<object>>}: newly created form.
 * @constructor
 */
const Create = async formData => mainHTTPClient.post(
  MAIN_HTTP_API_ENDPOINTS.FORMS,
  formData
)

/**
 * Get data of specified form.
 * @param formId {string}
 * @returns {Promise<AxiosResponse<object>>}: form data.
 * @constructor
 */
const GetFormInfo = async formId => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.FORM.replace(':id', formId)
)

/**
 * Update the specified form's data.
 * @param formId {string}
 * @param formData {object}
 * @returns {Promise<AxiosResponse<any>>}: updated form data.
 * @constructor
 */
const Update = async (formId, formData) => mainHTTPClient.put(
  MAIN_HTTP_API_ENDPOINTS.FORM.replace(':id', formId),
  formData
)

/**
 * Update the specified form's enabled attribute.
 * @param formId {string}
 * @param enabled {boolean}
 * @returns {Promise<AxiosResponse<any>>}: updated form's data.
 * @constructor
 */
const UpdateEnabledAttribute = async (formId, enabled) => mainHTTPClient.put(
  MAIN_HTTP_API_ENDPOINTS.FORM_ENABLED_ATTRIBUTE.replace(':id', formId),
  { enabled }
)

/**
 * Delete specified form.
 * @param formId {string}
 * @returns {Promise<AxiosResponse<string>>}: OK in case the deletion was successful.
 * @constructor
 */
const Delete = async formId => mainHTTPClient.delete(
  MAIN_HTTP_API_ENDPOINTS.FORM.replace(':id', formId)
)

/**
 * Search for forms.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.FORMS,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

const FormService = {
  Create,
  GetFormInfo,
  Update,
  UpdateEnabledAttribute,
  Delete,
  FindMany
}

export default FormService
