import { combineReducers } from 'redux'
import ServiceManagementServiceListSlice from '../../../slices/experiences/ServiceManagementServiceListSlice'
import ServiceManagementServiceListExportSlice from '../../../slices/experiences/ServiceManagementServiceListExportSlice'
import PriseDeServiceReducer from './PriseDeService'

const ServiceManagementExperienceStore = combineReducers({
  list: ServiceManagementServiceListSlice.reducer,
  exportList: ServiceManagementServiceListExportSlice.reducer,
  priseDeService: PriseDeServiceReducer
})

export default ServiceManagementExperienceStore
