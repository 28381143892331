class StoreFormFillData {
  constructor (
    id = undefined,
    formId,
    formVersion,
    dataJSON
  ) {
    this.id = id
    this.formId = formId
    this.form = null
    this.formVersion = formVersion
    this.dataJSON = dataJSON
    this.data = null
    this.mergedFieldData = null
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      formId: this.formId,
      formVersion: this.formVersion,
      dataJSON: this.dataJSON
    })
  }

  static FromNormalizedRESTModel ({
    id,
    formId,
    formVersion,
    dataJSON
  }) {
    return new StoreFormFillData(
      id,
      formId,
      formVersion,
      dataJSON
    )
  }

  toJSON () {
    return {
      id: this.id,
      formId: this.formId,
      formVersion: this.formVersion,
      dataJSON: this.dataJSON
    }
  }

  static FromJSON ({
    id,
    formId,
    formVersion,
    dataJSON
  }) {
    return new StoreFormFillData(
      id,
      formId,
      formVersion,
      dataJSON
    )
  }
}

export default StoreFormFillData
