import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Get data of specified service.
 * @param serviceId {string}
 * @returns {Promise<AxiosResponse<object>>}: service data.
 * @constructor
 */
const GetServiceInfo = async serviceId => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.SERVICE.replace(':id', serviceId)
)

/**
 *
 * Search for services.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = async listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.SERVICES,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

const ServiceService = {
  GetServiceInfo,
  FindMany
}

export default ServiceService
