import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import DeviceOutputDataStoreSagaHandlers from '../../sagas/data/DeviceOutputDataStore'

const sliceName = 'deviceOutputsStore'

const DeviceOutputDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  DeviceOutputDataStoreSagaHandlers
)

export default DeviceOutputDataStoreSlice
