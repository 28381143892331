import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'
import mainAuth0Client from '../clients/mainAuth0Client'

const AUTH_TOKEN_SETTING_STORAGE_KEY = 'at'
const AUTH_CODE_PREFIX_SETTING_STORAGE_KEY = 'acp'

/**
 * Email-based authentication.
 * @param email {string}
 * @param password {string}
 * @returns {Promise<AxiosResponse<any>>}: auth code prefix.
 * @constructor
 */
const LogIn = (email, password) => mainHTTPClient.post(
  MAIN_HTTP_API_ENDPOINTS.LOG_IN,
  { email, password }
)

/**
 * Email-based authentication.
 * @param authCode {string}
 * @returns {Promise<AxiosResponse<any>>}: auth token.
 * @constructor
 */
const ValidateAuthCode = authCode => mainHTTPClient.post(
  MAIN_HTTP_API_ENDPOINTS.VALIDATE_AUTH_CODE,
  { authCode }
)

/**
 * Persists the logged-in user's authentication token.
 * @param authToken {string}
 * @param longTermStorage {boolean}: whether to save the token temporarily or in the long-term.
 * @returns {Promise<AxiosResponse<any>>}: auth token.
 * @constructor
 */
const PersistAuthToken = (authToken, longTermStorage) => (
  longTermStorage ? window.localStorage : window.sessionStorage
).setItem(
  AUTH_TOKEN_SETTING_STORAGE_KEY,
  authToken
)

/**
 * Get persisted authentication token.
 * @returns {string | null}: auth token.
 * @constructor
 */
const GetAuthToken = () => {
  const sessionToken = window.sessionStorage.getItem(AUTH_TOKEN_SETTING_STORAGE_KEY)
  const localStorageToken = window.localStorage.getItem(AUTH_TOKEN_SETTING_STORAGE_KEY)

  return sessionToken || localStorageToken
}

/**
 * Remove persisted authentication tokens.
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const RemoveAuthToken = () => {
  window.localStorage.removeItem(AUTH_TOKEN_SETTING_STORAGE_KEY)
  window.sessionStorage.removeItem(AUTH_TOKEN_SETTING_STORAGE_KEY)
}

/**
 * Get persisted auth code prefix.
 * @returns {string | null}: auth code prefix.
 * @constructor
 */
const GetAuthCodePrefix = () => window.sessionStorage.getItem(AUTH_CODE_PREFIX_SETTING_STORAGE_KEY)

/**
 * Persists the auth code prefix.
 * @param authCodePrefix {string}
 * @returns {any}: result of the session storage setItem action.
 * @constructor
 */
const PersistAuthCodePrefix = authCodePrefix => window.sessionStorage.setItem(
  AUTH_CODE_PREFIX_SETTING_STORAGE_KEY,
  authCodePrefix
)

/**
 * Remove persisted auth code prefix.
 * @returns {any}
 * @constructor
 */
const RemoveAuthCodePrefix = () => window.sessionStorage.removeItem(AUTH_CODE_PREFIX_SETTING_STORAGE_KEY)

/**
 * Checking if the user is already authenticated with auth0.
 * @returns {any}
 * @constructor
 */
const IsAuthenticatedWithAuth0 = () => mainAuth0Client.instance.isAuthenticated()

/**
 * Redirect user to the Auth0 authentication.
 * @returns {any}
 * @constructor
 */
const Auth0Login = () => mainAuth0Client.instance.loginWithPopup({
  authorizationParams: {
    redirect_uri: window.location.origin
  }
})

/**
 * Deauthenticates user from Auth0.
 * @returns {any}
 * @constructor
 */
const Auth0Logout = () => mainAuth0Client.instance.logout({
  logoutParams: {
    returnTo: window.location.origin
  }
})

const AuthService = {
  LogIn,
  ValidateAuthCode,
  PersistAuthToken,
  GetAuthToken,
  RemoveAuthToken,
  GetAuthCodePrefix,
  PersistAuthCodePrefix,
  RemoveAuthCodePrefix,
  IsAuthenticatedWithAuth0,
  Auth0Login,
  Auth0Logout
}

export default AuthService
