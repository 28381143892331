import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'
import mainHTTPClient from '../clients/mainHTTPClient'

/**
 * Search for deviceCoordinates.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.DEVICE_COORDINATES,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

/**
 * Get location string for specified coordinate.
 * @param deviceCoordinateId {string}: device coordinate to get the location string for.
 * @returns {Promise<AxiosResponse<object>>}: location string for device coordinate.
 * @constructor
 */
const GetLocationStringForCoordinate = async deviceCoordinateId => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.DEVICE_COORDINATE_LOCATION_STRING.replace(':id', deviceCoordinateId)
)

const DeviceCoordinateService = {
  FindMany,
  GetLocationStringForCoordinate
}

export default DeviceCoordinateService
