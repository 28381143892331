import MessageDataStoreSelectors from '../selectors/data/Messages'
import MessageDataStoreSlice from '../slices/data/MessageDataStoreSlice'
import StoreMessage from '../models/Message'
import useDataStoreItems from './helpers/useDataStoreItems'

const JSONStoreMessageToStoreMessage = JSONStoreMessage => StoreMessage.FromJSON(JSONStoreMessage)

/**
 * Returns requested-/all message(s) from state.data.messages.
 * @param messageIds {string | string[]}: id of requested messages.
 * @param asMap {boolean}: when multiple messages are requested, return messages as an object mapping messages by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all messages, when no message id is passed.
 * @returns {StoreMessage | StoreMessage[]}
 */
const useNormalizedMessages = (
  messageIds = null,
  asMap = false,
  fetchAllEnabled = false
) => useDataStoreItems(
  messageIds,
  MessageDataStoreSelectors.messages,
  MessageDataStoreSlice.actions.fetch,
  JSONStoreMessageToStoreMessage,
  asMap,
  fetchAllEnabled
)

export default useNormalizedMessages
