class StoreDeviceStatusChangeCode {
  constructor (
    id = undefined,
    name,
    description
  ) {
    this.id = id
    this.name = name
    this.description = description
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      name: this.name,
      description: this.description
    })
  }

  static FromNormalizedRESTModel ({
    id,
    name,
    description
  }) {
    return new StoreDeviceStatusChangeCode(
      id,
      name,
      description
    )
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name,
      description: this.description
    }
  }

  static FromJSON ({
    id,
    name,
    description
  }) {
    return new StoreDeviceStatusChangeCode(
      id,
      name,
      description
    )
  }
}

export default StoreDeviceStatusChangeCode
