class StoreTrackingGroup {
  constructor (
    id = undefined,
    name,
    startDate,
    endDate,
    safePerimeterInMAroundTargetDevice,
    active,
    lastPositionLat,
    lastPositionLng,
    targetDeviceId,
    deviceIds
  ) {
    this.id = id
    this.name = name
    this.startDate = startDate && new Date(startDate)
    this.endDate = endDate && new Date(endDate)
    this.safePerimeterInMAroundTargetDevice = safePerimeterInMAroundTargetDevice
    this.active = active
    this.lastPositionLat = lastPositionLat
    this.lastPositionLng = lastPositionLng
    this.targetDeviceId = targetDeviceId
    this.targetDevice = null
    this.deviceIds = deviceIds
    this.devices = null
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      name: this.name,
      startDate: this.startDate,
      endDate: this.endDate,
      safePerimeterInMAroundTargetDevice: this.safePerimeterInMAroundTargetDevice,
      active: this.active,
      // lastPositionLat is read-only
      // lastPositionLng is read-only
      targetDeviceId: this.targetDeviceId,
      deviceIds: this.deviceIds
    })
  }

  static FromNormalizedRESTModel ({
    id,
    name,
    startDate,
    endDate,
    safePerimeterInMAroundTargetDevice,
    active,
    lastPositionLat,
    lastPositionLng,
    targetDeviceId,
    deviceIds
  }) {
    return new StoreTrackingGroup(
      id,
      name,
      startDate,
      endDate,
      safePerimeterInMAroundTargetDevice,
      active,
      lastPositionLat,
      lastPositionLng,
      targetDeviceId,
      deviceIds
    )
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name,
      startDate: this.startDate,
      endDate: this.endDate,
      safePerimeterInMAroundTargetDevice: this.safePerimeterInMAroundTargetDevice,
      active: this.active,
      lastPositionLat: this.lastPositionLat,
      lastPositionLng: this.lastPositionLng,
      targetDeviceId: this.targetDeviceId,
      deviceIds: this.deviceIds
    }
  }

  static FromJSON ({
    id,
    name,
    startDate,
    endDate,
    safePerimeterInMAroundTargetDevice,
    active,
    lastPositionLat,
    lastPositionLng,
    targetDeviceId,
    deviceIds
  }) {
    return new StoreTrackingGroup(
      id,
      name,
      startDate,
      endDate,
      safePerimeterInMAroundTargetDevice,
      active,
      lastPositionLat,
      lastPositionLng,
      targetDeviceId,
      deviceIds
    )
  }
}

export default StoreTrackingGroup
