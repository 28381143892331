import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import DeviceModelAttributeDataStoreSagaHandlers from '../../sagas/data/DeviceModelAttributeDataStore'

const sliceName = 'deviceModelAttributesStore'

const DeviceModelAttributeDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  DeviceModelAttributeDataStoreSagaHandlers
)

export default DeviceModelAttributeDataStoreSlice
