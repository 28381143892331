import { commonHandlers, createReducer } from '../../index'

/**
 * Creates a reducer for list data fetch management.
 * @param listManagementTypes {{FETCH: string, SET_IS_LOADING:string, SET_LIST_DATA:string, RESET_LIST_DATA:string}}
 * @param initialState {{data: [], isLoading: boolean, totalNumberOfItems: {int}}}: overwrite default initial state.
 * @returns {(function(*=, *): (*))|*}
 */
const createListDataFetchManagementReducer = (listManagementTypes, initialState) => createReducer(
  initialState,
  {
    [listManagementTypes.SET_IS_LOADING]: commonHandlers.setValue('isLoading'),
    [listManagementTypes.SET_LIST_DATA]: commonHandlers.setValue([
      'data',
      'totalNumberOfItems',
      'isLoading'
    ]),
    [listManagementTypes.RESET_LIST_DATA]: commonHandlers.resetState(initialState)
  }
)

export default createListDataFetchManagementReducer
