import useDataStoreItems from './helpers/useDataStoreItems'
import DeviceOutputDataStoreSelectors from '../selectors/data/DeviceOutputs'
import DeviceOutputDataStoreSlice from '../slices/data/DeviceOutputDataStoreSlice'
import StoreDeviceOutput from '../models/DeviceOutput'

const JSONStoreDeviceOutputToStoreDeviceOutput = JSONStoreDeviceOutput => StoreDeviceOutput.FromJSON(JSONStoreDeviceOutput)

/**
 * Returns requested-/all deviceOutput(s) from state.data.deviceOutputs.
 * @param deviceOutputIds {string | string[] | null}: id of requested deviceOutputs. If null, all deviceOutputs are
 * requested.
 * @param asMap {boolean}: when multiple deviceOutputs are requested, return deviceOutputs as an object mapping
 * deviceOutputs by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all devices, when no device id is passed.
 * @returns {StoreDeviceOutput | StoreDeviceOutput[]}
 */
const useNormalizedDeviceOutputs = (
  deviceOutputIds = null,
  asMap = false,
  fetchAllEnabled = false
) => useDataStoreItems(
  deviceOutputIds,
  DeviceOutputDataStoreSelectors.deviceOutputs,
  DeviceOutputDataStoreSlice.actions.fetch,
  JSONStoreDeviceOutputToStoreDeviceOutput,
  asMap,
  fetchAllEnabled
)

export default useNormalizedDeviceOutputs
