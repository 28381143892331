import { useMemo } from 'react'
import useNormalizedFormFillData from './useNormalizedFormFillData'
import uniq from 'lodash/uniq'
import useForms from './useForms'

/**
 * Returns requested-/all formFillData(s) from state.data.formFillData in a non-normalized fashion.
 * @param formFillDataIds {string | string[] | null}: id of requested formFillData.
 * @param asMap {boolean}: when multiple formFillData are requested, return formFillData as an object mapping formFillData by their id.
 * @returns {StoreFormFillData | StoreFormFillData[]}
 */
const useFormFillData = (formFillDataIds = null, asMap = false) => {
  const normalizedFormFillData = useNormalizedFormFillData(formFillDataIds, asMap)
  const relatedIds = useMemo(
    () => {
      const reducedFormFillData = typeof formFillDataIds === 'string'
        ? asMap
          ? normalizedFormFillData && { [normalizedFormFillData.id]: normalizedFormFillData }
          : normalizedFormFillData && [normalizedFormFillData]
        : normalizedFormFillData

      const relatedIds = (
        asMap
          ? Object.values(reducedFormFillData || {})
          : reducedFormFillData || []
      ).reduce(
        (relatedIds, formFillData) => {
          formFillData.formId && relatedIds.formIds.push(formFillData.formId)
          return relatedIds
        },
        { formIds: [] }
      )
      relatedIds.formIds = uniq(relatedIds.formIds)

      return relatedIds
    },
    [normalizedFormFillData, asMap, formFillDataIds]
  )
  const forms = useForms(relatedIds?.formIds, true)

  return useMemo(
    () => {
      const populateFormFillData = formFillData => {
        formFillData.form = forms ? forms[formFillData.formId] : null
        formFillData.data = formFillData.dataJSON ? JSON.parse(formFillData.dataJSON) : null
        formFillData.mergedFieldData = formFillData.form?.configuration?.fields
          ?.sort((field1, field2) => field1.position - field2.position) // sort fields by their position
          .reduce(
            (mergedFieldData, field) => {
              mergedFieldData.map[field.name] = {
                configuration: field,
                value: formFillData.data?.[field.name]
              }
              mergedFieldData.array.push(mergedFieldData.map[field.name])

              return mergedFieldData
            },
            { array: [], map: {} }
          )

        return formFillData
      }

      return normalizedFormFillData && (
        typeof formFillDataIds === 'string'
          ? populateFormFillData(normalizedFormFillData)
          : asMap
            ? Object.keys(normalizedFormFillData)
              .reduce((formFillData, formFillDataId) => {
                formFillData[formFillDataId] = populateFormFillData(normalizedFormFillData[formFillDataId])
                return formFillData
              }, {})
            : normalizedFormFillData?.map(populateFormFillData)
      )
    },
    [normalizedFormFillData, forms, asMap, formFillDataIds]
  )
}

export default useFormFillData
