import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import FormFillDataDataStoreSagaHandlers from '../../sagas/data/FormFillDataDataStore'

const sliceName = 'formFillDataStore'

const FormFillDataDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  FormFillDataDataStoreSagaHandlers
)

export default FormFillDataDataStoreSlice
