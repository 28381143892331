import { useMemo } from 'react'
import useNormalizedTrackingGroups from './useNormalizedTrackingGroups'
import uniq from 'lodash/uniq'
import useNormalizedDevices from './useNormalizedDevices'

/**
 * Returns requested tracking group(s) from state.data.trackingGroups in a normalized fashion.
 * @param trackingGroupIds {string | string[]}: id of requested tracking groups.
 * @param asMap {boolean}: when multiple tracking groups are requested, return tracking groups as an object mapping tracking groups by their id.
 * @param deNormalizationConfig {{targetDevice: boolean, devices: boolean}}:
 * specify which related models are merged into the tracking group object. Default config returns the same result as
 * useNormalizedTrackingGroups.
 * @param fetchAllEnabled {boolean}: enable fetching of all tracking groups, when no tracking group id is passed.
 * @returns {StoreTrackingGroup | StoreTrackingGroup[]}
 */
const useTrackingGroups = (
  trackingGroupIds = null,
  asMap = false,
  deNormalizationConfig = {
    targetDevice: false,
    devices: false
  },
  fetchAllEnabled = false
) => {
  const normalizedTrackingGroups = useNormalizedTrackingGroups(trackingGroupIds, asMap, fetchAllEnabled)
  const relatedIds = useMemo(
    () => {
      const reducedTrackingGroups = typeof trackingGroupIds === 'string'
        ? asMap
          ? normalizedTrackingGroups && { [normalizedTrackingGroups.id]: normalizedTrackingGroups }
          : normalizedTrackingGroups && [normalizedTrackingGroups]
        : normalizedTrackingGroups

      const relatedIds = (
        asMap
          ? Object.values(reducedTrackingGroups || {})
          : reducedTrackingGroups || []
      ).reduce(
        (relatedIds, trackingGroup) => {
          trackingGroup.targetDeviceId && relatedIds.deviceIds.push(trackingGroup.targetDeviceId)
          trackingGroup.deviceIds?.length && (relatedIds.deviceIds = relatedIds.deviceIds.concat(trackingGroup.deviceIds))
          return relatedIds
        },
        { deviceIds: [] }
      )
      relatedIds.deviceIds = uniq(relatedIds.deviceIds)

      return relatedIds
    },
    [normalizedTrackingGroups, asMap, trackingGroupIds]
  )
  const devices = useNormalizedDevices(
    deNormalizationConfig.devices || deNormalizationConfig.targetDevice ? relatedIds?.deviceIds : null,
    true,
    false
  )

  return useMemo(
    () => {
      const populateTrackingGroup = trackingGroup => {
        if (deNormalizationConfig.targetDevice) {
          trackingGroup.targetDevice = devices && Object.keys(devices).length
            ? devices[trackingGroup.targetDeviceId]
            : null
        }
        if (deNormalizationConfig.devices) {
          trackingGroup.devices = devices && Object.keys(devices).length
            ? trackingGroup.deviceIds?.map(deviceId => devices[deviceId])
            : []
        }

        return trackingGroup
      }

      return normalizedTrackingGroups && (
        typeof trackingGroupIds === 'string' || typeof trackingGroupIds === 'number'
          ? populateTrackingGroup(normalizedTrackingGroups)
          : asMap
            ? Object.keys(normalizedTrackingGroups)
              .reduce((trackingGroups, trackingGroupId) => {
                trackingGroups[trackingGroupId] = populateTrackingGroup(normalizedTrackingGroups[trackingGroupId])
                return trackingGroups
              }, {})
            : normalizedTrackingGroups.map(populateTrackingGroup)
      )
    },
    [normalizedTrackingGroups, trackingGroupIds, devices, asMap, deNormalizationConfig]
  )
}

export default useTrackingGroups
