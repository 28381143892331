import { schema } from 'normalizr'

// family
const FAMILY_ENTITY_NAME = 'families'
const family = new schema.Entity(FAMILY_ENTITY_NAME)

// image
const IMAGE_ENTITY_NAME = 'images'
const image = new schema.Entity(IMAGE_ENTITY_NAME)

// language
const LANGUAGE_ENTITY_NAME = 'languages'
const language = new schema.Entity(LANGUAGE_ENTITY_NAME)

// role
const ROLE_ENTITY_NAME = 'roles'
const role = new schema.Entity(ROLE_ENTITY_NAME)

// user
const USER_ENTITY_NAME = 'users'
const user = new schema.Entity(
  USER_ENTITY_NAME,
  {
    family,
    language,
    role,
    photo: image
  },
  {
    processStrategy: value => ({
      familyId: value.family?.id,
      languageId: value.language?.id,
      roleId: value.role?.id,
      photoId: value.photo?.id,
      deviceIds: value.devices?.map(device => device.id),
      ...value
    })
  }
)

// predefinedEvent
const PREDEFINED_EVENT_ENTITY_NAME = 'predefinedEvents'
const predefinedEvent = new schema.Entity(PREDEFINED_EVENT_ENTITY_NAME)

// deviceType
const DEVICE_TYPE_ENTITY_NAME = 'deviceTypes'
const deviceType = new schema.Entity(DEVICE_TYPE_ENTITY_NAME)

// deviceStatusChangeCode
const DEVICE_STATUS_CHANGE_CODE_ENTITY_NAME = 'deviceStatusChangeCodes'
const deviceStatusChangeCode = new schema.Entity(DEVICE_STATUS_CHANGE_CODE_ENTITY_NAME)

// deviceStatus
const DEVICE_STATUS_ENTITY_NAME = 'deviceStatuses'
const deviceStatus = new schema.Entity(DEVICE_STATUS_ENTITY_NAME)

// deviceMarkerType
const DEVICE_MARKER_TYPE_ENTITY_NAME = 'deviceMarkerTypes'
const deviceMarkerType = new schema.Entity(DEVICE_MARKER_TYPE_ENTITY_NAME)

// deviceCoordinate
const DEVICE_COORDINATE_ENTITY_NAME = 'deviceCoordinates'
const deviceCoordinate = new schema.Entity(
  DEVICE_COORDINATE_ENTITY_NAME,
  {},
  {
    processStrategy: value => ({
      deviceId: value.device?.id,
      ...value
    })
  }
)

// deviceModelInput
const DEVICE_MODEL_INPUT_ENTITY_NAME = 'deviceModelInputs'
const deviceModelInput = new schema.Entity(
  DEVICE_MODEL_INPUT_ENTITY_NAME,
  {},
  {
    processStrategy: value => ({
      modelId: value.model?.id,
      ...value
    })
  }
)

// deviceModelOutput
const DEVICE_MODEL_OUTPUT_ENTITY_NAME = 'deviceModelOutputs'
const deviceModelOutput = new schema.Entity(
  DEVICE_MODEL_OUTPUT_ENTITY_NAME,
  {},
  {
    processStrategy: value => ({
      modelId: value.model?.id,
      ...value
    })
  }
)

// deviceModel
const DEVICE_MODEL_ENTITY_NAME = 'deviceModels'
const deviceModel = new schema.Entity(
  DEVICE_MODEL_ENTITY_NAME,
  {
    inputs: [deviceModelInput],
    outputs: [deviceModelOutput]
  },
  {
    processStrategy: value => ({
      inputIds: value.inputs?.map(deviceModelInput => deviceModelInput.id),
      outputIds: value.outputs?.map(deviceModelOutput => deviceModelOutput.id),
      ...value
    })
  }
)

// deviceInput
const DEVICE_INPUT_ENTITY_NAME = 'deviceInputs'
const deviceInput = new schema.Entity(
  DEVICE_INPUT_ENTITY_NAME,
  {
    deviceModelInput,
    predefinedEvent
  },
  {
    processStrategy: value => ({
      deviceModelInputId: value.deviceModelInput?.id,
      predefinedEventId: value.predefinedEvent?.id,
      ...value
    })
  }
)

// deviceOutput
const DEVICE_OUTPUT_ENTITY_NAME = 'deviceOutputs'
const deviceOutput = new schema.Entity(
  DEVICE_OUTPUT_ENTITY_NAME,
  {
    deviceModelOutput
  },
  {
    processStrategy: value => ({
      deviceModelOutputId: value.deviceModelOutput?.id,
      ...value
    })
  }
)

// deviceAttribute
const DEVICE_ATTRIBUTE_ENTITY_NAME = 'deviceAttributes'
const deviceAttribute = new schema.Entity(DEVICE_ATTRIBUTE_ENTITY_NAME)

// deviceAttributeValue
const DEVICE_ATTRIBUTE_VALUE_ENTITY_NAME = 'deviceAttributeValues'
const deviceAttributeValue = new schema.Entity(
  DEVICE_ATTRIBUTE_VALUE_ENTITY_NAME,
  {
    attribute: deviceAttribute
  },
  {
    processStrategy: value => ({
      deviceId: value.device?.id,
      ...value
    })
  }
)

// deviceModelAttribute
const DEVICE_MODEL_ATTRIBUTE_ENTITY_NAME = 'deviceModelAttributes'
const deviceModelAttribute = new schema.Entity(
  DEVICE_MODEL_ATTRIBUTE_ENTITY_NAME,
  {
    model: deviceModel,
    attribute: deviceAttribute
  }
)

// devices
const DEVICE_ENTITY_NAME = 'devices'
const device = new schema.Entity(
  DEVICE_ENTITY_NAME,
  {
    user,
    type: deviceType,
    status: deviceStatus,
    model: deviceModel,
    coordinates: [deviceCoordinate],
    statusChangeCode: deviceStatusChangeCode,
    markerType: deviceMarkerType,
    attributeValues: [deviceAttributeValue],
    inputs: [deviceInput],
    outputs: [deviceOutput]
  },
  {
    processStrategy: value => ({
      userId: value.user?.id,
      typeId: value.type?.id,
      statusId: value.status?.id,
      modelId: value.model?.id,
      coordinateIds: value.coordinates?.map(coordinate => coordinate.id),
      statusChangeCodeId: value.statusChangeCode?.id,
      markerTypeId: value.markerType?.id,
      attributeValueIds: value.attributeValues?.map(attributeValue => attributeValue.id),
      inputIds: value.inputs?.map(deviceInput => deviceInput.id),
      outputIds: value.outputs?.map(deviceOutput => deviceOutput.id),
      ...value
    })
  }
)

// form
const FORM_ENTITY_NAME = 'forms'
const form = new schema.Entity(FORM_ENTITY_NAME)

// formFillData
const FORM_FILL_DATA_ENTITY_NAME = 'formFillData'
const formFillData = new schema.Entity(
  FORM_FILL_DATA_ENTITY_NAME,
  {
    form
  },
  {
    processStrategy: value => ({
      formId: value.form?.id,
      ...value
    })
  }
)

// profile
const PROFILE_ENTITY_NAME = 'profiles'
const profile = new schema.Entity(
  PROFILE_ENTITY_NAME,
  {
    family,
    language,
    role,
    photo: image
  },
  {
    processStrategy: value => ({
      familyId: value.family?.id,
      languageId: value.language?.id,
      roleId: value.role?.id,
      photoId: value.photo?.id,
      ...value
    })
  }
)

// zone shape type
const ZONE_SHAPE_TYPE_ENTITY_NAME = 'zoneShapeTypes'
const zoneShapeType = new schema.Entity(ZONE_SHAPE_TYPE_ENTITY_NAME)

// zone type
const ZONE_TYPE_ENTITY_NAME = 'zoneTypes'
const zoneType = new schema.Entity(ZONE_TYPE_ENTITY_NAME)

// zones
const ZONE_ENTITY_NAME = 'zones'
const zone = new schema.Entity(
  ZONE_ENTITY_NAME,
  {
    type: zoneType,
    shapeType: zoneShapeType
  },
  {
    processStrategy: value => ({
      typeId: value.type?.id,
      shapeTypeId: value.shapeType?.id,
      ...value
    })
  }
)

// zone devices
const ZONE_DEVICE_ENTITY_NAME = 'zoneDevices'
const zoneDevice = new schema.Entity(
  ZONE_DEVICE_ENTITY_NAME,
  {
    zone,
    device
  },
  {
    processStrategy: value => ({
      zoneId: value.zone?.id,
      deviceId: value.device?.id,
      ...value
    })
  }
)

// chat groups
const CHAT_GROUP_ENTITY_NAME = 'chatGroups'
const chatGroup = new schema.Entity(
  CHAT_GROUP_ENTITY_NAME,
  {
    users: [user]
  },
  {
    processStrategy: value => ({
      userIds: value.users?.map(user => user.id),
      ...value
    })
  }
)

// message category
const MESSAGE_CATEGORY_ENTITY_NAME = 'messageCategories'
const messageCategory = new schema.Entity(MESSAGE_CATEGORY_ENTITY_NAME)

// messages
const MESSAGE_ENTITY_NAME = 'messages'
const message = new schema.Entity(
  MESSAGE_ENTITY_NAME,
  {
    category: messageCategory,
    receiverUser: user,
    senderUser: user,
    receiverDevice: device,
    senderDevice: device,
    receiverChatGroup: chatGroup
  },
  {
    processStrategy: value => ({
      categoryId: value.category?.id,
      receiverUserId: value.receiverUser?.id,
      senderUserId: value.senderUser?.id,
      senderDeviceId: value.senderDevice?.id,
      receiverDeviceId: value.receiverDevice?.id,
      receiverChatGroupId: value.receiverChatGroup?.id,
      ...value
    })
  }
)

// missionObjective
const MISSION_OBJECTIVE_ENTITY_NAME = 'missionObjectives'
const missionObjective = new schema.Entity(
  MISSION_OBJECTIVE_ENTITY_NAME,
  {
    data: formFillData
  },
  {
    processStrategy: value => ({
      dataId: value.data?.id,
      ...value
    })
  }
)

// mission
const MISSION_ENTITY_NAME = 'missions'
const mission = new schema.Entity(
  MISSION_ENTITY_NAME,
  {
    objectives: [missionObjective],
    data: formFillData
  },
  {
    processStrategy: value => ({
      objectiveIds: value.objectives?.map(objective => objective.id),
      dataId: value.data?.id,
      ...value
    })
  }
)

// services
const SERVICE_ENTITY_NAME = 'services'
const service = new schema.Entity(
  SERVICE_ENTITY_NAME,
  {
    creator: user,
    missions: [mission],
    data: formFillData
  },
  {
    processStrategy: value => ({
      creatorId: value.creator?.id,
      missionIds: value.missions?.map(mission => mission.id),
      dataId: value.data?.id,
      ...value
    })
  }
)

// userActionTypes
const USER_ACTION_TYPE_ENTITY_NAME = 'userActionTypes'
const userActionType = new schema.Entity(USER_ACTION_TYPE_ENTITY_NAME)

// loginUserActionDetails
const LOGIN_USER_ACTION_DETAIL_ENTITY_NAME = 'loginUserActionDetails'
const loginUserActionDetail = new schema.Entity(
  LOGIN_USER_ACTION_DETAIL_ENTITY_NAME,
  {},
  {
    processStrategy: value => ({
      userActionId: value.userAction?.id,
      ...value
    })
  }
)

// userActions
const USER_ACTION_ENTITY_NAME = 'userActions'
const userAction = new schema.Entity(
  USER_ACTION_ENTITY_NAME,
  {
    user,
    type: userActionType,
    loginUserActionDetail
  },
  {
    processStrategy: value => ({
      userId: value.user?.id,
      type: value.type?.id,
      loginId: value.login?.id,
      loginUserActionDetailId: value.loginUserActionDetail?.id,
      ...value
    })
  }
)

// map regions
const MAP_REGION_ENTITY_NAME = 'mapRegions'
const mapRegion = new schema.Entity(MAP_REGION_ENTITY_NAME)

// tracking groups
const TRACKING_GROUP_ENTITY_NAME = 'trackingGroups'
const trackingGroup = new schema.Entity(
  TRACKING_GROUP_ENTITY_NAME,
  {
    targetDevice: device,
    devices: [device]
  },
  {
    processStrategy: value => ({
      targetDeviceId: value.targetDevice?.id,
      deviceIds: value.devices?.map(device => device.id),
      ...value
    })
  }
)

// define circular dependencies
user.define(
  {
    devices: [device]
  }
)

deviceAttributeValue.define(
  { device }
)

deviceCoordinate.define(
  { device }
)

deviceModelInput.define(
  {
    model: deviceModel
  }
)

deviceModelOutput.define(
  {
    model: deviceModel
  }
)

userAction.define(
  {
    login: userAction
  }
)

loginUserActionDetail.define(
  {
    userAction
  }
)

const RESTModelNormalizationSchemas = {
  family: {
    entityName: FAMILY_ENTITY_NAME,
    schema: family
  },
  image: {
    entityName: IMAGE_ENTITY_NAME,
    schema: image
  },
  language: {
    entityName: LANGUAGE_ENTITY_NAME,
    schema: language
  },
  role: {
    entityName: ROLE_ENTITY_NAME,
    schema: role
  },
  user: {
    entityName: USER_ENTITY_NAME,
    schema: user
  },
  deviceType: {
    entityName: DEVICE_TYPE_ENTITY_NAME,
    schema: deviceType
  },
  deviceStatusChangeCode: {
    entityName: DEVICE_STATUS_CHANGE_CODE_ENTITY_NAME,
    schema: deviceStatusChangeCode
  },
  deviceStatus: {
    entityName: DEVICE_STATUS_ENTITY_NAME,
    schema: deviceStatus
  },
  deviceModel: {
    entityName: DEVICE_MODEL_ENTITY_NAME,
    schema: deviceModel
  },
  deviceMarkerType: {
    entityName: DEVICE_MARKER_TYPE_ENTITY_NAME,
    schema: deviceMarkerType
  },
  deviceCoordinate: {
    entityName: DEVICE_COORDINATE_ENTITY_NAME,
    schema: deviceCoordinate
  },
  deviceAttribute: {
    entityName: DEVICE_ATTRIBUTE_ENTITY_NAME,
    schema: deviceAttribute
  },
  deviceAttributeValue: {
    entityName: DEVICE_ATTRIBUTE_VALUE_ENTITY_NAME,
    schema: deviceAttributeValue
  },
  deviceModelAttribute: {
    entityName: DEVICE_MODEL_ATTRIBUTE_ENTITY_NAME,
    schema: deviceModelAttribute
  },
  deviceModelInput: {
    entityName: DEVICE_MODEL_INPUT_ENTITY_NAME,
    schema: deviceModelInput
  },
  deviceModelOutput: {
    entityName: DEVICE_MODEL_OUTPUT_ENTITY_NAME,
    schema: deviceModelOutput
  },
  deviceInput: {
    entityName: DEVICE_INPUT_ENTITY_NAME,
    schema: deviceInput
  },
  deviceOutput: {
    entityName: DEVICE_OUTPUT_ENTITY_NAME,
    schema: deviceOutput
  },
  device: {
    entityName: DEVICE_ENTITY_NAME,
    schema: device
  },
  profile: {
    entityName: PROFILE_ENTITY_NAME,
    schema: profile
  },
  zoneShapeType: {
    entityName: ZONE_SHAPE_TYPE_ENTITY_NAME,
    schema: zoneShapeType
  },
  zoneType: {
    entityName: ZONE_TYPE_ENTITY_NAME,
    schema: zoneType
  },
  zone: {
    entityName: ZONE_ENTITY_NAME,
    schema: zone
  },
  zoneDevice: {
    entityName: ZONE_DEVICE_ENTITY_NAME,
    schema: zoneDevice
  },
  form: {
    entityName: FORM_ENTITY_NAME,
    schema: form
  },
  formFillData: {
    entityName: FORM_FILL_DATA_ENTITY_NAME,
    schema: formFillData
  },
  chatGroup: {
    entityName: CHAT_GROUP_ENTITY_NAME,
    schema: chatGroup
  },
  message: {
    entityName: MESSAGE_ENTITY_NAME,
    schema: message
  },
  messageCategory: {
    entityName: MESSAGE_CATEGORY_ENTITY_NAME,
    schema: messageCategory
  },
  missionObjective: {
    entityName: MISSION_OBJECTIVE_ENTITY_NAME,
    schema: missionObjective
  },
  mission: {
    entityName: MISSION_ENTITY_NAME,
    schema: mission
  },
  service: {
    entityName: SERVICE_ENTITY_NAME,
    schema: service
  },
  predefinedEvent: {
    entityName: PREDEFINED_EVENT_ENTITY_NAME,
    schema: predefinedEvent
  },
  userActionType: {
    entityName: USER_ACTION_TYPE_ENTITY_NAME,
    schema: userActionType
  },
  loginUserActionDetail: {
    entityName: LOGIN_USER_ACTION_DETAIL_ENTITY_NAME,
    schema: loginUserActionDetail
  },
  userAction: {
    entityName: USER_ACTION_ENTITY_NAME,
    schema: userAction
  },
  mapRegion: {
    entityName: MAP_REGION_ENTITY_NAME,
    schema: mapRegion
  },
  trackingGroup: {
    entityName: TRACKING_GROUP_ENTITY_NAME,
    schema: trackingGroup
  }
}

export default RESTModelNormalizationSchemas
