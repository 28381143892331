import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import MissionObjectiveDataStoreSagaHandlers from '../../sagas/data/MissionObjectiveDataStore'

const sliceName = 'missionObjectivesStore'

const MissionObjectiveDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  MissionObjectiveDataStoreSagaHandlers
)

export default MissionObjectiveDataStoreSlice
