import UserManagementUserListSlice from '../../slices/experiences/UserManagementUserListSlice'
import USER_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/UserManagement'
import { makeActionCreator } from '../../../modules/utilities/store'
import UserManagementUserListExportSlice from '../../slices/experiences/UserManagementUserListExportSlice'
import UserManagementUserActionListSlice from '../../slices/experiences/UserManagementUserActionListSlice'
import UserManagementUserActionListExportSlice from '../../slices/experiences/UserManagementUserActionListExportSlice'

const UserManagementExperienceActions = {
  list: UserManagementUserListSlice.actions,
  exportList: UserManagementUserListExportSlice.actions,
  actionList: UserManagementUserActionListSlice.actions,
  exportActionList: UserManagementUserActionListExportSlice.actions,
  /**
   * @param userItem {StoreUser}: StoreUser instance containing edited user data.
   * @param onSuccess {function}: executed if creation was successful.
   * @returns {{ type: string, data: { userItem: StoreUser, onSuccess: function } }}
   */
  create: makeActionCreator(USER_MANAGEMENT_EXPERIENCE_TYPES.CREATE, 'userItem', 'onSuccess'),
  /**
   * @param userItem {StoreUser}: StoreUser instance containing edited user data.
   * @param onSuccess {function}: executed if editing was successful.
   * @returns {{ type: string, data: { userItem: StoreUser, onSuccess: function } }}
   */
  edit: makeActionCreator(USER_MANAGEMENT_EXPERIENCE_TYPES.EDIT, 'userItem', 'onSuccess'),
  /**
   * @param userId {string}: id of user to delete.
   * @returns {{ type: string, data: { userId: string } }}
   */
  delete: makeActionCreator(USER_MANAGEMENT_EXPERIENCE_TYPES.DELETE, 'userId')
}

export default UserManagementExperienceActions
