import LoginUserActionDetailDataStoreSelectors from '../selectors/data/LoginUserActionDetails'
import LoginUserActionDetailDataStoreSlice from '../slices/data/LoginUserActionDetailDataStoreSlice'
import StoreLoginUserActionDetail from '../models/LoginUserActionDetail'
import useDataStoreItems from './helpers/useDataStoreItems'

const JSONStoreLoginUserActionDetailToStoreLoginUserActionDetail = JSONStoreLoginUserActionDetail =>
  StoreLoginUserActionDetail.FromJSON(JSONStoreLoginUserActionDetail)

/**
 * Returns requested-/all user action type(s) from state.data.loginUserActionDetails.
 * @param loginUserActionDetailIds {string | string[] | null}: id of requested user action types. If null, all user action
 * types are requested.
 * @param asMap {boolean}: when multiple user action types are requested, return user action  types as an object mapping
 * user action types by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all messages, when no message id is passed.
 * @returns {StoreLoginUserActionDetail | StoreLoginUserActionDetail[]}
 */
const useNormalizedLoginUserActionDetails = (
  loginUserActionDetailIds = null,
  asMap = false,
  fetchAllEnabled = false
) => useDataStoreItems(
  loginUserActionDetailIds,
  LoginUserActionDetailDataStoreSelectors.loginUserActionDetails,
  LoginUserActionDetailDataStoreSlice.actions.fetch,
  JSONStoreLoginUserActionDetailToStoreLoginUserActionDetail,
  asMap,
  fetchAllEnabled
)

export default useNormalizedLoginUserActionDetails
