import useDataStoreItemsFetchedOnce from './helpers/useDataStoreItemsFetchedOnce'
import DeviceTypeDataStoreSelectors from '../selectors/data/DeviceTypes'
import DeviceTypeDataStoreSlice from '../slices/data/DeviceTypeDataStoreSlice'
import StoreDeviceType from '../models/DeviceType'

const JSONStoreDeviceTypeToStoreDeviceType = JSONStoreDeviceType => StoreDeviceType.FromJSON(JSONStoreDeviceType)

/**
 * Returns requested-/all deviceType(s) from state.data.deviceTypes.
 * @param deviceTypeIds {string | string[] | null}: id of requested deviceTypes. If null, all deviceTypes are requested.
 * @param asMap {boolean}: when multiple deviceTypes are requested, return deviceTypes as an object mapping deviceTypes by their id.
 * @returns {StoreDeviceType | StoreDeviceType[]}
 */
const useNormalizedDeviceTypes = (deviceTypeIds = null, asMap = false) => useDataStoreItemsFetchedOnce(
  deviceTypeIds,
  DeviceTypeDataStoreSelectors.deviceTypes,
  DeviceTypeDataStoreSlice.actions.fetch,
  JSONStoreDeviceTypeToStoreDeviceType,
  asMap
)

export default useNormalizedDeviceTypes
