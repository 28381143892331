import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 *
 * Search for message categories.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = async listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.MESSAGE_CATEGORIES,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

/**
 * Update the specified message category's data.
 * @param messageCategoryId {string}
 * @param messageCategoryData {object}
 * @returns {Promise<AxiosResponse<any>>}: updated message category data.
 * @constructor
 */
const Update = async (messageCategoryId, messageCategoryData) => mainHTTPClient.put(
  MAIN_HTTP_API_ENDPOINTS.MESSAGE_CATEGORY.replace(':id', messageCategoryId),
  messageCategoryData
)

const MessageCategoryService = {
  FindMany,
  Update
}

export default MessageCategoryService
