import useDataStoreItemsFetchedOnce from './helpers/useDataStoreItemsFetchedOnce'
import ZoneTypeDataStoreSelectors from '../selectors/data/ZoneTypes'
import ZoneTypeDataStoreSlice from '../slices/data/ZoneTypeDataStoreSlice'
import StoreZoneType from '../models/ZoneType'

const JSONStoreZoneTypeToStoreZoneType = JSONStoreZoneType => StoreZoneType.FromJSON(JSONStoreZoneType)

/**
 * Returns requested-/all zone type(s) from state.data.zoneTypes.
 * @param zoneTypeIds {string | string[] | null}: id of requested zone types. If null, all zone types are requested.
 * @param asMap {boolean}: when multiple zone types are requested, return zone  types as an object mapping zone types by
 * their id.
 * @returns {StoreZoneType | StoreZoneType[]}
 */
const useNormalizedZoneTypes = (zoneTypeIds = null, asMap = false) => useDataStoreItemsFetchedOnce(
  zoneTypeIds,
  ZoneTypeDataStoreSelectors.zoneTypes,
  ZoneTypeDataStoreSlice.actions.fetch,
  JSONStoreZoneTypeToStoreZoneType,
  asMap
)

export default useNormalizedZoneTypes
