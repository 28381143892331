import useDataStoreItems from './helpers/useDataStoreItems'
import ServiceDataStoreSelectors from '../selectors/data/Services'
import ServiceDataStoreSlice from '../slices/data/ServiceDataStoreSlice'
import StoreService from '../models/Service'

const JSONStoreServiceToStoreService = JSONStoreService => StoreService.FromJSON(JSONStoreService)

/**
 * Returns requested-/all service(s) from state.data.services.
 * @param serviceIds {string | string[]}: id of requested services.
 * @param asMap {boolean}: when multiple services are requested, return services as an object mapping services by their id.
 * @returns {StoreService | StoreService[]}
 */
const useNormalizedServices = (serviceIds = null, asMap = false) => useDataStoreItems(
  serviceIds,
  ServiceDataStoreSelectors.services,
  ServiceDataStoreSlice.actions.fetch,
  JSONStoreServiceToStoreService,
  asMap
)

export default useNormalizedServices
