import ZoneTypeService from '../../../services/ZoneType'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const ZoneTypeDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'zoneType',
  ZoneTypeService,
  RESTModelNormalizationSchemas.zoneType.schema,
  [RESTModelNormalizationSchemas.zoneType.entityName]
)

export default ZoneTypeDataStoreSagaHandlers
