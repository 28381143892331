import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import DeviceStatusChangeCodeDataStoreSagaHandlers from '../../sagas/data/DeviceStatusChangeCodeDataStore'

const sliceName = 'deviceStatusChangeCodesStore'

const DeviceStatusChangeCodeDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  DeviceStatusChangeCodeDataStoreSagaHandlers
)

export default DeviceStatusChangeCodeDataStoreSlice
