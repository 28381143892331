import useDataStoreItemsFetchedOnce from './helpers/useDataStoreItemsFetchedOnce'
import DeviceStatusChangeCodeDataStoreSelectors from '../selectors/data/DeviceStatusChangeCodes'
import DeviceStatusChangeCodeDataStoreSlice from '../slices/data/DeviceStatusChangeCodeDataStoreSlice'
import StoreDeviceStatusChangeCode from '../models/DeviceStatusChangeCode'

const JSONStoreDeviceStatusChangeCodeToStoreDeviceStatusChangeCode = JSONStoreDeviceStatusChangeCode => StoreDeviceStatusChangeCode.FromJSON(JSONStoreDeviceStatusChangeCode)

/**
 * Returns requested-/all deviceStatusChangeCode(s) from state.data.deviceStatusChangeCodes.
 * @param deviceStatusChangeCodeIds {string | string[] | null}: id of requested deviceStatusChangeCodes. If null, all deviceStatusChangeCodes are requested.
 * @param asMap {boolean}: when multiple deviceStatusChangeCodes are requested, return deviceStatusChangeCodes as an object mapping deviceStatusChangeCodes by their id.
 * @returns {StoreDeviceStatusChangeCode | StoreDeviceStatusChangeCode[]}
 */
const useNormalizedDeviceStatusChangeCodes = (deviceStatusChangeCodeIds = null, asMap = false) => useDataStoreItemsFetchedOnce(
  deviceStatusChangeCodeIds,
  DeviceStatusChangeCodeDataStoreSelectors.deviceStatusChangeCodes,
  DeviceStatusChangeCodeDataStoreSlice.actions.fetch,
  JSONStoreDeviceStatusChangeCodeToStoreDeviceStatusChangeCode,
  asMap
)

export default useNormalizedDeviceStatusChangeCodes
