class StoreMessage {
  constructor (
    id = undefined,
    content,
    type,
    categoryId,
    senderDeviceId,
    senderUserId,
    receiverDeviceId,
    receiverUserId,
    receiverChatGroupId,
    createdAt
  ) {
    if (!Object.values(StoreMessage.SUPPORTED_MESSAGE_TYPES).includes(type)) {
      throw new Error(`[StoreMessage] Invalid 'type' received: ${type}`)
    }

    this.id = id
    this.content = content
    this.type = type
    this.categoryId = categoryId
    this.category = null
    this.senderDeviceId = senderDeviceId
    this.senderDevice = null
    this.senderUserId = senderUserId
    this.senderUser = null
    this.receiverDeviceId = receiverDeviceId
    this.receiverDevice = null
    this.receiverUserId = receiverUserId
    this.receiverUser = null
    this.receiverChatGroupId = receiverChatGroupId
    this.receiverChatGroup = null
    this.createdAt = new Date(createdAt)
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      content: this.content,
      type: this.type,
      categoryId: this.categoryId,
      // senderDeviceId is read-only
      // senderUserId is read-only
      receiverDeviceId: this.receiverDeviceId,
      receiverUserId: this.receiverUserId,
      receiverChatGroupId: this.receiverChatGroupId
      // createdAt is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    content,
    type,
    categoryId,
    senderDeviceId,
    senderUserId,
    receiverDeviceId,
    receiverUserId,
    receiverChatGroupId,
    createdAt
  }) {
    return new StoreMessage(
      id,
      content,
      type,
      categoryId,
      senderDeviceId,
      senderUserId,
      receiverDeviceId,
      receiverUserId,
      receiverChatGroupId,
      createdAt
    )
  }

  toJSON () {
    return {
      id: this.id,
      content: this.content,
      type: this.type,
      categoryId: this.categoryId,
      senderDeviceId: this.senderDeviceId,
      senderUserId: this.senderUserId,
      receiverDeviceId: this.receiverDeviceId,
      receiverUserId: this.receiverUserId,
      receiverChatGroupId: this.receiverChatGroupId,
      createdAt: this.createdAt
    }
  }

  static FromJSON ({
    id,
    content,
    type,
    categoryId,
    senderDeviceId,
    senderUserId,
    receiverDeviceId,
    receiverUserId,
    receiverChatGroupId,
    createdAt
  }) {
    return new StoreMessage(
      id,
      content,
      type,
      categoryId,
      senderDeviceId,
      senderUserId,
      receiverDeviceId,
      receiverUserId,
      receiverChatGroupId,
      createdAt
    )
  }
}

StoreMessage.SUPPORTED_MESSAGE_TYPES = {
  TEXT: 'text'
}

export default StoreMessage
