import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import FormDataStoreSagaHandlers from '../../sagas/data/FormDataStore'

const sliceName = 'formsStore'

const FormDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  FormDataStoreSagaHandlers
)

export default FormDataStoreSlice
