import { useMemo } from 'react'
import useNormalizedServices from './useNormalizedServices'
import useMissions from './useMissions'
import useUsers from './useUsers'
import useFormFillData from './useFormFillData'

/**
 * Returns the details of requested service.
 * @param serviceId {string}: id of requested service.
 * @returns {StoreService | StoreService[]}
 */
const useServiceDetails = serviceId => {
  const normalizedService = useNormalizedServices(serviceId)
  const missions = useMissions(normalizedService?.missionIds, true)
  const user = useUsers(normalizedService?.creatorId)
  const data = useFormFillData(normalizedService?.dataId)

  return useMemo(
    () => {
      if (normalizedService) {
        normalizedService.missions = missions && Object.keys(missions).length
          ? normalizedService.missionIds.map(missionId => missions[missionId])
          : null
        normalizedService.creator = user
        normalizedService.data = data
      }

      return normalizedService
    },
    [normalizedService, missions, user, data]
  )
}

export default useServiceDetails
