import { put, takeLatest } from 'redux-saga/effects'
import UserService from '../../../services/User'
import ListMetaData from '../../../modules/utilities/list/ListMetaData'
import FilterExpression from '../../../modules/utilities/list/FilterExpression'
import UserDataStoreSlice from '../../slices/data/UserDataStoreSlice'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import UserManagementExperienceActions from '../../actions/experiences/UserManagement'
import USER_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/UserManagement'
import LayoutActions from '../../actions/Layout'
import UserManagementUserListSlice from '../../slices/experiences/UserManagementUserListSlice'
import MessageActions from '../../actions/Messages'
import { AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT, DISPLAYED_MESSAGE_TYPES } from '../../../constants/general'
import UserManagementUserListExportSlice from '../../slices/experiences/UserManagementUserListExportSlice'
import UserManagementUserActionListSlice from '../../slices/experiences/UserManagementUserActionListSlice'
import UserManagementUserActionListExportSlice from '../../slices/experiences/UserManagementUserActionListExportSlice'
import UserActionService from '../../../services/UserAction'
import UserActionDataStoreSlice from '../../slices/data/UserActionDataStoreSlice'

function * fetchUsers (action) {
  const sliceActions = action.type === UserManagementUserListExportSlice.types.FETCH
    ? UserManagementExperienceActions.exportList
    : UserManagementExperienceActions.list
  try {
    const { data: { limit, page, sort, filters, filtersLinkingType } } = action
    yield put(sliceActions.setIsLoading(true))

    const result = yield UserService.FindMany(
      new ListMetaData(
        limit,
        page,
        sort,
        new FilterExpression(
          filtersLinkingType,
          ...filters
        )
      )
    )
    yield put(
      UserDataStoreSlice.actions.fetchResult(
        result,
        function * (storeValues) {
          yield put(
            sliceActions.setData(storeValues.result, storeValues.totalNrOfItems, false)
          )
        }
      )
    )
  } catch (e) {
    yield put(sliceActions.setIsLoading(false))
    yield put(ErrorActions.trigger(
      new OLError('[UserManagementExperience->fetchUsers]: Failed to fetch users', 'Failed to fetch users', e),
      true,
      true,
      true,
      false
    ))
  }
}

function * createUser (action) {
  const fetchId = 'UserManagementExperienceSaga.createUser'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { userItem, onSuccess } } = action

    const result = yield UserService.Create(userItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['CREATE_USER_SUCCESS', 'User successfully created'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[UserManagementExperience->createUser]: Failed to create user', 'Failed to create user', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * editUser (action) {
  const fetchId = 'UserManagementExperienceSaga.editUser'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { userItem, onSuccess } } = action

    const result = yield UserService.Update(userItem.id, userItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['EDIT_USER_SUCCESS', 'User successfully updated'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[UserManagementExperience->editUser]: Failed to update user', 'Failed to update user', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * deleteUser (action) {
  const fetchId = 'UserManagementExperienceSaga.deleteUser'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { userId } } = action

    const result = yield UserService.Delete(userId)
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['DELETE_USER_SUCCESS', 'User deleted'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[UserManagementExperience->deleteUser]: Failed to delete user', 'Failed to delete user', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * fetchUserActions (action) {
  const sliceActions = action.type === UserManagementUserActionListExportSlice.types.FETCH
    ? UserManagementExperienceActions.exportActionList
    : UserManagementExperienceActions.actionList
  try {
    const { data: { limit, page, sort, filters, filtersLinkingType } } = action
    yield put(sliceActions.setIsLoading(true))

    const result = yield UserActionService.FindMany(
      new ListMetaData(
        limit,
        page,
        sort,
        new FilterExpression(
          filtersLinkingType,
          ...filters
        )
      )
    )
    yield put(
      UserActionDataStoreSlice.actions.fetchResult(
        result,
        function * (storeValues) {
          yield put(
            sliceActions.setData(storeValues.result, storeValues.totalNrOfItems, false)
          )
        }
      )
    )
  } catch (e) {
    yield put(sliceActions.setIsLoading(false))
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[UserManagementExperience->fetchUserActions]: Failed to fetch user actions',
          'Failed to fetch user actions',
          e
        ),
        true,
        true,
        true,
        false
      )
    )
  }
}

function * UserManagementExperienceSaga () {
  yield takeLatest(UserManagementUserListSlice.types.FETCH, fetchUsers)
  yield takeLatest(UserManagementUserListExportSlice.types.FETCH, fetchUsers)
  yield takeLatest(USER_MANAGEMENT_EXPERIENCE_TYPES.CREATE, createUser)
  yield takeLatest(USER_MANAGEMENT_EXPERIENCE_TYPES.EDIT, editUser)
  yield takeLatest(USER_MANAGEMENT_EXPERIENCE_TYPES.DELETE, deleteUser)
  yield takeLatest(UserManagementUserActionListSlice.types.FETCH, fetchUserActions)
  yield takeLatest(UserManagementUserActionListExportSlice.types.FETCH, fetchUserActions)
}

export default UserManagementExperienceSaga
