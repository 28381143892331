import { combineReducers } from 'redux'
import UserManagementUserListSlice from '../../slices/experiences/UserManagementUserListSlice'
import UserManagementUserListExportSlice from '../../slices/experiences/UserManagementUserListExportSlice'
import UserManagementUserActionListSlice from '../../slices/experiences/UserManagementUserActionListSlice'
import UserManagementUserActionListExportSlice from '../../slices/experiences/UserManagementUserActionListExportSlice'

const UserManagementExperienceStore = combineReducers({
  list: UserManagementUserListSlice.reducer,
  exportList: UserManagementUserListExportSlice.reducer,
  actionList: UserManagementUserActionListSlice.reducer,
  exportActionList: UserManagementUserActionListExportSlice.reducer
})

export default UserManagementExperienceStore
