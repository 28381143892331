import DeviceService from '../../../services/Device'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const DeviceDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'device',
  DeviceService,
  RESTModelNormalizationSchemas.device.schema,
  [
    RESTModelNormalizationSchemas.device.entityName,
    RESTModelNormalizationSchemas.deviceAttributeValue.entityName,
    RESTModelNormalizationSchemas.deviceCoordinate.entityName,
    RESTModelNormalizationSchemas.deviceInput.entityName,
    RESTModelNormalizationSchemas.deviceOutput.entityName,
    RESTModelNormalizationSchemas.deviceModel.entityName,
    RESTModelNormalizationSchemas.user.entityName
  ]
)

export default DeviceDataStoreSagaHandlers
