import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Create a new zone.
 * @param zoneData {object}: Zone data.
 * @returns {Promise<AxiosResponse<object>>}: newly created zone.
 * @constructor
 */
const Create = async zoneData => mainHTTPClient.post(
  MAIN_HTTP_API_ENDPOINTS.ZONES,
  zoneData
)

/**
 * Get data of specified zone.
 * @param zoneId {string}
 * @returns {Promise<AxiosResponse<object>>}: zone data.
 * @constructor
 */
const GetZoneInfo = async zoneId => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.ZONE.replace(':id', zoneId)
)

/**
 * Update the specified zone's data.
 * @param zoneId {string}
 * @param zoneData {object}
 * @returns {Promise<AxiosResponse<any>>}: updated zone data.
 * @constructor
 */
const Update = async (zoneId, zoneData) => mainHTTPClient.put(
  MAIN_HTTP_API_ENDPOINTS.ZONE.replace(':id', zoneId),
  zoneData
)

/**
 * Delete specified zone.
 * @param zoneId {string}
 * @returns {Promise<AxiosResponse<string>>}: OK in case the deletion was successful.
 * @constructor
 */
const Delete = async zoneId => mainHTTPClient.delete(
  MAIN_HTTP_API_ENDPOINTS.ZONE.replace(':id', zoneId)
)

/**
 * Update specified zone's `active` attribute.
 * @param zoneId {string}
 * @param active {boolean}
 * @returns {Promise<AxiosResponse<any>>}: updated zone data.
 * @constructor
 */
const UpdateAttributeActive = async (zoneId, active) => mainHTTPClient.put(
  MAIN_HTTP_API_ENDPOINTS.ZONE_ACTIVE_ATTRIBUTE.replace(':id', zoneId),
  {
    active
  }
)

/**
 *
 * Search for zones.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = async listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.ZONES,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

const ZoneService = {
  Create,
  GetZoneInfo,
  Update,
  Delete,
  UpdateAttributeActive,
  FindMany
}

export default ZoneService
