import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import UserActionDataStoreSagaHandlers from '../../sagas/data/UserActionDataStore'

const sliceName = 'userActionsStore'

const UserActionDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  UserActionDataStoreSagaHandlers
)

export default UserActionDataStoreSlice
