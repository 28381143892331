import FormFillDataService from '../../../services/FormFillData'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const FormFillDataDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'formFillData',
  FormFillDataService,
  RESTModelNormalizationSchemas.formFillData.schema,
  [
    RESTModelNormalizationSchemas.formFillData.entityName,
    RESTModelNormalizationSchemas.form.entityName
  ]
)

export default FormFillDataDataStoreSagaHandlers
