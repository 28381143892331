import { put, takeLatest } from 'redux-saga/effects'
import ServiceService from '../../../services/Service'
import ListMetaData from '../../../modules/utilities/list/ListMetaData'
import FilterExpression from '../../../modules/utilities/list/FilterExpression'
import ServiceDataStoreSlice from '../../slices/data/ServiceDataStoreSlice'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import ServiceManagementExperienceActions from '../../actions/experiences/ServiceManagement'
import SERVICE_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/ServiceManagement'
import LayoutActions from '../../actions/Layout'
import ServiceManagementServiceListSlice from '../../slices/experiences/ServiceManagementServiceListSlice'
import MessageActions from '../../actions/Messages'
import { AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT, DISPLAYED_MESSAGE_TYPES } from '../../../constants/general'
import MissionService from '../../../services/Mission'
import ServiceManagementServiceListExportSlice from '../../slices/experiences/ServiceManagementServiceListExportSlice'

function * fetchServices (action) {
  const sliceActions = action.type === ServiceManagementServiceListExportSlice.types.FETCH
    ? ServiceManagementExperienceActions.exportList
    : ServiceManagementExperienceActions.list
  try {
    const { data: { limit, page, sort, filters, filtersLinkingType } } = action
    yield put(sliceActions.setIsLoading(true))

    const result = yield ServiceService.FindMany(
      new ListMetaData(
        limit,
        page,
        sort,
        new FilterExpression(
          filtersLinkingType,
          ...filters
        )
      )
    )
    yield put(
      ServiceDataStoreSlice.actions.fetchResult(
        result,
        function * (storeValues) {
          yield put(
            sliceActions.setData(storeValues.result, storeValues.totalNrOfItems, false)
          )
        }
      )
    )
  } catch (e) {
    yield put(sliceActions.setIsLoading(false))
    yield put(ErrorActions.trigger(
      new OLError('[ServiceManagementExperience->fetchServices]: Failed to fetch services', 'Failed to fetch services', e),
      true,
      true,
      true,
      false
    ))
  }
}

function * editMission (action) {
  const fetchId = 'ServiceManagementExperienceSaga.editMission'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { missionItem, onSuccess } } = action

    const result = yield MissionService.Update(missionItem.id, missionItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['EDIT_MISSION_SUCCESS', 'Mission successfully updated'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[ServiceManagementExperience->editService]: Failed to update mission', 'Failed to update mission', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * ServiceManagementExperienceSaga () {
  yield takeLatest(ServiceManagementServiceListSlice.types.FETCH, fetchServices)
  yield takeLatest(ServiceManagementServiceListExportSlice.types.FETCH, fetchServices)
  yield takeLatest(SERVICE_MANAGEMENT_EXPERIENCE_TYPES.EDIT_MISSION, editMission)
}

export default ServiceManagementExperienceSaga
