import PropTypes from 'prop-types'
import { AbacProvider } from 'react-abac'
import { useSelector } from 'react-redux'
import { selectAC } from '../../../redux/selectors/AC'
import { USER_ROLES } from '../constants'

const AccessControlProvider = ({ children, loadingPlaceholder }) => {
  const { rulesPopulated, rules } = useSelector(selectAC)
  // select the auth user from your auth store here
  const authUser = {
    id: 1,
    roles: [USER_ROLES.SUPER_ADMIN],
    permissions: [] // user permissions can be directly populated here and the other rbac and abac rules will be ignored
  }

  return rulesPopulated
    ? (
      <AbacProvider
        user={authUser}
        roles={authUser.roles}
        rules={rules}
        permissions={authUser.permissions}
      >
        {children}
      </AbacProvider>
      )
    : loadingPlaceholder || null
}

AccessControlProvider.propTypes = {
  children: PropTypes.node,
  loadingPlaceholder: PropTypes.node
}

export default AccessControlProvider
