class StoreDeviceAttributeInputConfiguration {
  constructor (
    type,
    name,
    label,
    defaultValue,
    isRequired,
    containerProps,
    gridProps,
    customAttributes
  ) {
    if (!Object.values(StoreDeviceAttributeInputConfiguration.SUPPORTED_INPUT_TYPES).includes(type)) {
      throw new Error(`[StoreDeviceAttributeInputConfiguration] Invalid 'type' received: ${type}`)
    }

    this.type = type
    this.name = name
    this.label = label
    this.defaultValue = defaultValue
    this.isRequired = isRequired
    this.containerProps = containerProps
    this.gridProps = gridProps
    this.customAttributes = customAttributes
  }

  toNormalizedRESTModel () {
    return ({
      type: this.type,
      name: this.name,
      label: this.label,
      defaultValue: this.defaultValue,
      isRequired: this.isRequired,
      containerProps: this.containerProps,
      gridProps: this.gridProps,
      customAttributes: this.customAttributes
    })
  }

  static FromNormalizedRESTModel ({
    type,
    name,
    label,
    defaultValue,
    isRequired,
    containerProps,
    gridProps,
    customAttributes
  }) {
    return new StoreDeviceAttributeInputConfiguration(
      type,
      name,
      label,
      defaultValue,
      isRequired,
      containerProps,
      gridProps,
      customAttributes
    )
  }

  toJSON () {
    return {
      type: this.type,
      name: this.name,
      label: this.label,
      defaultValue: this.defaultValue,
      isRequired: this.isRequired,
      containerProps: this.containerProps,
      gridProps: this.gridProps,
      customAttributes: this.customAttributes
    }
  }

  static FromJSON ({
    type,
    name,
    label,
    defaultValue,
    isRequired,
    containerProps,
    gridProps,
    customAttributes
  }) {
    return new StoreDeviceAttributeInputConfiguration(
      type,
      name,
      label,
      defaultValue,
      isRequired,
      containerProps,
      gridProps,
      customAttributes
    )
  }
}

StoreDeviceAttributeInputConfiguration.SUPPORTED_INPUT_TYPES = {
  TEXT: 'text',
  NUMBER: 'number',
  SELECT: 'select',
  RADIO: 'radio',
  SWITCH: 'switch'
}

export default StoreDeviceAttributeInputConfiguration
