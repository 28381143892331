import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Create a new user.
 * @param familyData {object}
 * @returns {Promise<AxiosResponse<object>>}: newly created family.
 * @constructor
 */
const Create = async familyData => mainHTTPClient.post(
  MAIN_HTTP_API_ENDPOINTS.FAMILIES,
  familyData
)

/**
 * Get data of specified family.
 * @param familyId {string}
 * @returns {Promise<AxiosResponse<object>>}: family data.
 * @constructor
 */
const GetFamilyInfo = async familyId => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.FAMILY.replace(':id', familyId)
)

/**
 * Update the specified family's data.
 * @param familyId {string}
 * @param familyData {object}
 * @returns {Promise<AxiosResponse<any>>}: updated family data.
 * @constructor
 */
const Update = async (familyId, familyData) => mainHTTPClient.put(
  MAIN_HTTP_API_ENDPOINTS.FAMILY.replace(':id', familyId),
  familyData
)

/**
 * Delete specified family.
 * @param familyId {string}
 * @returns {Promise<AxiosResponse<string>>}: OK in case the deletion was successful.
 * @constructor
 */
const Delete = async familyId => mainHTTPClient.delete(
  MAIN_HTTP_API_ENDPOINTS.FAMILY.replace(':id', familyId)
)

/**
 * Search for families.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.FAMILIES,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

const FamilyService = {
  Create,
  GetFamilyInfo,
  Update,
  Delete,
  FindMany
}

export default FamilyService
