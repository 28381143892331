const selectServices = state => state.data.services

const selectServiceById = serviceId => state => state.data.services[serviceId]

const ServiceDataStoreSelectors = {
  services: selectServices,
  serviceById: selectServiceById
}

export default ServiceDataStoreSelectors
