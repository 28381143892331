import * as React from 'react'
import PropTypes from 'prop-types'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
/* import Link from '@mui/material/Link' */
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import HookedTextField from '../../modules/formManagement/components/inputs/TextField'
import HookedCheckbox from '../../modules/formManagement/components/inputs/Checkbox'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import MUIContainer from '@mui/material/Container'
import Fade from '@mui/material/Fade'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'

const containerSX = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const logoContainerSX = {
  textAlign: 'center'
}

const logoSX = {
  margin: 'auto',
  bgColor: 'secondary.main',
  width: 192,
  height: 192
}

const cardSX = {
  borderRadius: 3
}

const cardContentSX = {
  p: 4
}

const authCodeLineSX = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  height: '100%'
}

const LoginUI = ({
  labels,
  onSubmit,
  disabled,
  logoURI,
  twoFAAuthCodePrefix,
  displayMode,
  onLogInWithWSS/*, forgotPasswordPageLink */
}) => (
  <MUIContainer component='main' maxWidth='xs'>
    <Box sx={containerSX}>
      <Card sx={cardSX}>
        <CardContent sx={cardContentSX}>
          <Box sx={logoContainerSX}>
            <Avatar
              sx={logoSX}
              src={logoURI}
            >
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
              {labels.title}
            </Typography>
          </Box>
          <Box
            component='form'
            onSubmit={onSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            {displayMode === LoginUI.DISPLAY_MODES.TWO_FA_FIRST_STEP && (
              <>
                <Fade in={displayMode === LoginUI.DISPLAY_MODES.TWO_FA_FIRST_STEP}>
                  <HookedTextField
                    margin='normal'
                    required
                    fullWidth
                    label={labels.emailInput}
                    disabled={disabled}
                    name='email'
                    autoComplete='email'
                    autoFocus
                  />
                </Fade>
                <Fade in={displayMode === LoginUI.DISPLAY_MODES.TWO_FA_FIRST_STEP}>
                  <HookedTextField
                    margin='normal'
                    required
                    fullWidth
                    name='password'
                    label={labels.passwordInput}
                    disabled={disabled}
                    type='password'
                    autoComplete='current-password'
                  />
                </Fade>
              </>
            )}
            {displayMode === LoginUI.DISPLAY_MODES.TWO_FA_SECOND_STEP && (
              <Fade in={displayMode === LoginUI.DISPLAY_MODES.TWO_FA_SECOND_STEP}>
                <Grid
                  container
                  flex={1}
                  alignItems='flex-end'
                >
                  <Grid item xs={2}>
                    <TextField
                      disabled
                      value={twoFAAuthCodePrefix}
                      margin='normal'
                      variant='standard'
                    />
                  </Grid>
                  <Grid item xs={1} mb={1}>
                    <Typography sx={authCodeLineSX}> - </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <HookedTextField
                      margin='normal'
                      required
                      label={labels.authCodeInput}
                      disabled={disabled}
                      name='authCode'
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Fade>
            )}
            <HookedCheckbox
              margin='normal'
              fullWidth
              name='rememberMe'
              label={labels.rememberMe}
              disabled={disabled}
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
              color='primary'
              disabled={disabled}
            >
              {labels.loginButton}
            </Button>
            <Button
              fullWidth
              variant='contained'
              onClick={onLogInWithWSS}
              sx={{ mt: 3, mb: 2 }}
              color='secondary'
              disabled={disabled}
            >
              {labels.loginWithWSSButton}
            </Button>
            {/* <Grid container>
          <Grid item xs>
            <Link href={forgotPasswordPageLink} variant='body2'>
              {labels.forgotPasswordLinkLabel}
            </Link>
          </Grid>
        </Grid> */}
          </Box>
        </CardContent>
      </Card>
    </Box>
  </MUIContainer>
)

LoginUI.DISPLAY_MODES = {
  TWO_FA_FIRST_STEP: 1,
  TWO_FA_SECOND_STEP: 2
}

LoginUI.propTypes = {
  /** Labels used on the login UI **/
  labels: PropTypes.shape({
    title: PropTypes.string.isRequired,
    emailInput: PropTypes.string.isRequired,
    passwordInput: PropTypes.string.isRequired,
    rememberMe: PropTypes.string.isRequired,
    authCodeInput: PropTypes.string.isRequired,
    loginButton: PropTypes.string.isRequired,
    loginWithWSSButton: PropTypes.string.isRequired,
    forgotPasswordLinkLabel: PropTypes.string.isRequired
  }),
  /** Logo file URI **/
  logoURI: PropTypes.string,
  /** Link to the forgot password page **/
  forgotPasswordPageLink: PropTypes.string.isRequired,
  /** Prefix of two-factor authentication code **/
  twoFAAuthCodePrefix: PropTypes.string,
  /** It can be specified in which mode the login form should be displayed **/
  displayMode: PropTypes.oneOf(
    Object.values(LoginUI.DISPLAY_MODES)
  ),
  /** Is completing the login form and clicking on buttons disabled? **/
  disabled: PropTypes.bool,
  /**
   * {func(formData: {
   *   email {string}
   *   password {string}
   *   rememberMe {boolean}
   * })}: Executed when the submit button is clicked, and validation is passed.
   */
  onSubmit: PropTypes.func,
  /**
   * {func()}: Executed when the "Log in with wss" button is clicked.
   */
  onLogInWithWSS: PropTypes.func
}

export default LoginUI
