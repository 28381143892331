class StoreLoginUserActionDetail {
  constructor (
    id = undefined,
    userActionId,
    deviceType,
    ipAddress,
    macAddress,
    geolocationAddress,
    status
  ) {
    this.id = id
    this.userActionId = userActionId
    this.userAction = null
    this.deviceType = deviceType
    this.ipAddress = ipAddress
    this.macAddress = macAddress
    this.geolocationAddress = geolocationAddress
    this.status = status
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      // userActionId is read-only
      // deviceType is read-only
      // ipAddress is read-only
      // macAddress is read-only
      // geolocationAddress is read-only
      // status is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    userActionId,
    deviceType,
    ipAddress,
    macAddress,
    geolocationAddress,
    status
  }) {
    return new StoreLoginUserActionDetail(
      id,
      userActionId,
      deviceType,
      ipAddress,
      macAddress,
      geolocationAddress,
      status
    )
  }

  toJSON () {
    return {
      id: this.id,
      userActionId: this.userActionId,
      deviceType: this.deviceType,
      ipAddress: this.ipAddress,
      macAddress: this.macAddress,
      geolocationAddress: this.geolocationAddress,
      status: this.status
    }
  }

  static FromJSON ({
    id,
    userActionId,
    deviceType,
    ipAddress,
    macAddress,
    geolocationAddress,
    status
  }) {
    return new StoreLoginUserActionDetail(
      id,
      userActionId,
      deviceType,
      ipAddress,
      macAddress,
      geolocationAddress,
      status
    )
  }
}

StoreLoginUserActionDetail.DEVICE_TYPES = {
  PC: 0,
  MOBILE: 1
}

StoreLoginUserActionDetail.STATUSES = {
  SUCCESS: 0,
  FAILED: 1
}

export default StoreLoginUserActionDetail
