class StoreMessageCategory {
  constructor (
    id = undefined,
    name,
    color,
    canBeDirectlySentByChat,
    isDefaultChatMessageType,
    soundPath
  ) {
    this.id = id
    this.name = name
    this.color = color
    this.canBeDirectlySentByChat = canBeDirectlySentByChat
    this.isDefaultChatMessageType = isDefaultChatMessageType
    this.soundPath = soundPath
    this.sound = this.soundPath
      ? new window.Audio(this.soundPath)
      : null
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      // name is read-only
      // color is read-only
      canBeDirectlySentByChat: this.canBeDirectlySentByChat,
      isDefaultChatMessageType: this.isDefaultChatMessageType,
      soundPath: this.soundPath
    })
  }

  static FromNormalizedRESTModel ({
    id,
    name,
    color,
    canBeDirectlySentByChat,
    isDefaultChatMessageType,
    soundPath
  }) {
    return new StoreMessageCategory(
      id,
      name,
      color,
      canBeDirectlySentByChat,
      isDefaultChatMessageType,
      soundPath
    )
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name,
      color: this.color,
      canBeDirectlySentByChat: this.canBeDirectlySentByChat,
      isDefaultChatMessageType: this.isDefaultChatMessageType,
      soundPath: this.soundPath
    }
  }

  static FromJSON ({
    id,
    name,
    color,
    canBeDirectlySentByChat,
    isDefaultChatMessageType,
    soundPath
  }) {
    return new StoreMessageCategory(
      id,
      name,
      color,
      canBeDirectlySentByChat,
      isDefaultChatMessageType,
      soundPath
    )
  }
}

export default StoreMessageCategory
