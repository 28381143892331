import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import ZoneDataStoreSagaHandlers from '../../sagas/data/ZoneDataStore'

const sliceName = 'zonesStore'

const ZoneDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  ZoneDataStoreSagaHandlers
)

export default ZoneDataStoreSlice
