import FamilyDataStoreSlice from '../slices/data/FamilyDataStoreSlice'
import FamilyDataStoreSelectors from '../selectors/data/Families'
import StoreFamily from '../models/Family'
import useDataStoreItemsFetchedOnce from './helpers/useDataStoreItemsFetchedOnce'

const JSONStoreFamilyToStoreFamily = JSONStoreFamily => StoreFamily.FromJSON(JSONStoreFamily)

/**
 * Returns requested-/all family(s) from state.data.families.
 * @param familyIds {string | string[] | null}: id of requested families. If null, all families are requested.
 * @param asMap {boolean}: when multiple families are requested, return families as an object mapping families by their id.
 * @returns {StoreFamily | StoreFamily[]}
 */
const useNormalizedFamilies = (familyIds = null, asMap = false) => useDataStoreItemsFetchedOnce(
  familyIds,
  FamilyDataStoreSelectors.families,
  FamilyDataStoreSlice.actions.fetch,
  JSONStoreFamilyToStoreFamily,
  asMap
)

export default useNormalizedFamilies
