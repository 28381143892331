import RESTModelNormalizationSchemas from './models/RESTModelNormalizationSchemas'
import StoreChatGroup from './models/ChatGroup'
import StoreDevice from './models/Device'
import StoreDeviceAttribute from './models/DeviceAttribute'
import StoreDeviceAttributeValue from './models/DeviceAttributeValue'
import StoreDeviceCoordinate from './models/DeviceCoordinate'
import StoreDeviceInput from './models/DeviceInput'
import StoreDeviceMarkerType from './models/DeviceMarkerType'
import StoreDeviceModel from './models/DeviceModel'
import StoreDeviceModelAttribute from './models/DeviceModelAttribute'
import StoreDeviceModelInput from './models/DeviceModelInput'
import StoreDeviceModelOutput from './models/DeviceModelOutput'
import StoreDeviceOutput from './models/DeviceOutput'
import StoreDeviceStatus from './models/DeviceStatus'
import StoreDeviceStatusChangeCode from './models/DeviceStatusChangeCode'
import StoreDeviceType from './models/DeviceType'
import StoreFamily from './models/Family'
import StoreForm from './models/Form/Form'
import StoreFormFillData from './models/Form/FormFillData'
import StoreImage from './models/Image'
import StoreLanguage from './models/Language'
import StoreLoginUserActionDetail from './models/LoginUserActionDetail'
import StoreMapRegion from './models/MapRegion'
import StoreMessage from './models/Message'
import StoreMessageCategory from './models/MessageCategory'
import StoreMission from './models/Mission'
import StoreMissionObjective from './models/MissionObjective'
import StorePredefinedEvent from './models/PredefinedEvent'
import StoreProfile from './models/Profile'
import StoreService from './models/Service'
import StoreTrackingGroup from './models/TrackingGroup'
import StoreUser from './models/User'
import StoreUserAction from './models/UserAction'
import StoreUserActionType from './models/UserActionType'
import StoreZone from './models/Zone'
import StoreZoneDevice from './models/ZoneDevice'
import StoreZoneShapeType from './models/ZoneShapeType'
import StoreZoneType from './models/ZoneType'

export const NORMALIZATION_ENTITY_NAME_TO_STORE_ENTITY_NAME_MAPPING = {
  [RESTModelNormalizationSchemas.chatGroup.entityName]: StoreChatGroup,
  [RESTModelNormalizationSchemas.device.entityName]: StoreDevice,
  [RESTModelNormalizationSchemas.deviceAttribute.entityName]: StoreDeviceAttribute,
  [RESTModelNormalizationSchemas.deviceAttributeValue.entityName]: StoreDeviceAttributeValue,
  [RESTModelNormalizationSchemas.deviceCoordinate.entityName]: StoreDeviceCoordinate,
  [RESTModelNormalizationSchemas.deviceInput.entityName]: StoreDeviceInput,
  [RESTModelNormalizationSchemas.deviceMarkerType.entityName]: StoreDeviceMarkerType,
  [RESTModelNormalizationSchemas.deviceModel.entityName]: StoreDeviceModel,
  [RESTModelNormalizationSchemas.deviceModelAttribute.entityName]: StoreDeviceModelAttribute,
  [RESTModelNormalizationSchemas.deviceModelInput.entityName]: StoreDeviceModelInput,
  [RESTModelNormalizationSchemas.deviceModelOutput.entityName]: StoreDeviceModelOutput,
  [RESTModelNormalizationSchemas.deviceOutput.entityName]: StoreDeviceOutput,
  [RESTModelNormalizationSchemas.deviceStatus.entityName]: StoreDeviceStatus,
  [RESTModelNormalizationSchemas.deviceStatusChangeCode.entityName]: StoreDeviceStatusChangeCode,
  [RESTModelNormalizationSchemas.deviceType.entityName]: StoreDeviceType,
  [RESTModelNormalizationSchemas.family.entityName]: StoreFamily,
  [RESTModelNormalizationSchemas.form.entityName]: StoreForm,
  [RESTModelNormalizationSchemas.formFillData.entityName]: StoreFormFillData,
  [RESTModelNormalizationSchemas.image.entityName]: StoreImage,
  [RESTModelNormalizationSchemas.language.entityName]: StoreLanguage,
  [RESTModelNormalizationSchemas.loginUserActionDetail.entityName]: StoreLoginUserActionDetail,
  [RESTModelNormalizationSchemas.mapRegion.entityName]: StoreMapRegion,
  [RESTModelNormalizationSchemas.message.entityName]: StoreMessage,
  [RESTModelNormalizationSchemas.messageCategory.entityName]: StoreMessageCategory,
  [RESTModelNormalizationSchemas.mission.entityName]: StoreMission,
  [RESTModelNormalizationSchemas.missionObjective.entityName]: StoreMissionObjective,
  [RESTModelNormalizationSchemas.predefinedEvent.entityName]: StorePredefinedEvent,
  [RESTModelNormalizationSchemas.profile.entityName]: StoreProfile,
  [RESTModelNormalizationSchemas.service.entityName]: StoreService,
  [RESTModelNormalizationSchemas.trackingGroup.entityName]: StoreTrackingGroup,
  [RESTModelNormalizationSchemas.user.entityName]: StoreUser,
  [RESTModelNormalizationSchemas.userAction.entityName]: StoreUserAction,
  [RESTModelNormalizationSchemas.userActionType.entityName]: StoreUserActionType,
  [RESTModelNormalizationSchemas.zone.entityName]: StoreZone,
  [RESTModelNormalizationSchemas.zoneDevice.entityName]: StoreZoneDevice,
  [RESTModelNormalizationSchemas.zoneShapeType.entityName]: StoreZoneShapeType,
  [RESTModelNormalizationSchemas.zoneType.entityName]: StoreZoneType
}
