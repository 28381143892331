import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import ZoneTypeDataStoreSagaHandlers from '../../sagas/data/ZoneTypeDataStore'

const sliceName = 'zoneTypesStore'

const ZoneTypeDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  ZoneTypeDataStoreSagaHandlers
)

export default ZoneTypeDataStoreSlice
