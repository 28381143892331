import { useMemo } from 'react'
import useNormalizedPredefinedEvents from './useNormalizedPredefinedEvents'
import { useTranslation } from 'react-i18next'

/**
 * Returns all predefined event(s) from state.data.predefinedEvents as select options.
 * @param addNullElement {boolean}: add an extra option for resetting the selection.
 * @returns {[{value: string, label: string}]}: predefined events as select options.
 */
const usePredefinedEventsAsSelectOptions = (addNullElement = false) => {
  const { t } = useTranslation()
  const normalizedPredefinedEvents = useNormalizedPredefinedEvents(null, false, true)

  return useMemo(
    () => normalizedPredefinedEvents
      ? (
          addNullElement
            ? [
                {
                  value: null,
                  label: t('PredefinedEvent[_NoEvent_]', 'No event selected')
                }
              ]
            : []
        ).concat(
          normalizedPredefinedEvents.map(
            ({ id, name }) => ({
              value: id,
              label: t(`PredefinedEvent[${name}]`, name)
            })
          )
        )
      : [],
    [normalizedPredefinedEvents, addNullElement, t]
  )
}

export default usePredefinedEventsAsSelectOptions
