import useNormalizedMapRegions from './useNormalizedMapRegions'

/**
 * Returns requested-/all mapRegion(s) from state.data.mapRegions in a non-normalized fashion.
 * @param mapRegionIds {string | string[]}: id of requested mapRegions.
 * @param asMap {boolean}: when multiple mapRegions are requested, return mapRegions as an object mapping mapRegions by
 * their id.
 * @returns {StoreMapRegion | StoreMapRegion[]}
 */
const useMapRegions = (mapRegionIds = null, asMap = false) =>
  useNormalizedMapRegions(mapRegionIds, asMap)

export default useMapRegions
