import DeviceMarkerTypeService from '../../../services/DeviceMarkerType'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const DeviceMarkerTypeDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'deviceMarkerType',
  DeviceMarkerTypeService,
  RESTModelNormalizationSchemas.deviceMarkerType.schema,
  [RESTModelNormalizationSchemas.deviceMarkerType.entityName]
)

export default DeviceMarkerTypeDataStoreSagaHandlers
