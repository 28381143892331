import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import MessageCategoryDataStoreSagaHandlers from '../../sagas/data/MessageCategoryDataStore'

const sliceName = 'messageCategoriesStore'

const MessageCategoryDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  MessageCategoryDataStoreSagaHandlers
)

export default MessageCategoryDataStoreSlice
