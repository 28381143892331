import ChatGroupDataStoreSelectors from '../selectors/data/ChatGroups'
import ChatGroupDataStoreSlice from '../slices/data/ChatGroupDataStoreSlice'
import StoreChatGroup from '../models/ChatGroup'
import useDataStoreItems from './helpers/useDataStoreItems'

const JSONStoreChatGroupToStoreChatGroup = JSONStoreChatGroup => StoreChatGroup.FromJSON(JSONStoreChatGroup)

/**
 * Returns requested-/all chatGroup(s) from state.data.chatGroups.
 * @param chatGroupIds {string | string[]}: id of requested chatGroups.
 * @param asMap {boolean}: when multiple chatGroups are requested, return chatGroups as an object mapping chatGroups by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all devices, when no device id is passed.
 * @returns {StoreChatGroup | StoreChatGroup[]}
 */
const useNormalizedChatGroups = (
  chatGroupIds = null, asMap = false, fetchAllEnabled = false
) => useDataStoreItems(
  chatGroupIds,
  ChatGroupDataStoreSelectors.chatGroups,
  ChatGroupDataStoreSlice.actions.fetch,
  JSONStoreChatGroupToStoreChatGroup,
  asMap,
  fetchAllEnabled
)

export default useNormalizedChatGroups
