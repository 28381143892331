import { useMemo } from 'react'
import useNormalizedZoneShapeTypes from './useNormalizedZoneShapeTypes'
import { useTranslation } from 'react-i18next'

const useZoneShapeTypesAsSelectOptions = () => {
  const { t } = useTranslation()
  const zoneShapeTypes = useNormalizedZoneShapeTypes()

  return useMemo(
    () => zoneShapeTypes.map(({ id, name }) => ({
      value: id,
      label: t(`ZoneShapeTypeLabels[${name}]`, name)
    })),
    [zoneShapeTypes, t]
  )
}

export default useZoneShapeTypesAsSelectOptions
