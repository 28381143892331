import MissionObjectiveService from '../../../services/MissionObjective'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const MissionObjectiveDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'missionObjective',
  MissionObjectiveService,
  RESTModelNormalizationSchemas.missionObjective.schema,
  [
    RESTModelNormalizationSchemas.missionObjective.entityName,
    RESTModelNormalizationSchemas.mission.entityName,
    RESTModelNormalizationSchemas.formFillData.entityName
  ]
)

export default MissionObjectiveDataStoreSagaHandlers
