import { useMemo } from 'react'
import useNormalizedMissionObjectives from './useNormalizedMissionObjectives'
import uniq from 'lodash/uniq'
import useFormFillData from './useFormFillData'

/**
 * Returns requested mission objective(s) from state.data.missionObjectives in a normalized fashion.
 * @param missionObjectiveIds {string | string[]}: id of requested missionObjectives.
 * @param asMap {boolean}: when multiple missionObjectives are requested, return missionObjectives as an object mapping missionObjectives by their id.
 * @returns {StoreMissionObjective | StoreMissionObjective[]}
 */
const useMissionObjectives = (missionObjectiveIds = null, asMap = false) => {
  const normalizedMissionObjectives = useNormalizedMissionObjectives(missionObjectiveIds, asMap)
  const relatedIds = useMemo(
    () => {
      const reducedMissionObjectives = typeof missionObjectiveIds === 'string'
        ? asMap
          ? normalizedMissionObjectives && { [normalizedMissionObjectives.id]: normalizedMissionObjectives }
          : normalizedMissionObjectives && [normalizedMissionObjectives]
        : normalizedMissionObjectives

      const relatedIds = (
        asMap
          ? Object.values(reducedMissionObjectives || {})
          : reducedMissionObjectives || []
      ).reduce(
        (relatedIds, missionObjective) => {
          missionObjective.dataId && relatedIds.dataIds.push(missionObjective.dataId)
          return relatedIds
        },
        { dataIds: [] }
      )
      relatedIds.dataIds = uniq(relatedIds.dataIds)

      return relatedIds
    },
    [normalizedMissionObjectives, asMap, missionObjectiveIds]
  )
  const data = useFormFillData(relatedIds?.dataIds, true)

  return useMemo(
    () => {
      const populateMissionObjective = missionObjective => {
        missionObjective.data = data ? data[missionObjective.dataId] : null
        return missionObjective
      }

      return normalizedMissionObjectives && (
        typeof missionObjectiveIds === 'string' || typeof missionObjectiveIds === 'number'
          ? populateMissionObjective(normalizedMissionObjectives)
          : asMap
            ? Object.keys(normalizedMissionObjectives)
              .reduce((missionObjectives, missionObjectiveId) => {
                missionObjectives[missionObjectiveId] = populateMissionObjective(normalizedMissionObjectives[missionObjectiveId])
                return missionObjectives
              }, {})
            : normalizedMissionObjectives.map(populateMissionObjective)
      )
    },
    [normalizedMissionObjectives, missionObjectiveIds, data, asMap]
  )
}

export default useMissionObjectives
