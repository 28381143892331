class StoreMissionObjective {
  constructor (
    id = undefined,
    missionId,
    dataId
  ) {
    this.id = id
    this.missionId = missionId
    this.mission = null
    this.dataId = dataId
    this.data = null
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      // missionId is read-only
      // dataId is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    missionId,
    dataId
  }) {
    return new StoreMissionObjective(
      id,
      missionId,
      dataId
    )
  }

  toJSON () {
    return {
      id: this.id,
      missionId: this.missionId,
      dataId: this.dataId
    }
  }

  static FromJSON ({
    id,
    missionId,
    dataId
  }) {
    return new StoreMissionObjective(
      id,
      missionId,
      dataId
    )
  }
}

export default StoreMissionObjective
