class StoreImage {
  constructor (
    id = undefined,
    path
  ) {
    this.id = id
    this.path = path
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      path: this.path
    })
  }

  static FromNormalizedRESTModel ({
    id,
    path
  }) {
    return new StoreImage(
      id,
      path
    )
  }

  toJSON () {
    return {
      id: this.id,
      path: this.path
    }
  }

  static FromJSON ({
    id,
    path
  }) {
    return new StoreImage(
      id,
      path
    )
  }
}

export default StoreImage
