import LoginUserActionDetailService from '../../../services/LoginUserActionDetail'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const LoginUserActionDetailDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'loginUserActionDetail',
  LoginUserActionDetailService,
  RESTModelNormalizationSchemas.loginUserActionDetail.schema,
  [RESTModelNormalizationSchemas.loginUserActionDetail.entityName]
)

export default LoginUserActionDetailDataStoreSagaHandlers
