import DeviceModelInputService from '../../../services/DeviceModelInput'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const DeviceModelInputDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'deviceModelInput',
  DeviceModelInputService,
  RESTModelNormalizationSchemas.deviceModelInput.schema,
  [
    RESTModelNormalizationSchemas.deviceModelInput.entityName,
    RESTModelNormalizationSchemas.deviceModel.entityName
  ]
)

export default DeviceModelInputDataStoreSagaHandlers
