import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import ServiceDataStoreSagaHandlers from '../../sagas/data/ServiceDataStore'

const sliceName = 'servicesStore'

const ServiceDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  ServiceDataStoreSagaHandlers
)

export default ServiceDataStoreSlice
