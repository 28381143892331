import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import DeviceModelOutputDataStoreSagaHandlers from '../../sagas/data/DeviceModelOutputDataStore'

const sliceName = 'deviceModelOutputsStore'

const DeviceModelOutputDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  DeviceModelOutputDataStoreSagaHandlers
)

export default DeviceModelOutputDataStoreSlice
