import useDataStoreItemsFetchedOnce from './helpers/useDataStoreItemsFetchedOnce'
import DeviceModelAttributeDataStoreSelectors from '../selectors/data/DeviceModelAttributes'
import DeviceModelAttributeDataStoreSlice from '../slices/data/DeviceModelAttributeDataStoreSlice'
import StoreDeviceModelAttribute from '../models/DeviceModelAttribute'
import { useMemo } from 'react'

const JSONStoreDeviceModelAttributeToStoreDeviceModelAttribute = JSONStoreDeviceModelAttribute => StoreDeviceModelAttribute.FromJSON(JSONStoreDeviceModelAttribute)

/**
 * Returns requested-/all deviceModelAttribute(s) from state.data.deviceModelAttributes.
 * @param deviceModelAttributeIds {string | string[] | null}: id of requested deviceModelAttributes. If null, all deviceModelAttributes are requested.
 * @param deviceModelIds {string | string[] | null}: request device-model-attributes having the `deviceModelIds` model
 * id(s). null means device-model-attributes are not filtered by `deviceModelAttribute.modelId`.
 * @param asMap {boolean}: when multiple deviceModelAttributes are requested, return deviceModelAttributes as an object mapping deviceModelAttributes by their id.
 * @returns {StoreDeviceModelAttribute | StoreDeviceModelAttribute[]}
 */
const useNormalizedDeviceModelAttributes = (deviceModelAttributeIds = null, deviceModelIds = null, asMap = false) => {
  const normalizedDeviceModelAttributes = useDataStoreItemsFetchedOnce(
    deviceModelAttributeIds,
    DeviceModelAttributeDataStoreSelectors.deviceModelAttributes,
    DeviceModelAttributeDataStoreSlice.actions.fetch,
    JSONStoreDeviceModelAttributeToStoreDeviceModelAttribute,
    asMap
  )

  return useMemo(
    () => deviceModelIds
      ? normalizedDeviceModelAttributes.filter(
        deviceModelAttribute => Array.isArray(deviceModelIds)
          ? deviceModelIds.includes(deviceModelAttribute.modelId)
          : deviceModelAttribute.modelId === deviceModelIds
      )
      : normalizedDeviceModelAttributes,
    [normalizedDeviceModelAttributes, deviceModelIds]
  )
}

export default useNormalizedDeviceModelAttributes
