import { put, takeLatest } from 'redux-saga/effects'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import IMAGE_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/ImageManagement'
import LayoutActions from '../../actions/Layout'
import MessageActions from '../../actions/Messages'
import { AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT, DISPLAYED_MESSAGE_TYPES } from '../../../constants/general'
import ImageService from '../../../services/Image'

function * createImage (action) {
  const fetchId = 'ImageManagementExperienceSaga.createImage'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { imageBlob, onSuccess } } = action
    const result = yield ImageService.Create(imageBlob)
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['UPLOAD_IMAGE_SUCCESS', 'Image successfully uploaded'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess(result.data.id)
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[ImageManagementExperienceSaga->createImage]: Failed to create image', 'Failed to create image', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * editImage (action) {
  const fetchId = 'ImageManagementExperienceSaga.editImage'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { imageId, imageBlob, onSuccess } } = action

    const result = yield ImageService.Update(imageId, imageBlob)
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['UPLOAD_IMAGE_SUCCESS', 'Image successfully uploaded'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[ImageManagementExperienceSaga->editImage]: Failed to update image', 'Failed to update image', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * deleteImage (action) {
  const fetchId = 'ImageManagementExperienceSaga.deleteImage'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { imageId } } = action

    const result = yield ImageService.Delete(imageId)
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['DELETE_IMAGE_SUCCESS', 'Image deleted'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[ImageManagementExperienceSaga->deleteImage]: Failed to delete image', 'Failed to delete image', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * ImageManagementExperienceSaga () {
  yield takeLatest(IMAGE_MANAGEMENT_EXPERIENCE_TYPES.CREATE, createImage)
  yield takeLatest(IMAGE_MANAGEMENT_EXPERIENCE_TYPES.EDIT, editImage)
  yield takeLatest(IMAGE_MANAGEMENT_EXPERIENCE_TYPES.DELETE, deleteImage)
}

export default ImageManagementExperienceSaga
