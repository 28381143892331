import { useMemo } from 'react'
import useNormalizedForms from './useNormalizedForms'
import StoreFormConfiguration from '../models/Form/FormConfiguration'

/**
 * Returns requested form(s) from state.data.forms in a normalized fashion.
 * @param formIds {string | string[]}: id of requested forms.
 * @param asMap {boolean}: when multiple forms are requested, return forms as an object mapping forms by their id.
 * @returns {StoreForm | StoreForm[]}
 */
const useForms = (formIds = null, asMap = false) => {
  const normalizedForms = useNormalizedForms(formIds, asMap)

  return useMemo(
    () => {
      const populateForm = form => {
        form.configuration = StoreFormConfiguration.FromJSON(
          JSON.parse(form.jsonSchema)
        )
        return form
      }

      return normalizedForms && (
        typeof formIds === 'string'
          ? populateForm(normalizedForms)
          : asMap
            ? Object.keys(normalizedForms)
              .reduce((forms, formId) => {
                forms[formId] = populateForm(normalizedForms[formId])
                return forms
              }, {})
            : normalizedForms.map(populateForm)
      )
    },
    [formIds, normalizedForms, asMap]
  )
}

export default useForms
