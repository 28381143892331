import { useMemo } from 'react'
import useNormalizedUsers from './useNormalizedUsers'
import uniq from 'lodash/uniq'
import useNormalizedServices from './useNormalizedServices'
import useNormalizedMissions from './useNormalizedMissions'
import useNormalizedFormFillData from './useNormalizedFormFillData'

/**
 * Returns requested user(s) from state.data.users in a normalized fashion.
 * @param serviceIds {string | string[]}: id of requested services.
 * @param asMap {boolean}: when multiple services are requested, return services as an object mapping services by their id.
 * @returns {StoreService | StoreService[]}
 */
const useServices = (serviceIds = null, asMap = false) => {
  const normalizedServices = useNormalizedServices(serviceIds, asMap)
  const relatedIds = useMemo(
    () => {
      const reducedServices = typeof serviceIds === 'string'
        ? asMap
          ? normalizedServices && { [normalizedServices.id]: normalizedServices }
          : normalizedServices && [normalizedServices]
        : normalizedServices

      const relatedIds = (
        asMap
          ? Object.values(reducedServices || {})
          : reducedServices || []
      ).reduce(
        (relatedIds, service) => {
          service.missionIds?.length && (relatedIds.missionIds = relatedIds.missionIds.concat(service.missionIds))
          service.creatorId && relatedIds.userIds.push(service.creatorId)
          service.dataId && relatedIds.dataIds.push(service.dataId)
          return relatedIds
        },
        { missionIds: [], userIds: [], dataIds: [] }
      )
      relatedIds.missionIds = uniq(relatedIds.missionIds)
      relatedIds.userIds = uniq(relatedIds.userIds)
      relatedIds.dataIds = uniq(relatedIds.dataIds)

      return relatedIds
    },
    [normalizedServices, asMap, serviceIds]
  )
  const missions = useNormalizedMissions(relatedIds?.missionIds, true)
  const users = useNormalizedUsers(relatedIds?.userIds, true)
  const data = useNormalizedFormFillData(relatedIds?.dataIds, true)

  return useMemo(
    () => {
      const populateService = service => {
        service.missions = service.missionIds.map(missionId => missions[missionId])
        service.creator = users[service.creatorId]
        service.data = data[service.dataId]
        return service
      }

      return normalizedServices && (
        typeof serviceIds === 'string' || typeof serviceIds === 'number'
          ? populateService(normalizedServices)
          : asMap
            ? Object.keys(normalizedServices)
              .reduce((services, serviceId) => {
                services[serviceId] = populateService(normalizedServices[serviceId])
                return services
              }, {})
            : normalizedServices.map(populateService)
      )
    },
    [normalizedServices, serviceIds, missions, users, data, asMap]
  )
}

export default useServices
