class StoreUserAction {
  constructor (
    id = undefined,
    userId,
    typeId,
    loginId,
    loginUserActionDetailId,
    timestamp
  ) {
    this.id = id
    this.userId = userId
    this.user = null
    this.typeId = typeId
    this.type = null
    this.loginId = loginId
    this.login = null
    this.loginUserActionDetailId = loginUserActionDetailId
    this.loginUserActionDetail = null
    this.timestamp = new Date(timestamp)
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      // userId is read-only
      // typeId is read-only
      // loginId is read-only
      // loginUserActionDetailId is read-only
      // timestamp is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    userId,
    typeId,
    loginId,
    loginUserActionDetailId,
    timestamp
  }) {
    return new StoreUserAction(
      id,
      userId,
      typeId,
      loginId,
      loginUserActionDetailId,
      timestamp
    )
  }

  toJSON () {
    return {
      id: this.id,
      userId: this.userId,
      typeId: this.typeId,
      loginId: this.loginId,
      loginUserActionDetailId: this.loginUserActionDetailId,
      timestamp: this.timestamp
    }
  }

  static FromJSON ({
    id,
    userId,
    typeId,
    loginId,
    loginUserActionDetailId,
    timestamp
  }) {
    return new StoreUserAction(
      id,
      userId,
      typeId,
      loginId,
      loginUserActionDetailId,
      timestamp
    )
  }
}

export default StoreUserAction
