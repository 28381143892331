import useDataStoreItemsFetchedOnce from './helpers/useDataStoreItemsFetchedOnce'
import MapRegionDataStoreSelectors from '../selectors/data/MapRegions'
import MapRegionDataStoreSlice from '../slices/data/MapRegionDataStoreSlice'
import StoreMapRegion from '../models/MapRegion'

const JSONStoreMapRegionToStoreMapRegion = JSONStoreMapRegion => StoreMapRegion.FromJSON(JSONStoreMapRegion)

/**
 * Returns requested-/all map region(s) from state.data.mapRegions.
 * @param mapRegionIds {string | string[] | null}: id of requested map regions. If null, all user action
 * types are requested.
 * @param asMap {boolean}: when multiple user action types are requested, return user action  types as an object mapping
 * user action types by their id.
 * @returns {StoreMapRegion | StoreMapRegion[]}
 */
const useNormalizedMapRegions = (mapRegionIds = null, asMap = false) => useDataStoreItemsFetchedOnce(
  mapRegionIds,
  MapRegionDataStoreSelectors.mapRegions,
  MapRegionDataStoreSlice.actions.fetch,
  JSONStoreMapRegionToStoreMapRegion,
  asMap
)

export default useNormalizedMapRegions
