import { createAuth0Client } from '@auth0/auth0-spa-js'
import { IS_TEST_ENV } from '../constants/general'

let mainAuth0ClientInstance

const mainAuth0Client = {
  init: async () => {
    if (!IS_TEST_ENV) {
      mainAuth0ClientInstance = await createAuth0Client({
        domain: process.env.REACT_APP_AUTH0_DOMAIN,
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
        authorizationParams: window.location.origin/*,
        useRefreshTokens: true,
        cacheLocation: 'localstorage' */
      })
    }
  },
  get instance () {
    return mainAuth0ClientInstance
  }
}

export default mainAuth0Client
