import { put } from 'redux-saga/effects'
import LayoutActions from '../../actions/Layout'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import ZoneDeviceService from '../../../services/ZoneDevice'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import ListFetchResult from '../../../modules/utilities/list/ListFetchResult'
import DataStoresActions from '../../actions/data'
import StoreZoneDevice from '../../models/ZoneDevice'

const ZoneDeviceDataStoreSagaHandlers = /**
 * Fetch handler saga creator for the zone-device data-store slice.
 * @param ZoneDeviceDataStoreSlice {{types: {FETCH: string, FETCH_RESULT: string, MERGE: string, RESET: string}, reducer: ((function(*=, *): *)|*), actions: {fetch: function(ids: string[]): object, fetchResult: function(result: AxiosResponse<object[]>, callback: function(storeValue: object)): object, merge: function(data: {}): object, reset: function(): object}, saga: ((function(): Generator<SimpleEffect<"FORK", ForkEffectDescriptor<never>>, void, *>)|*)}} *
 * @returns {function(string, {FindMany: (function(ListMetaData): Promise<AxiosResponse<Object[]>>)}, string, Object, string=): {fetch: function({type: string, data: {ids: (string[]|null)}}): Generator<*, void, *>, fetchResult: function({type: string, data: {result: AxiosResponse<Object[]>, callback: (function(Object))}}): Generator<*, void, *>}}
 */
  ZoneDeviceDataStoreSlice =>
    ({
    /**
     * @param action {{type: string, data: { ids: string }}}
     * @returns {Generator<*, void, *>}
     */
      fetch: function * (action) {
        const fetchId = '[zoneDevice] DataStoreSagaHandlers.fetch'
        try {
          yield put(LayoutActions.setIsLoading(true, fetchId))
          const { data: { ids: zoneId } } = action

          const result = yield ZoneDeviceService.GetByZoneId(zoneId)
          yield put(ZoneDeviceDataStoreSlice.actions.fetchResult(result))
        } catch (e) {
          yield put(ErrorActions.trigger(
            new OLError(
              '[[zoneDevice] DataStoreSagaHandlers.fetch]: Failed to fetch the assigned devices of the specified zone',
              'Failed to fetch the assigned devices of the specified zone',
              e
            ),
            true,
            true,
            true,
            false
          ))
        }
        yield put(LayoutActions.setIsLoading(false, fetchId))
      },
      /**
     * @param action {{type: string, data: { result: AxiosResponse<object[]>, callback: function(storeValue: object) }}}
     * @returns {Generator<*, void, *>}
     */
      fetchResult: function * (action) {
        try {
          const { data: { result, callback } } = action

          const zoneDevices = ListFetchResult.FromAxiosResponse(result).items
            .map(RESTZoneDevice => {
              const zoneDevice = StoreZoneDevice.FromNormalizedRESTModel(RESTZoneDevice)
              zoneDevice.deviceId = RESTZoneDevice.device?.id
              return zoneDevice
            })

          const storeValues = {
            entities: {
              [RESTModelNormalizationSchemas.zoneDevice.entityName]: zoneDevices.length
                ? { [zoneDevices[0].zoneId]: zoneDevices }
                : {}
            },
            result: zoneDevices
          }

          // merge items extracted during normalization(toStoreValues(...)), into their own data store
          yield put(
            DataStoresActions.mergeEntitiesIntoStores({
              [RESTModelNormalizationSchemas.zoneDevice.entityName]: storeValues.entities[RESTModelNormalizationSchemas.zoneDevice.entityName]
            })
          )
          if (callback) {
            yield callback(storeValues)
          } // it is important to execute the callback last so the previously dispatched actions can take their own effect before the callback being executed
        } catch (e) {
          yield put(ErrorActions.trigger(
            new OLError(
              '[[zoneDevice] DataStoreSagaHandlers.fetchResult]: Failed to process the fetched zoneDevice(s)',
              'Failed to process the fetched zoneDevice(s)',
              e
            ),
            true,
            true,
            true,
            false
          ))
        }
      }
    })

export default ZoneDeviceDataStoreSagaHandlers
