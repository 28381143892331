/**
 *
 * @param fields {StoreFormField[]}
 */
import StoreFormField from './FormField'

class StoreFormConfiguration {
  constructor (
    fields
  ) {
    this.fields = fields
  }

  toJSON () {
    const jsonProperties = this.fields
      .map(field => field.toJSONSchema())
      .sort((field1, field2) => field1.position - field2.position)
      .reduce(
        (jsonProperties, field) => {
          const { name, propertySchema, uiSchema, required } = field
          jsonProperties.properties[name] = propertySchema
          jsonProperties.elements.push(uiSchema)
          jsonProperties.required = jsonProperties.required.concat(required)

          return jsonProperties
        },
        { properties: {}, required: [], elements: [] }
      )

    return {
      propertySchema: {
        type: 'object',
        properties: jsonProperties.properties,
        required: jsonProperties.required
      },
      uiSchema: {
        type: 'VerticalLayout',
        elements: jsonProperties.elements
      }
    }
  }

  static FromJSON ({
    propertySchema,
    uiSchema
  }) {
    const fields = Object.keys(propertySchema.properties).map(
      (propertyName, index) => StoreFormField.FromJSONSchema(
        propertyName,
        index,
        propertySchema,
        uiSchema
      )
    )

    return new StoreFormConfiguration(
      fields
    )
  }
}

export default StoreFormConfiguration
