import useDataStoreItemsFetchedOnce from './helpers/useDataStoreItemsFetchedOnce'
import UserActionTypeDataStoreSelectors from '../selectors/data/UserActionTypes'
import UserActionTypeDataStoreSlice from '../slices/data/UserActionTypeDataStoreSlice'
import StoreUserActionType from '../models/UserActionType'

const JSONStoreUserActionTypeToStoreUserActionType = JSONStoreUserActionType => StoreUserActionType.FromJSON(JSONStoreUserActionType)

/**
 * Returns requested-/all user action type(s) from state.data.userActionTypes.
 * @param userActionTypeIds {string | string[] | null}: id of requested user action types. If null, all user action
 * types are requested.
 * @param asMap {boolean}: when multiple user action types are requested, return user action  types as an object mapping
 * user action types by their id.
 * @returns {StoreUserActionType | StoreUserActionType[]}
 */
const useNormalizedUserActionTypes = (userActionTypeIds = null, asMap = false) => useDataStoreItemsFetchedOnce(
  userActionTypeIds,
  UserActionTypeDataStoreSelectors.userActionTypes,
  UserActionTypeDataStoreSlice.actions.fetch,
  JSONStoreUserActionTypeToStoreUserActionType,
  asMap
)

export default useNormalizedUserActionTypes
