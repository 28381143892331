import useNormalizedUserActionTypes from './useNormalizedUserActionTypes'
import { useMemo } from 'react'
import useNormalizedUserActions from './useNormalizedUserActions'
import uniq from 'lodash/uniq'
import useNormalizedUsers from './useNormalizedUsers'
import useNormalizedLoginUserActionDetails from './useNormalizedLoginUserActionDetails'

/**
 * Returns requested userAction(s) from state.data.userActions in a normalized fashion.
 * @param userActionIds {string | string[]}: id of requested userActions.
 * @param userIds {string[] | null}: only actions of the specified users are returned. If null, actions of all users are
 * returned.
 * @param actionTypeNames {string[] | null}: only actions with the specified action types are returned. If null, actions
 * of all action types are returned.
 * @param asMap {boolean}: when multiple userActions are requested, return userActions as an object mapping userActions by their id.
 * @param deNormalizationConfig {{userActionType: boolean, user: boolean, login: boolean, loginUserActionDetail: boolean}}:
 * specify which related models are merged into the userAction object. Default config returns the same result as
 * useNormalizedUserActions.
 * @param fetchAllEnabled {boolean}: enable fetching of all userActions, when no userAction id is passed.
 * @returns {StoreUserAction | StoreUserAction[]}
 */
const useUserActions = (
  userActionIds = null,
  userIds = null,
  actionTypeNames = null,
  asMap = false,
  deNormalizationConfig = {
    userActionType: true,
    user: false,
    login: false,
    loginUserActionDetail: true
  },
  fetchAllEnabled = false
) => {
  const normalizedUserActions = useNormalizedUserActions(userActionIds, userIds, actionTypeNames, asMap, fetchAllEnabled)
  const userActionTypes = useNormalizedUserActionTypes(null, true)
  const relatedIds = useMemo(
    () => {
      const reducedUserActions = typeof userActionIds === 'string'
        ? asMap
          ? normalizedUserActions && { [normalizedUserActions.id]: normalizedUserActions }
          : normalizedUserActions && [normalizedUserActions]
        : normalizedUserActions

      const relatedIds = (
        asMap
          ? Object.values(reducedUserActions || {})
          : reducedUserActions || []
      ).reduce(
        (relatedIds, userAction) => {
          userAction.userId && relatedIds.userIds.push(userAction.userId)
          userAction.loginUserActionDetailId && relatedIds.loginUserActionDetailIds.push(userAction.loginUserActionDetailId)
          userAction.loginId && relatedIds.loginIds.push(userAction.loginId)
          return relatedIds
        },
        { userIds: [], loginUserActionDetailIds: [], loginIds: [] }
      )
      relatedIds.userIds = uniq(relatedIds.userIds)
      relatedIds.loginUserActionDetailIds = uniq(relatedIds.loginUserActionDetailIds)
      relatedIds.loginIds = uniq(relatedIds.loginIds)

      return relatedIds
    },
    [normalizedUserActions, asMap, userActionIds]
  )
  const users = useNormalizedUsers(
    deNormalizationConfig.user ? relatedIds?.userIds : null,
    true,
    false
  )
  const loginUserActionDetails = useNormalizedLoginUserActionDetails(
    deNormalizationConfig.loginUserActionDetail ? relatedIds?.loginUserActionDetailIds : null,
    true,
    false
  )
  const logins = useNormalizedUserActions(
    deNormalizationConfig.login ? relatedIds?.loginIds : null,
    null,
    true,
    false
  )

  return useMemo(
    () => {
      const populateUserAction = userAction => {
        if (deNormalizationConfig.user && users) {
          userAction.user = users[userAction.userId] || null
        }
        if (deNormalizationConfig.userActionType && userActionTypes) {
          userAction.type = userActionTypes[userAction.typeId] || null
        }
        if (deNormalizationConfig.login && logins) {
          userAction.login = logins[userAction.loginId] || null
        }
        if (deNormalizationConfig.loginUserActionDetail && loginUserActionDetails) {
          userAction.loginUserActionDetail = loginUserActionDetails[userAction.loginUserActionDetailId] || null
        }
        return userAction
      }

      return normalizedUserActions && (
        typeof userActionIds === 'string' || typeof userActionIds === 'number'
          ? populateUserAction(normalizedUserActions)
          : asMap
            ? Object.keys(normalizedUserActions)
              .reduce((userActions, userActionId) => {
                userActions[userActionId] = populateUserAction(normalizedUserActions[userActionId])
                return userActions
              }, {})
            : normalizedUserActions.map(populateUserAction)
      )
    },
    [
      normalizedUserActions, userActionIds, userActionTypes, logins, loginUserActionDetails, users, asMap,
      deNormalizationConfig
    ]
  )
}

export default useUserActions
