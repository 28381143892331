import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import DeviceModelDataStoreSagaHandlers from '../../sagas/data/DeviceModelDataStore'

const sliceName = 'deviceModelsStore'

const DeviceModelDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  DeviceModelDataStoreSagaHandlers
)

export default DeviceModelDataStoreSlice
