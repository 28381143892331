class StoreUser {
  constructor (
    id = undefined,
    firstName,
    lastName,
    email,
    identifier,
    phoneNumber,
    password,
    familyId,
    languageId,
    roleId,
    photoId,
    areFormsRequired,
    is2FARequired,
    isActive,
    deviceIds
  ) {
    this.id = id
    this.firstName = firstName
    this.lastName = lastName
    this.email = email
    this.identifier = identifier
    this.phoneNumber = phoneNumber
    this.password = password
    this.familyId = familyId
    this.family = null
    this.languageId = languageId
    this.language = null
    this.roleId = roleId
    this.role = null
    this.photoId = photoId
    this.photo = null
    this.areFormsRequired = areFormsRequired
    this.is2FARequired = is2FARequired
    this.isActive = isActive
    this.deviceIds = deviceIds
    this.devices = null
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      userName: this.identifier,
      mobilePhone: this.phoneNumber,
      password: this.password,
      familyId: this.familyId,
      languageId: this.languageId,
      roleId: this.roleId,
      photoId: this.photoId,
      areFormFillsMandatory: this.areFormsRequired,
      twoFactorAuthEnabled: this.is2FARequired,
      isActive: this.isActive
      // deviceIds is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    firstName,
    lastName,
    email,
    userName,
    mobilePhone,
    familyId,
    languageId,
    roleId,
    photoId,
    areFormFillsMandatory,
    twoFactorAuthEnabled,
    isActive,
    deviceIds
  }) {
    return new StoreUser(
      id,
      firstName,
      lastName,
      email,
      userName,
      mobilePhone,
      undefined,
      familyId,
      languageId,
      roleId,
      photoId,
      areFormFillsMandatory,
      twoFactorAuthEnabled,
      isActive,
      deviceIds
    )
  }

  toJSON () {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      identifier: this.identifier,
      phoneNumber: this.phoneNumber,
      password: this.password,
      familyId: this.familyId,
      languageId: this.languageId,
      roleId: this.roleId,
      photoId: this.photoId,
      areFormsRequired: this.areFormsRequired,
      is2FARequired: this.is2FARequired,
      isActive: this.isActive,
      deviceIds: this.deviceIds
    }
  }

  static FromJSON ({
    id,
    firstName,
    lastName,
    email,
    identifier,
    phoneNumber,
    password,
    familyId,
    languageId,
    roleId,
    photoId,
    areFormsRequired,
    is2FARequired,
    isActive,
    deviceIds
  }) {
    return new StoreUser(
      id,
      firstName,
      lastName,
      email,
      identifier,
      phoneNumber,
      password,
      familyId,
      languageId,
      roleId,
      photoId,
      areFormsRequired,
      is2FARequired,
      isActive,
      deviceIds
    )
  }

  getFullName () {
    return `${this.firstName || ''} ${this.lastName || ''}`
  }
}

export default StoreUser
