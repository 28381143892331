import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Create a new user.
 * @param imageBlob {blob}
 * @returns {Promise<AxiosResponse<object>>}: newly created image.
 * @constructor
 */
const Create = imageBlob => {
  const formData = new FormData()
  formData.append('image', imageBlob)

  return mainHTTPClient.post(
    MAIN_HTTP_API_ENDPOINTS.IMAGES,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

/**
 * Get data of specified image.
 * @param imageId {string}
 * @returns {Promise<AxiosResponse<object>>}: image data.
 * @constructor
 */
const GetImageInfo = imageId => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.IMAGE.replace(':id', imageId)
)

/**
 * Update the specified image's data.
 * @param imageId {string}
 * @param imageBlob {blob}
 * @returns {Promise<AxiosResponse<any>>}: updated image data.
 * @constructor
 */
const Update = (imageId, imageBlob) => {
  const formData = new FormData()
  formData.append('image', imageBlob)

  return mainHTTPClient.put(
    MAIN_HTTP_API_ENDPOINTS.IMAGE.replace(':id', imageId),
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

/**
 * Delete specified image.
 * @param imageId {string}
 * @returns {Promise<AxiosResponse<string>>}: OK in case the deletion was successful.
 * @constructor
 */
const Delete = imageId => mainHTTPClient.delete(
  MAIN_HTTP_API_ENDPOINTS.IMAGE.replace(':id', imageId)
)

/**
 * Search for images.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.IMAGES,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

const ImageService = {
  Create,
  GetImageInfo,
  Update,
  Delete,
  FindMany
}

export default ImageService
