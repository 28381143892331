import { useMemo } from 'react'
import useNormalizedDeviceModels from './useNormalizedDeviceModels'

const deviceModelToSelectOption = ({ id, name }) => ({
  value: id,
  label: name
})

const useDeviceModelsAsSelectOptions = () => {
  const deviceModels = useNormalizedDeviceModels()

  return useMemo(() => deviceModels.map(deviceModelToSelectOption), [deviceModels])
}

export default useDeviceModelsAsSelectOptions
