import { useMemo } from 'react'
import useNormalizedUserActionTypes from './useNormalizedUserActionTypes'

const userActionTypeToSelectOption = ({ id, name }) => ({
  value: id,
  label: name
})

const useUserActionTypesAsSelectOptions = () => {
  const userActionTypes = useNormalizedUserActionTypes()

  return useMemo(() => userActionTypes.map(userActionTypeToSelectOption), [userActionTypes])
}

export default useUserActionTypesAsSelectOptions
