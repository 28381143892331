class StoreDeviceAttributeValue {
  constructor (
    id = undefined,
    deviceId,
    attributeId,
    value
  ) {
    this.id = id
    this.deviceId = deviceId
    this.device = null
    this.attributeId = attributeId
    this.attribute = null
    this.value = value
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      deviceId: this.deviceId,
      attributeId: this.attributeId,
      value: `${this.value}`
    })
  }

  static FromNormalizedRESTModel ({
    id,
    deviceId,
    attributeId,
    value
  }) {
    return new StoreDeviceAttributeValue(
      id,
      deviceId,
      attributeId,
      value
    )
  }

  toJSON () {
    return {
      id: this.id,
      deviceId: this.deviceId,
      attributeId: this.attributeId,
      value: this.value
    }
  }

  static FromJSON ({
    id,
    deviceId,
    attributeId,
    value
  }) {
    return new StoreDeviceAttributeValue(
      id,
      deviceId,
      attributeId,
      value
    )
  }
}

export default StoreDeviceAttributeValue
