import DeviceStatusChangeCodeService from '../../../services/DeviceStatusChangeCode'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const DeviceStatusChangeCodeDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'deviceStatusChangeCode',
  DeviceStatusChangeCodeService,
  RESTModelNormalizationSchemas.deviceStatusChangeCode.schema,
  [RESTModelNormalizationSchemas.deviceStatusChangeCode.entityName]
)

export default DeviceStatusChangeCodeDataStoreSagaHandlers
