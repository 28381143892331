import DeviceModelService from '../../../services/DeviceModel'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const DeviceModelDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'deviceModel',
  DeviceModelService,
  RESTModelNormalizationSchemas.deviceModel.schema,
  [
    RESTModelNormalizationSchemas.deviceModel.entityName,
    RESTModelNormalizationSchemas.deviceModelInput.entityName,
    RESTModelNormalizationSchemas.deviceModelOutput.entityName
  ]
)

export default DeviceModelDataStoreSagaHandlers
