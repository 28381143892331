import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import ChatGroupDataStoreSagaHandlers from '../../sagas/data/ChatGroupDataStore'

const sliceName = 'chatGroupsStore'

const ChatGroupDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  ChatGroupDataStoreSagaHandlers
)

export default ChatGroupDataStoreSlice
