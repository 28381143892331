import { combineReducers } from 'redux'
import UserDataStoreSlice from '../../slices/data/UserDataStoreSlice'
import FamilyDataStoreSlice from '../../slices/data/FamilyDataStoreSlice'
import LanguageDataStoreSlice from '../../slices/data/LanguageDataStoreSlice'
import ImageDataStoreSlice from '../../slices/data/ImageDataStoreSlice'
import DeviceTypeDataStoreSlice from '../../slices/data/DeviceTypeDataStoreSlice'
import DeviceStatusChangeCodeDataStoreSlice from '../../slices/data/DeviceStatusChangeCodeDataStoreSlice'
import DeviceStatusDataStoreSlice from '../../slices/data/DeviceStatusDataStoreSlice'
import DeviceMarkerTypeDataStoreSlice from '../../slices/data/DeviceMarkerTypeDataStoreSlice'
import DeviceAttributeDataStoreSlice from '../../slices/data/DeviceAttributeDataStoreSlice'
import DeviceAttributeValueDataStoreSlice from '../../slices/data/DeviceAttributeValueDataStoreSlice'
import DeviceCoordinateDataStoreSlice from '../../slices/data/DeviceCoordinateDataStoreSlice'
import DeviceInputDataStoreSlice from '../../slices/data/DeviceInputDataStoreSlice'
import DeviceModelDataStoreSlice from '../../slices/data/DeviceModelDataStoreSlice'
import DeviceModelAttributeDataStoreSlice from '../../slices/data/DeviceModelAttributeDataStoreSlice'
import DeviceModelInputDataStoreSlice from '../../slices/data/DeviceModelInputDataStoreSlice'
import DeviceModelOutputDataStoreSlice from '../../slices/data/DeviceModelOutputDataStoreSlice'
import DeviceOutputDataStoreSlice from '../../slices/data/DeviceOutputDataStoreSlice'
import DeviceDataStoreSlice from '../../slices/data/DeviceDataStoreSlice'
import FormDataStoreSlice from '../../slices/data/FormDataStoreSlice'
import FormFillDataDataStoreSlice from '../../slices/data/FormFillDataDataStoreSlice'
import ZoneShapeTypeDataStoreSlice from '../../slices/data/ZoneShapeTypeDataStoreSlice'
import ZoneTypeDataStoreSlice from '../../slices/data/ZoneTypeDataStoreSlice'
import ZoneDataStoreSlice from '../../slices/data/ZoneDataStoreSlice'
import ZoneDeviceDataStoreSlice from '../../slices/data/ZoneDeviceDataStoreSlice'
import ChatGroupDataStoreSlice from '../../slices/data/ChatGroupDataStoreSlice'
import ServicesDataStoreSlice from '../../slices/data/ServiceDataStoreSlice'
import MissionDataStoreSlice from '../../slices/data/MissionDataStoreSlice'
import MissionObjectiveDataStoreSlice from '../../slices/data/MissionObjectiveDataStoreSlice'
import PredefinedEventDataStoreSlice from '../../slices/data/PredefinedEventDataStoreSlice'
import MessageDataStoreSlice from '../../slices/data/MessageDataStoreSlice'
import MessageCategoryDataStoreSlice from '../../slices/data/MessageCategoryDataStoreSlice'
import UserActionDataStoreSlice from '../../slices/data/UserActionDataStoreSlice'
import UserActionTypeDataStoreSlice from '../../slices/data/UserActionTypeDataStoreSlice'
import LoginUserActionDetailDataStoreSlice from '../../slices/data/LoginUserActionDetailDataStoreSlice'
import MapRegionDataStoreSlice from '../../slices/data/MapRegionDataStoreSlice'
import TrackingGroupDataStoreSlice from '../../slices/data/TrackingGroupDataStoreSlice'

/**
 * Stores global state(state used by multiple experiences).
 * It is the core of the store normalization process: other experiences that use this state, reference items from this
 * store, without duplicating the same data.
 * @type {Reducer<CombinedState<{}>>}
 */
const DataStore = combineReducers({
  users: UserDataStoreSlice.reducer,
  families: FamilyDataStoreSlice.reducer,
  languages: LanguageDataStoreSlice.reducer,
  images: ImageDataStoreSlice.reducer,
  deviceTypes: DeviceTypeDataStoreSlice.reducer,
  deviceStatusChangeCodes: DeviceStatusChangeCodeDataStoreSlice.reducer,
  deviceStatuses: DeviceStatusDataStoreSlice.reducer,
  deviceMarkerTypes: DeviceMarkerTypeDataStoreSlice.reducer,
  deviceAttributes: DeviceAttributeDataStoreSlice.reducer,
  deviceAttributeValues: DeviceAttributeValueDataStoreSlice.reducer,
  deviceCoordinates: DeviceCoordinateDataStoreSlice.reducer,
  deviceInputs: DeviceInputDataStoreSlice.reducer,
  deviceModels: DeviceModelDataStoreSlice.reducer,
  deviceModelAttributes: DeviceModelAttributeDataStoreSlice.reducer,
  deviceModelInputs: DeviceModelInputDataStoreSlice.reducer,
  deviceModelOutputs: DeviceModelOutputDataStoreSlice.reducer,
  deviceOutputs: DeviceOutputDataStoreSlice.reducer,
  devices: DeviceDataStoreSlice.reducer,
  forms: FormDataStoreSlice.reducer,
  formFillData: FormFillDataDataStoreSlice.reducer,
  zoneShapeTypes: ZoneShapeTypeDataStoreSlice.reducer,
  zoneTypes: ZoneTypeDataStoreSlice.reducer,
  zones: ZoneDataStoreSlice.reducer,
  zoneDevices: ZoneDeviceDataStoreSlice.reducer,
  chatGroups: ChatGroupDataStoreSlice.reducer,
  services: ServicesDataStoreSlice.reducer,
  missions: MissionDataStoreSlice.reducer,
  missionObjectives: MissionObjectiveDataStoreSlice.reducer,
  predefinedEvents: PredefinedEventDataStoreSlice.reducer,
  messages: MessageDataStoreSlice.reducer,
  messageCategories: MessageCategoryDataStoreSlice.reducer,
  userActions: UserActionDataStoreSlice.reducer,
  userActionTypes: UserActionTypeDataStoreSlice.reducer,
  loginUserActionDetails: LoginUserActionDetailDataStoreSlice.reducer,
  mapRegions: MapRegionDataStoreSlice.reducer,
  trackingGroups: TrackingGroupDataStoreSlice.reducer
})

export default DataStore
