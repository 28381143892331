const selectChatGroups = state => state.data.chatGroups

const selectChatGroupById = chatGroupId => state => state.data.chatGroups[chatGroupId]

const ChatGroupDataStoreSelectors = {
  chatGroups: selectChatGroups,
  chatGroupById: selectChatGroupById
}

export default ChatGroupDataStoreSelectors
