import ServiceManagementServiceListSlice from '../../slices/experiences/ServiceManagementServiceListSlice'
import SERVICE_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/ServiceManagement'
import { makeActionCreator } from '../../../modules/utilities/store'
import ServiceManagementServiceListExportSlice from '../../slices/experiences/ServiceManagementServiceListExportSlice'

const ServiceManagementExperienceActions = {
  list: ServiceManagementServiceListSlice.actions,
  exportList: ServiceManagementServiceListExportSlice.actions,
  /**
   * @param missionItem {StoreMission}: StoreService instance containing edited mission data.
   * @param onSuccess {function}: executed if editing was successful.
   * @returns {{ type: string, data: { missionItem: StoreMission, onSuccess: function } }}
   */
  editMission: makeActionCreator(SERVICE_MANAGEMENT_EXPERIENCE_TYPES.EDIT_MISSION, 'missionItem', 'onSuccess'),
  /**
   * @param serviceId {string}: Id of began service.
   * @returns {{ type: string, data: { serviceId: string } }}
   */
  addPriseDeService: makeActionCreator(SERVICE_MANAGEMENT_EXPERIENCE_TYPES.ADD_PRISE_DE_SERVICE, 'serviceId'),
  /**
   * @param serviceId {string}: Id of hidden service.
   * @returns {{ type: string, data: { serviceId: string } }}
   */
  removePriseDeService: makeActionCreator(SERVICE_MANAGEMENT_EXPERIENCE_TYPES.REMOVE_PRISE_DE_SERVICE, 'serviceId')
}

export default ServiceManagementExperienceActions
