import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import DeviceAttributeDataStoreSagaHandlers from '../../sagas/data/DeviceAttributeDataStore'

const sliceName = 'deviceAttributesStore'

const DeviceAttributeDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  DeviceAttributeDataStoreSagaHandlers
)

export default DeviceAttributeDataStoreSlice
