import ServiceService from '../../../services/Service'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const ServiceDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'service',
  ServiceService,
  RESTModelNormalizationSchemas.service.schema,
  [
    RESTModelNormalizationSchemas.service.entityName,
    RESTModelNormalizationSchemas.mission.entityName,
    RESTModelNormalizationSchemas.user.entityName,
    RESTModelNormalizationSchemas.formFillData.entityName
  ]
)

export default ServiceDataStoreSagaHandlers
