import { useMemo } from 'react'
import useNormalizedDeviceMarkerTypes from './useNormalizedDeviceMarkerTypes'

const deviceMarkerTypeToSelectOption = ({ id, name }) => ({
  value: id,
  label: name
})

const useDeviceMarkerTypesAsSelectOptions = () => {
  const deviceMarkerTypes = useNormalizedDeviceMarkerTypes()

  return useMemo(() => deviceMarkerTypes.map(deviceMarkerTypeToSelectOption), [deviceMarkerTypes])
}

export default useDeviceMarkerTypesAsSelectOptions
