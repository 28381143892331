import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import PredefinedEventDataStoreSagaHandlers from '../../sagas/data/PredefinedEventDataStore'

const sliceName = 'predefinedEventsStore'

const PredefinedEventDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  PredefinedEventDataStoreSagaHandlers
)

export default PredefinedEventDataStoreSlice
