import MissionService from '../../../services/Mission'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const MissionDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'mission',
  MissionService,
  RESTModelNormalizationSchemas.mission.schema,
  [
    RESTModelNormalizationSchemas.mission.entityName,
    RESTModelNormalizationSchemas.missionObjective.entityName,
    RESTModelNormalizationSchemas.formFillData.entityName
  ]
)

export default MissionDataStoreSagaHandlers
