class StoreProfile {
  constructor (
    id = undefined,
    firstName,
    lastName,
    email,
    identifier,
    phoneNumber,
    password,
    familyId,
    languageId,
    roleId,
    photoId,
    deviceIds,
    smsDangerMessages,
    smsNormalMessages,
    emailDangerMessages,
    emailNormalMessages,
    browserNotification,
    theme
  ) {
    this.id = id
    this.firstName = firstName
    this.lastName = lastName
    this.email = email
    this.identifier = identifier
    this.phoneNumber = phoneNumber
    this.password = password
    this.familyId = familyId
    this.family = null
    this.languageId = languageId
    this.language = null
    this.roleId = roleId
    this.role = null
    this.photoId = photoId
    this.photo = null
    this.deviceIds = deviceIds
    this.devices = []
    this.smsDangerMessages = smsDangerMessages
    this.smsNormalMessages = smsNormalMessages
    this.emailDangerMessages = emailDangerMessages
    this.emailNormalMessages = emailNormalMessages
    this.browserNotification = browserNotification
    this.theme = theme
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      userName: this.identifier,
      mobilePhone: this.phoneNumber,
      password: this.password,
      // familyId is read-only
      languageId: this.languageId,
      // roleId is read-only
      photoId: this.photoId,
      // deviceIds is read-only
      smsDangerMessages: this.smsDangerMessages,
      smsNormalMessages: this.smsNormalMessages,
      emailDangerMessages: this.emailDangerMessages,
      emailNormalMessages: this.emailNormalMessages,
      browserNotification: this.browserNotification,
      theme: this.theme
    })
  }

  static FromNormalizedRESTModel ({
    id,
    firstName,
    lastName,
    email,
    userName,
    mobilePhone,
    familyId,
    languageId,
    roleId,
    photoId,
    deviceIds,
    smsDangerMessages,
    smsNormalMessages,
    emailDangerMessages,
    emailNormalMessages,
    browserNotification,
    theme
  }) {
    return new StoreProfile(
      id,
      firstName,
      lastName,
      email,
      userName,
      mobilePhone,
      undefined,
      familyId,
      languageId,
      roleId,
      photoId,
      deviceIds,
      smsDangerMessages,
      smsNormalMessages,
      emailDangerMessages,
      emailNormalMessages,
      browserNotification,
      theme
    )
  }

  toJSON () {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      identifier: this.identifier,
      phoneNumber: this.phoneNumber,
      password: this.password,
      familyId: this.familyId,
      languageId: this.languageId,
      roleId: this.roleId,
      photoId: this.photoId,
      deviceIds: this.deviceIds,
      smsDangerMessages: this.smsDangerMessages,
      smsNormalMessages: this.smsNormalMessages,
      emailDangerMessages: this.emailDangerMessages,
      emailNormalMessages: this.emailNormalMessages,
      browserNotification: this.browserNotification,
      theme: this.theme
    }
  }

  static FromJSON ({
    id,
    firstName,
    lastName,
    email,
    identifier,
    phoneNumber,
    password,
    familyId,
    languageId,
    roleId,
    photoId,
    deviceIds,
    smsDangerMessages,
    smsNormalMessages,
    emailDangerMessages,
    emailNormalMessages,
    browserNotification,
    theme
  }) {
    return new StoreProfile(
      id,
      firstName,
      lastName,
      email,
      identifier,
      phoneNumber,
      password,
      familyId,
      languageId,
      roleId,
      photoId,
      deviceIds,
      smsDangerMessages,
      smsNormalMessages,
      emailDangerMessages,
      emailNormalMessages,
      browserNotification,
      theme
    )
  }
}

export default StoreProfile
