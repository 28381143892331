import { commonHandlers, createReducer } from '../../../../modules/utilities/store'
import DEVICE_MANAGEMENT_EXPERIENCE_TYPES from '../../../types/experiences/DeviceManagement'
import GLOBAL_TYPES from '../../../types/Global'

const initialState = {
  deviceIds: []
}

const handlers = {
  [DEVICE_MANAGEMENT_EXPERIENCE_TYPES.OPEN_DEVICE_CONTROL_PANEL]: (state, action) => {
    if (state.deviceIds.includes(action.data.deviceId)) {
      return state
    }
    return ({
      ...state,
      deviceIds: [
        ...state.deviceIds,
        action.data.deviceId
      ]
    })
  },
  [DEVICE_MANAGEMENT_EXPERIENCE_TYPES.CLOSE_DEVICE_CONTROL_PANEL]: (state, action) => ({
    ...state,
    deviceIds: state.deviceIds.filter(deviceId => deviceId !== action.data.deviceId)
  }),
  [GLOBAL_TYPES.RESET_STORE]: commonHandlers.resetState(initialState)
}

const DeviceControlPanelReducer = createReducer(initialState, handlers)

export default DeviceControlPanelReducer
