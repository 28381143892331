class StoreChatGroup {
  constructor (
    id = undefined,
    name,
    lastMessageContent,
    userIds,
    deviceIds
  ) {
    this.id = id
    this.name = name
    this.lastMessageContent = lastMessageContent
    this.userIds = userIds
    this.users = []
    this.deviceIds = deviceIds
    this.devices = []
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      name: this.name,
      // lastMessageContent is read-only
      userIds: this.userIds,
      deviceIds: this.deviceIds
    })
  }

  static FromNormalizedRESTModel ({
    id,
    name,
    lastMessageContent,
    userIds,
    deviceIds
  }) {
    return new StoreChatGroup(
      id,
      name,
      lastMessageContent,
      userIds,
      deviceIds
    )
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name,
      lastMessageContent: this.lastMessageContent,
      userIds: this.userIds,
      deviceIds: this.deviceIds
    }
  }

  static FromJSON ({
    id,
    name,
    lastMessageContent,
    userIds,
    deviceIds
  }) {
    return new StoreChatGroup(
      id,
      name,
      lastMessageContent,
      userIds,
      deviceIds
    )
  }
}

export default StoreChatGroup
