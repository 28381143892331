class StoreDeviceOutput {
  constructor (
    id = undefined,
    deviceId,
    deviceModelOutputId,
    description,
    updatedAt
  ) {
    this.id = id
    this.deviceId = deviceId
    this.device = null
    this.deviceModelOutputId = deviceModelOutputId
    this.deviceModelOutput = null
    this.description = description
    this.updatedAt = updatedAt
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      // deviceId is read-only
      deviceModelOutputId: this.deviceModelOutputId,
      description: this.description
      // updatedAt is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    deviceId,
    deviceModelOutputId,
    description,
    updatedAt
  }) {
    return new StoreDeviceOutput(
      id,
      deviceId,
      deviceModelOutputId,
      description,
      updatedAt
    )
  }

  toJSON () {
    return {
      id: this.id,
      deviceId: this.deviceId,
      deviceModelOutputId: this.deviceModelOutputId,
      description: this.description,
      updatedAt: this.updatedAt
    }
  }

  static FromJSON ({
    id,
    deviceId,
    deviceModelOutputId,
    description,
    updatedAt
  }) {
    return new StoreDeviceOutput(
      id,
      deviceId,
      deviceModelOutputId,
      description,
      updatedAt
    )
  }
}

export default StoreDeviceOutput
