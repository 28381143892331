import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import MissionDataStoreSagaHandlers from '../../sagas/data/MissionDataStore'

const sliceName = 'missionsStore'

const MissionDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  MissionDataStoreSagaHandlers
)

export default MissionDataStoreSlice
