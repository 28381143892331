class StoreDeviceModelInput {
  constructor (
    id = undefined,
    name,
    externalEventId,
    modelId
  ) {
    this.id = id
    this.name = name
    this.externalEventId = externalEventId
    this.modelId = modelId
    this.model = null
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      name: this.name,
      externalEventId: this.externalEventId
      // modelId is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    name,
    externalEventId,
    modelId
  }) {
    return new StoreDeviceModelInput(
      id,
      name,
      externalEventId,
      modelId
    )
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name,
      externalEventId: this.externalEventId,
      modelId: this.modelId
    }
  }

  static FromJSON ({
    id,
    name,
    externalEventId,
    modelId
  }) {
    return new StoreDeviceModelInput(
      id,
      name,
      externalEventId,
      modelId
    )
  }
}

export default StoreDeviceModelInput
