/**
 * Creates type constants for list data fetch management, that includes the unique store slice name.
 * @param sliceName {string}: slice name.
 * @returns {{FETCH: string, SET_IS_LOADING: string, SET_LIST_DATA: string, RESET_LIST_DATA: string}}
 */
const LIST_DATA_FETCH_MANAGEMENT_TYPES = sliceName => ({
  FETCH: `[${sliceName}] fetch`,
  SET_IS_LOADING: `[${sliceName}] setIsLoading`,
  SET_LIST_DATA: `[${sliceName}] setListData`,
  RESET_LIST_DATA: `[${sliceName}] resetListData`
})

export default LIST_DATA_FETCH_MANAGEMENT_TYPES
