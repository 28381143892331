import useDataStoreItems from './helpers/useDataStoreItems'
import DeviceModelOutputDataStoreSelectors from '../selectors/data/DeviceModelOutputs'
import DeviceModelOutputDataStoreSlice from '../slices/data/DeviceModelOutputDataStoreSlice'
import StoreDeviceModelOutput from '../models/DeviceModelOutput'

const JSONStoreDeviceModelOutputToStoreDeviceModelOutput = JSONStoreDeviceModelOutput => StoreDeviceModelOutput.FromJSON(JSONStoreDeviceModelOutput)

/**
 * Returns requested-/all deviceModelOutput(s) from state.data.deviceModelOutputs.
 * @param deviceModelOutputIds {string | string[] | null}: id of requested deviceModelOutputs. If null, all deviceModelOutputs are requested.
 * @param asMap {boolean}: when multiple deviceModelOutputs are requested, return deviceModelOutputs as an object mapping deviceModelOutputs by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all devices, when no device id is passed.
 * @returns {StoreDeviceModelOutput | StoreDeviceModelOutput[]}
 */
const useNormalizedDeviceModelOutputs = (
  deviceModelOutputIds = null,
  asMap = false,
  fetchAllEnabled = false
) => useDataStoreItems(
  deviceModelOutputIds,
  DeviceModelOutputDataStoreSelectors.deviceModelOutputs,
  DeviceModelOutputDataStoreSlice.actions.fetch,
  JSONStoreDeviceModelOutputToStoreDeviceModelOutput,
  asMap,
  fetchAllEnabled
)

export default useNormalizedDeviceModelOutputs
