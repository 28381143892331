import { put, takeLatest } from 'redux-saga/effects'
import FormService from '../../../services/Form'
import ListMetaData from '../../../modules/utilities/list/ListMetaData'
import FilterExpression from '../../../modules/utilities/list/FilterExpression'
import FormDataStoreSlice from '../../slices/data/FormDataStoreSlice'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import FormManagementExperienceActions from '../../actions/experiences/FormManagement'
import FORM_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/FormManagement'
import LayoutActions from '../../actions/Layout'
import FormManagementFormListSlice from '../../slices/experiences/FormManagementFormListSlice'
import MessageActions from '../../actions/Messages'
import { AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT, DISPLAYED_MESSAGE_TYPES } from '../../../constants/general'

function * fetchForms (action) {
  try {
    const { data: { limit, page, sort, filters, filtersLinkingType } } = action
    yield put(FormManagementExperienceActions.list.setIsLoading(true))

    const result = yield FormService.FindMany(
      new ListMetaData(
        limit,
        page,
        sort,
        new FilterExpression(
          filtersLinkingType,
          ...filters
        )
      )
    )
    yield put(
      FormDataStoreSlice.actions.fetchResult(
        result,
        function * (storeValues) {
          yield put(
            FormManagementExperienceActions.list.setData(storeValues.result, storeValues.totalNrOfItems, false)
          )
        }
      )
    )
  } catch (e) {
    yield put(FormManagementExperienceActions.list.setIsLoading(false))
    yield put(ErrorActions.trigger(
      new OLError('[FormManagementExperience->fetchForms]: Failed to fetch forms', 'Failed to fetch forms', e),
      true,
      true,
      true,
      false
    ))
  }
}

function * createForm (action) {
  const fetchId = 'FormManagementExperienceSaga.createForm'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { formItem, onSuccess } } = action

    const result = yield FormService.Create(formItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['CREATE_FORM_SUCCESS', 'Form successfully created'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[FormManagementExperience->createForm]: Failed to create form', 'Failed to create form', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * editForm (action) {
  const fetchId = 'FormManagementExperienceSaga.editForm'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { formItem, onSuccess } } = action

    const result = yield FormService.Update(formItem.id, formItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['EDIT_FORM_SUCCESS', 'Form successfully updated'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[FormManagementExperience->editForm]: Failed to update form', 'Failed to update form', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * updateEnabledAttribute (action) {
  const fetchId = 'FormManagementExperienceSaga.updateEnabledAttribute'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { formId, enabled } } = action

    const result = yield FormService.UpdateEnabledAttribute(formId, enabled)
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['FORM_ENABLED_ATTRIBUTE_UPDATE_SUCCESS', 'Form enabled state was successfully changed'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[FormManagementExperience->updateEnabledAttribute]: Failed to update form\'s "enabled" attribute value',
          'Failed to update enabled state',
          e
        ),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * deleteForm (action) {
  const fetchId = 'FormManagementExperienceSaga.deleteForm'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { formId } } = action

    const result = yield FormService.Delete(formId)
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['DELETE_FORM_SUCCESS', 'Form deleted'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[FormManagementExperience->deleteForm]: Failed to delete form', 'Failed to delete form', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * FormManagementExperienceSaga () {
  yield takeLatest(FormManagementFormListSlice.types.FETCH, fetchForms)
  yield takeLatest(FORM_MANAGEMENT_EXPERIENCE_TYPES.CREATE, createForm)
  yield takeLatest(FORM_MANAGEMENT_EXPERIENCE_TYPES.EDIT, editForm)
  yield takeLatest(FORM_MANAGEMENT_EXPERIENCE_TYPES.UPDATE_ENABLED_ATTRIBUTE, updateEnabledAttribute)
  yield takeLatest(FORM_MANAGEMENT_EXPERIENCE_TYPES.DELETE, deleteForm)
}

export default FormManagementExperienceSaga
