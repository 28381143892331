class StoreZoneType {
  constructor (
    id = undefined,
    name,
    color
  ) {
    this.id = id
    this.name = name
    this.color = color
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      name: this.name,
      color: this.color
    })
  }

  static FromNormalizedRESTModel ({
    id,
    name,
    color
  }) {
    return new StoreZoneType(
      id,
      name,
      color
    )
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name,
      color: this.color
    }
  }

  static FromJSON ({
    id,
    name,
    color
  }) {
    return new StoreZoneType(
      id,
      name,
      color
    )
  }
}

export default StoreZoneType
