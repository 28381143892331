import { createTheme } from '@mui/material/styles'
import { blue, grey } from '@mui/material/colors'

const darkTheme = createTheme({
  name: 'dark',
  customSizes: {
    drawerWidth: 240
  },
  palette: {
    customColors: {
      praetorianMain: '#1C294D'
    },
    mode: 'dark',
    deviceInput: {
      background: {
        inactive: blue[500],
        active: blue[400]
      },
      text: {
        inactive: grey[100],
        active: grey[100]
      }
    },
    deviceOutput: {
      background: {
        inactive: blue[500],
        active: blue[400]
      },
      text: {
        inactive: grey[100],
        active: grey[100]
      }
    }
  }
})

export default darkTheme
