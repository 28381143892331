class StoreDevice {
  constructor (
    id = undefined,
    name,
    phoneNumber,
    enabled,
    userId,
    typeId,
    statusId,
    coordinateIds,
    statusChangeCodeId,
    markerTypeId,
    isActive,
    attributeValueIds,
    modelId,
    inputIds,
    outputIds,
    phoneWssAppVersion,
    phoneAndroidSDKVersionNumber,
    phoneManufacturerName,
    phoneModel,
    phoneNetworkOperatorName,
    phoneDetectedPhoneNumber
  ) {
    this.id = id
    this.name = name
    this.phoneNumber = phoneNumber
    this.enabled = enabled
    this.userId = userId
    this.user = null
    this.typeId = typeId
    this.type = null
    this.statusId = statusId
    this.status = null
    this.coordinateIds = coordinateIds
    this.coordinates = []
    this.statusChangeCodeId = statusChangeCodeId
    this.statusChangeCode = null
    this.markerTypeId = markerTypeId
    this.markerType = null
    this.isActive = isActive
    this.attributeValueIds = attributeValueIds
    this.attributeValues = []
    this.modelId = modelId
    this.model = null
    this.inputIds = inputIds
    this.inputs = []
    this.outputIds = outputIds
    this.outputs = []
    this.phoneWssAppVersion = phoneWssAppVersion
    this.phoneAndroidSDKVersionNumber = phoneAndroidSDKVersionNumber
    this.phoneManufacturerName = phoneManufacturerName
    this.phoneModel = phoneModel
    this.phoneNetworkOperatorName = phoneNetworkOperatorName
    this.phoneDetectedPhoneNumber = phoneDetectedPhoneNumber
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      name: this.name,
      phoneNumber: this.phoneNumber,
      enabled: this.enabled,
      // userId is read-only
      typeId: this.typeId,
      statusId: this.statusId,
      // coordinateIds is read-only
      // statusChangeCodeId is read-only
      markerTypeId: this.markerTypeId,
      // isActive is read-only
      // attributeValueIds is read-only
      attributeValues: this.attributeValues.map(attributeValue => attributeValue.toNormalizedRESTModel()),
      // inputIds is read-only
      inputs: this.inputs.map(deviceInput => deviceInput.toNormalizedRESTModel()),
      // outputIds is read-only
      outputs: this.outputs.map(deviceOutput => deviceOutput.toNormalizedRESTModel()),
      modelId: this.modelId
      // phoneWssAppVersion is read-only
      // phoneAndroidSDKVersionNumber is read-only
      // phoneManufacturerName is read-only
      // phoneModel is read-only
      // phoneNetworkOperatorName is read-only
      // phoneDetectedPhoneNumber is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    name,
    phoneNumber,
    enabled,
    userId,
    typeId,
    statusId,
    coordinateIds,
    statusChangeCodeId,
    markerTypeId,
    isActive,
    attributeValueIds,
    modelId,
    inputIds,
    outputIds,
    phoneWssAppVersion,
    phoneAndroidSDKVersionNumber,
    phoneManufacturerName,
    phoneModel,
    phoneNetworkOperatorName,
    phoneDetectedPhoneNumber
  }) {
    return new StoreDevice(
      id,
      name,
      phoneNumber,
      enabled,
      userId,
      typeId,
      statusId,
      coordinateIds,
      statusChangeCodeId,
      markerTypeId,
      isActive,
      attributeValueIds,
      modelId,
      inputIds,
      outputIds,
      phoneWssAppVersion,
      phoneAndroidSDKVersionNumber,
      phoneManufacturerName,
      phoneModel,
      phoneNetworkOperatorName,
      phoneDetectedPhoneNumber
    )
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name,
      phoneNumber: this.phoneNumber,
      enabled: this.enabled,
      userId: this.userId,
      typeId: this.typeId,
      statusId: this.statusId,
      coordinateIds: this.coordinateIds,
      statusChangeCodeId: this.statusChangeCodeId,
      markerTypeId: this.markerTypeId,
      isActive: this.isActive,
      attributeValueIds: this.attributeValueIds,
      modelId: this.modelId,
      inputIds: this.inputIds,
      outputIds: this.outputIds,
      phoneWssAppVersion: this.phoneWssAppVersion,
      phoneAndroidSDKVersionNumber: this.phoneAndroidSDKVersionNumber,
      phoneManufacturerName: this.phoneManufacturerName,
      phoneModel: this.phoneModel,
      phoneNetworkOperatorName: this.phoneNetworkOperatorName,
      phoneDetectedPhoneNumber: this.phoneDetectedPhoneNumber
    }
  }

  static FromJSON ({
    id,
    name,
    phoneNumber,
    enabled,
    userId,
    typeId,
    statusId,
    coordinateIds,
    statusChangeCodeId,
    markerTypeId,
    isActive,
    attributeValueIds,
    modelId,
    inputIds,
    outputIds,
    phoneWssAppVersion,
    phoneAndroidSDKVersionNumber,
    phoneManufacturerName,
    phoneModel,
    phoneNetworkOperatorName,
    phoneDetectedPhoneNumber
  }) {
    return new StoreDevice(
      id,
      name,
      phoneNumber,
      enabled,
      userId,
      typeId,
      statusId,
      coordinateIds,
      statusChangeCodeId,
      markerTypeId,
      isActive,
      attributeValueIds,
      modelId,
      inputIds,
      outputIds,
      phoneWssAppVersion,
      phoneAndroidSDKVersionNumber,
      phoneManufacturerName,
      phoneModel,
      phoneNetworkOperatorName,
      phoneDetectedPhoneNumber
    )
  }
}

export default StoreDevice
