import { useMemo } from 'react'
import useNormalizedChatGroups from './useNormalizedChatGroups'
import useNormalizedUsers from './useNormalizedUsers'
import uniq from 'lodash/uniq'
import useNormalizedDevices from './useNormalizedDevices'

/**
 * Returns requested-/all chatGroup(s) from state.data.chatGroups in a non-normalized fashion.
 * @param chatGroupIds {string | string[] | null}: id of requested chatGroups.
 * @param asMap {boolean}: when multiple chatGroups are requested, return chatGroups as an object mapping chatGroups by their id.
 * @returns {StoreChatGroup | StoreChatGroup[]}
 */
const useChatGroups = (chatGroupIds = null, asMap = false) => {
  const normalizedChatGroups = useNormalizedChatGroups(chatGroupIds, asMap)
  const relatedIds = useMemo(
    () => {
      const reducedChatGroups = typeof chatGroupIds === 'string'
        ? asMap
          ? normalizedChatGroups && { [normalizedChatGroups.id]: normalizedChatGroups }
          : normalizedChatGroups && [normalizedChatGroups]
        : normalizedChatGroups

      const relatedIds = (
        asMap
          ? Object.values(reducedChatGroups || {})
          : reducedChatGroups || []
      ).reduce(
        (relatedIds, chatGroup) => {
          chatGroup.userIds?.length && relatedIds.userIds.concat(chatGroup.userIds)
          chatGroup.deviceIds?.length && relatedIds.deviceIds.concat(chatGroup.deviceIds)
          return relatedIds
        },
        { userIds: [], deviceIds: [] }
      )
      relatedIds.userIds = uniq(relatedIds.userIds)
      relatedIds.deviceIds = uniq(relatedIds.deviceIds)

      return relatedIds
    },
    [normalizedChatGroups, asMap, chatGroupIds]
  )
  const users = useNormalizedUsers(relatedIds?.userIds, true, false)
  const devices = useNormalizedDevices(relatedIds?.deviceIds, true, false)

  return useMemo(
    () => {
      const populateChatGroup = chatGroup => {
        chatGroup.users = chatGroup.userIds?.map(userId => users[userId])
        chatGroup.devices = chatGroup.deviceIds?.map(deviceId => devices[deviceId])

        return chatGroup
      }

      return normalizedChatGroups && (
        typeof chatGroupIds === 'string'
          ? populateChatGroup(normalizedChatGroups)
          : asMap
            ? Object.keys(normalizedChatGroups)
              .reduce((chatGroups, chatGroupId) => {
                chatGroups[chatGroupId] = populateChatGroup(normalizedChatGroups[chatGroupId])
                return chatGroups
              }, {})
            : normalizedChatGroups?.map(populateChatGroup)
      )
    },
    [normalizedChatGroups, users, devices, asMap, chatGroupIds]
  )
}

export default useChatGroups
