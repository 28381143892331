class StorePredefinedEvent {
  constructor (
    id = undefined,
    name,
    message,
    isDangerMessage,
    launchedFromAuthorizedZones,
    createdAt
  ) {
    this.id = id
    this.name = name
    this.message = message
    this.isDangerMessage = isDangerMessage
    this.shapeType = null
    this.launchedFromAuthorizedZones = launchedFromAuthorizedZones
    this.createdAt = new Date(createdAt)
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      name: this.name,
      message: this.message,
      isDangerMessage: this.isDangerMessage,
      launchedFromAuthorizedZones: this.launchedFromAuthorizedZones
      // createdAt is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    name,
    message,
    isDangerMessage,
    launchedFromAuthorizedZones,
    createdAt
  }) {
    return new StorePredefinedEvent(
      id,
      name,
      message,
      isDangerMessage,
      launchedFromAuthorizedZones,
      createdAt
    )
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name,
      message: this.message,
      isDangerMessage: this.isDangerMessage,
      launchedFromAuthorizedZones: this.launchedFromAuthorizedZones,
      createdAt: this.createdAt
    }
  }

  static FromJSON ({
    id,
    name,
    message,
    isDangerMessage,
    launchedFromAuthorizedZones,
    createdAt
  }) {
    return new StorePredefinedEvent(
      id,
      name,
      message,
      isDangerMessage,
      launchedFromAuthorizedZones,
      createdAt
    )
  }
}

export default StorePredefinedEvent
