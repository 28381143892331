import { put, takeLatest } from 'redux-saga/effects'
import TrackingGroupService from '../../../services/TrackingGroup'
import ListMetaData from '../../../modules/utilities/list/ListMetaData'
import FilterExpression from '../../../modules/utilities/list/FilterExpression'
import TrackingGroupDataStoreSlice from '../../slices/data/TrackingGroupDataStoreSlice'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import TrackingGroupManagementExperienceActions from '../../actions/experiences/TrackingGroupManagement'
import TRACKING_GROUP_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/TrackingGroupManagement'
import LayoutActions from '../../actions/Layout'
import TrackingGroupManagementTrackingGroupListSlice from '../../slices/experiences/TrackingGroupManagementTrackingGroupListSlice'
import MessageActions from '../../actions/Messages'
import { AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT, DISPLAYED_MESSAGE_TYPES } from '../../../constants/general'
import TrackingGroupManagementTrackingGroupListExportSlice from '../../slices/experiences/TrackingGroupManagementTrackingGroupListExportSlice'

function * fetchTrackingGroups (action) {
  const sliceActions = action.type === TrackingGroupManagementTrackingGroupListExportSlice.types.FETCH
    ? TrackingGroupManagementExperienceActions.exportList
    : TrackingGroupManagementExperienceActions.list
  try {
    const { data: { limit, page, sort, filters, filtersLinkingType } } = action
    yield put(sliceActions.setIsLoading(true))

    const result = yield TrackingGroupService.FindMany(
      new ListMetaData(
        limit,
        page,
        sort,
        new FilterExpression(
          filtersLinkingType,
          ...filters
        )
      )
    )
    yield put(
      TrackingGroupDataStoreSlice.actions.fetchResult(
        result,
        function * (storeValues) {
          yield put(
            sliceActions.setData(storeValues.result, storeValues.totalNrOfItems, false)
          )
        }
      )
    )
  } catch (e) {
    yield put(sliceActions.setIsLoading(false))
    yield put(ErrorActions.trigger(
      new OLError('[TrackingGroupManagementExperience->fetchTrackingGroups]: Failed to fetch tracking groups', 'Failed to fetch tracking groups', e),
      true,
      true,
      true,
      false
    ))
  }
}

function * createTrackingGroup (action) {
  const fetchId = 'TrackingGroupManagementExperienceSaga.createTrackingGroup'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { trackingGroupItem, onSuccess } } = action

    const result = yield TrackingGroupService.Create(trackingGroupItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['CREATE_TRACKING_GROUP_SUCCESS', 'Tracking group successfully created'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[TrackingGroupManagementExperience->createTrackingGroup]: Failed to create tracking group', 'Failed to create tracking group', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * deleteTrackingGroup (action) {
  const fetchId = 'TrackingGroupManagementExperienceSaga.deleteTrackingGroup'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { trackingGroupId } } = action

    const result = yield TrackingGroupService.Delete(trackingGroupId)
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['DELETE_TRACKING_GROUP_SUCCESS', 'TrackingGroup deleted'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[TrackingGroupManagementExperience->deleteTrackingGroup]: Failed to delete tracking group', 'Failed to delete tracking group', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * updateAttributeActive (action) {
  const fetchId = 'TrackingGroupManagementExperienceSaga.updateAttributeActive'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { trackingGroupId, active } } = action

    const result = yield TrackingGroupService.UpdateAttributeActive(trackingGroupId, active)
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['TRACKING_GROUP_ACTIVE_STATE_CHANGE_SUCCESS', 'Tracking group active state successfully changed'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[TrackingGroupManagementExperience->updateAttributeActive]: Failed to change tracking group active state',
          'Failed to change tracking group active state',
          e
        ),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * TrackingGroupManagementExperienceSaga () {
  yield takeLatest(TrackingGroupManagementTrackingGroupListSlice.types.FETCH, fetchTrackingGroups)
  yield takeLatest(TrackingGroupManagementTrackingGroupListExportSlice.types.FETCH, fetchTrackingGroups)
  yield takeLatest(TRACKING_GROUP_MANAGEMENT_EXPERIENCE_TYPES.CREATE, createTrackingGroup)
  yield takeLatest(TRACKING_GROUP_MANAGEMENT_EXPERIENCE_TYPES.DELETE, deleteTrackingGroup)
  yield takeLatest(TRACKING_GROUP_MANAGEMENT_EXPERIENCE_TYPES.UPDATE_ATTRIBUTE_ACTIVE, updateAttributeActive)
}

export default TrackingGroupManagementExperienceSaga
