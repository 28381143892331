import { useMemo } from 'react'
import useNormalizedMissions from './useNormalizedMissions'
import uniq from 'lodash/uniq'
import useFormFillData from './useFormFillData'
import useMissionObjectives from './useMissionObjectives'

/**
 * Returns requested mission(s) from state.data.missions in a normalized fashion.
 * @param missionIds {string | string[]}: id of requested missions.
 * @param asMap {boolean}: when multiple missions are requested, return missions as an object mapping missions by their id.
 * @returns {StoreMission | StoreMission[]}
 */
const useMissions = (missionIds = null, asMap = false) => {
  const normalizedMissions = useNormalizedMissions(missionIds, asMap)
  const relatedIds = useMemo(
    () => {
      const reducedMissions = typeof missionIds === 'string'
        ? asMap
          ? normalizedMissions && { [normalizedMissions.id]: normalizedMissions }
          : normalizedMissions && [normalizedMissions]
        : normalizedMissions

      const relatedIds = (
        asMap
          ? Object.values(reducedMissions || {})
          : reducedMissions || []
      ).reduce(
        (relatedIds, mission) => {
          mission.objectiveIds?.length && (relatedIds.objectiveIds = relatedIds.objectiveIds.concat(mission.objectiveIds))
          mission.dataId && relatedIds.dataIds.push(mission.dataId)
          return relatedIds
        },
        { objectiveIds: [], dataIds: [] }
      )
      relatedIds.objectiveIds = uniq(relatedIds.objectiveIds)
      relatedIds.dataIds = uniq(relatedIds.dataIds)

      return relatedIds
    },
    [normalizedMissions, asMap, missionIds]
  )
  const missionObjectives = useMissionObjectives(relatedIds?.objectiveIds, true)
  const data = useFormFillData(relatedIds?.dataIds, true)

  return useMemo(
    () => {
      const populateMission = mission => {
        mission.objectives = missionObjectives && Object.keys(missionObjectives).length
          ? mission.objectiveIds.map(objectiveId => missionObjectives[objectiveId])
          : null
        mission.data = data ? data[mission.dataId] : null
        return mission
      }

      return normalizedMissions && (
        typeof missionIds === 'string' || typeof missionIds === 'number'
          ? populateMission(normalizedMissions)
          : asMap
            ? Object.keys(normalizedMissions)
              .reduce((missions, missionId) => {
                missions[missionId] = populateMission(normalizedMissions[missionId])
                return missions
              }, {})
            : normalizedMissions.map(populateMission)
      )
    },
    [normalizedMissions, missionIds, missionObjectives, data, asMap]
  )
}

export default useMissions
