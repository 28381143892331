import { useMemo } from 'react'
import useNormalizedDeviceTypes from './useNormalizedDeviceTypes'

/**
 * Returns StoreDeviceType with the requested name.
 * @param deviceTypeName {StoreDeviceType.DEVICE_TYPE_NAMES}: requested device type's name.
 * @returns {StoreDeviceType}
 */
const useDeviceType = deviceTypeName => {
  const normalizedDeviceTypes = useNormalizedDeviceTypes(null, false)

  return useMemo(
    () => normalizedDeviceTypes.find(deviceType => deviceType.name === deviceTypeName),
    [normalizedDeviceTypes, deviceTypeName]
  )
}

export default useDeviceType
