import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import LoginUserActionDetailDataStoreSagaHandlers from '../../sagas/data/LoginUserActionDetailDataStore'

const sliceName = 'loginUserActionDetailsStore'

const LoginUserActionDetailDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  LoginUserActionDetailDataStoreSagaHandlers
)

export default LoginUserActionDetailDataStoreSlice
