import { put, takeLatest } from 'redux-saga/effects'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import AUDIO_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/AudioManagement'
import LayoutActions from '../../actions/Layout'
import MessageActions from '../../actions/Messages'
import { AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT, DISPLAYED_MESSAGE_TYPES } from '../../../constants/general'
import AudioService from '../../../services/Audio'

function * createAudio (action) {
  const fetchId = 'AudioManagementExperienceSaga.createAudio'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { audioBlob, onSuccess } } = action
    const result = yield AudioService.Create(audioBlob)
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['UPLOAD_AUDIO_SUCCESS', 'Audio successfully uploaded'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess(result.data)
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[AudioManagementExperienceSaga->createAudio]: Failed to create audio', 'Failed to create audio', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * editAudio (action) {
  const fetchId = 'AudioManagementExperienceSaga.editAudio'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { audioPath, audioBlob, onSuccess } } = action

    const result = yield AudioService.Update(audioPath, audioBlob)
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['UPLOAD_AUDIO_SUCCESS', 'Audio successfully uploaded'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess(result.data)
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[AudioManagementExperienceSaga->editAudio]: Failed to update audio', 'Failed to update audio', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * AudioManagementExperienceSaga () {
  yield takeLatest(AUDIO_MANAGEMENT_EXPERIENCE_TYPES.CREATE, createAudio)
  yield takeLatest(AUDIO_MANAGEMENT_EXPERIENCE_TYPES.EDIT, editAudio)
}

export default AudioManagementExperienceSaga
