import { put, takeLatest } from 'redux-saga/effects'
import MessageService from '../../../services/Message'
import ListMetaData from '../../../modules/utilities/list/ListMetaData'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import CHAT_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/ChatManagement'
import LayoutActions from '../../actions/Layout'
import MessageDataStoreSlice from '../../slices/data/MessageDataStoreSlice'
import ChatManagementMessageListSlice from '../../slices/experiences/ChatManagementMessageListSlice'
import ChatManagementExperienceActions from '../../actions/experiences/ChatManagement'
import MessageActions from '../../actions/Messages'
import { AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT, DISPLAYED_MESSAGE_TYPES } from '../../../constants/general'
import MessageCategoryService from '../../../services/MessageCategory'
import ChatManagementChatGroupListSlice from '../../slices/experiences/ChatManagementChatGroupListSlice'
import ChatGroupService from '../../../services/ChatGroup'
import ChatGroupDataStoreSlice from '../../slices/data/ChatGroupDataStoreSlice'

function * fetchMessages (action) {
  try {
    yield put(ChatManagementExperienceActions.messageList.setIsLoading(true))

    const { data: { limit, page, sort, filters } } = action

    const result = yield MessageService.FindMany(
      new ListMetaData(
        limit,
        page,
        sort,
        filters
      )
    )

    yield put(
      MessageDataStoreSlice.actions.fetchResult(
        result,
        function * (storeValues) {
          yield put(
            ChatManagementExperienceActions.messageList.setData(storeValues.result, storeValues.totalNrOfItems, false)
          )
        }
      )
    )
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[ChatManagementExperience->fetchMessages]: Failed to fetch messages', 'Failed to fetch messages', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(ChatManagementExperienceActions.messageList.setIsLoading(false))
}

function * createMessage (action) {
  const fetchId = 'ChatManagementExperienceSaga.createMessage'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { messageItem, onSuccess } } = action

    const result = yield MessageService.Create(messageItem.toNormalizedRESTModel())
    result.status === 200 && onSuccess && onSuccess()
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[ChatManagementExperienceSaga->createMessage]: Failed to create message', 'Failed to create message', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * editMessageCategory (action) {
  const fetchId = 'ChatManagementExperienceSaga.editMessageCategory'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { messageCategoryItem, onSuccess } } = action

    const result = yield MessageCategoryService.Update(
      messageCategoryItem.id,
      messageCategoryItem.toNormalizedRESTModel()
    )

    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['EDIT_MESSAGE_CATEGORY_SUCCESS', 'Message category successfully updated'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[ChatManagementExperienceSaga->editMessageCategory]: Failed to update message category',
          'Failed to update message category',
          e
        ),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * fetchChatGroups (action) {
  try {
    yield put(ChatManagementExperienceActions.chatGroupList.setIsLoading(true))

    const { data: { limit, page, sort, filters } } = action

    const result = yield ChatGroupService.FindMany(
      new ListMetaData(
        limit,
        page,
        sort,
        filters
      )
    )

    yield put(
      ChatGroupDataStoreSlice.actions.fetchResult(
        result,
        function * (storeValues) {
          yield put(
            ChatManagementExperienceActions.chatGroupList.setData(storeValues.result, storeValues.totalNrOfItems, false)
          )
        }
      )
    )
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[ChatManagementExperience->fetchChatGroups]: Failed to fetch chat groups',
          'Failed to fetch chat groups',
          e
        ),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(ChatManagementExperienceActions.chatGroupList.setIsLoading(false))
}

function * createChatGroup (action) {
  const fetchId = 'ChatManagementExperienceSaga.createChatGroup'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { chatGroupItem, onSuccess } } = action

    const result = yield ChatGroupService.Create(chatGroupItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['CREATE_CHAT_GROUP_SUCCESS', 'Chat group successfully created'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[ChatManagementExperience->createChatGroup]: Failed to create chat group',
          'Failed to create chat group',
          e
        ),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * editChatGroup (action) {
  const fetchId = 'ChatManagementExperienceSaga.editChatGroup'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { chatGroupItem, onSuccess } } = action

    const result = yield ChatGroupService.Update(chatGroupItem.id, chatGroupItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['EDIT_CHAT_GROUP_SUCCESS', 'Chat group successfully updated'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[ChatManagementExperience->editChatGroup]: Failed to update chat group',
          'Failed to update chat group',
          e
        ),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * deleteChatGroup (action) {
  const fetchId = 'ChatManagementExperienceSaga.deleteChatGroup'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { chatGroupId } } = action

    const result = yield ChatGroupService.Delete(chatGroupId)
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['DELETE_CHAT_GROUP_SUCCESS', 'Chat group deleted'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[ChatGroupManagementExperience->deleteChatGroup]: Failed to delete chat group',
          'Failed to delete chat group',
          e
        ),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * ChatManagementExperienceSaga () {
  yield takeLatest(ChatManagementMessageListSlice.types.FETCH, fetchMessages)
  yield takeLatest(CHAT_MANAGEMENT_EXPERIENCE_TYPES.CREATE_MESSAGE, createMessage)
  yield takeLatest(CHAT_MANAGEMENT_EXPERIENCE_TYPES.EDIT_MESSAGE_CATEGORY, editMessageCategory)
  yield takeLatest(ChatManagementChatGroupListSlice.types.FETCH, fetchChatGroups)
  yield takeLatest(CHAT_MANAGEMENT_EXPERIENCE_TYPES.CREATE_CHAT_GROUP, createChatGroup)
  yield takeLatest(CHAT_MANAGEMENT_EXPERIENCE_TYPES.EDIT_CHAT_GROUP, editChatGroup)
  yield takeLatest(CHAT_MANAGEMENT_EXPERIENCE_TYPES.DELETE_CHAT_GROUP, deleteChatGroup)
}

export default ChatManagementExperienceSaga
