class StoreDeviceModelAttribute {
  constructor (
    id = undefined,
    modelId,
    attributeId
  ) {
    this.id = id
    this.modelId = modelId
    this.model = null
    this.attributeId = attributeId
    this.attribute = null
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      modelId: this.modelId,
      attributeId: this.attributeId
    })
  }

  static FromNormalizedRESTModel ({
    id,
    modelId,
    attributeId
  }) {
    return new StoreDeviceModelAttribute(
      id,
      modelId,
      attributeId
    )
  }

  toJSON () {
    return {
      id: this.id,
      modelId: this.modelId,
      attributeId: this.attributeId
    }
  }

  static FromJSON ({
    id,
    modelId,
    attributeId
  }) {
    return new StoreDeviceModelAttribute(
      id,
      modelId,
      attributeId
    )
  }
}

export default StoreDeviceModelAttribute
