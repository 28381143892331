import DeviceTypeService from '../../../services/DeviceType'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const DeviceTypeDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'deviceType',
  DeviceTypeService,
  RESTModelNormalizationSchemas.deviceType.schema,
  [RESTModelNormalizationSchemas.deviceType.entityName]
)

export default DeviceTypeDataStoreSagaHandlers
