import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Create a new map region.
 * @param mapRegionData {object}: Map region data.
 * @returns {Promise<AxiosResponse<object>>}: newly created map region.
 * @constructor
 */
const Create = async mapRegionData => mainHTTPClient.post(
  MAIN_HTTP_API_ENDPOINTS.MAP_REGIONS,
  mapRegionData
)

/**
 * Get data of specified map region.
 * @param mapRegionId {string}
 * @returns {Promise<AxiosResponse<object>>}: map region data.
 * @constructor
 */
const GetMapRegionInfo = async mapRegionId => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.MAP_REGION.replace(':id', mapRegionId)
)

/**
 * Update the specified map region's data.
 * @param mapRegionId {string}
 * @param mapRegionData {object}
 * @returns {Promise<AxiosResponse<any>>}: updated map region data.
 * @constructor
 */
const Update = async (mapRegionId, mapRegionData) => mainHTTPClient.put(
  MAIN_HTTP_API_ENDPOINTS.MAP_REGION.replace(':id', mapRegionId),
  mapRegionData
)

/**
 * Delete specified map region.
 * @param mapRegionId {string}
 * @returns {Promise<AxiosResponse<string>>}: OK in case the deletion was successful.
 * @constructor
 */
const Delete = async mapRegionId => mainHTTPClient.delete(
  MAIN_HTTP_API_ENDPOINTS.MAP_REGION.replace(':id', mapRegionId)
)

/**
 * Update specified map region's `active` attribute.
 * @param mapRegionId {string}
 * @param active {boolean}
 * @returns {Promise<AxiosResponse<any>>}: updated map region data.
 * @constructor
 */
const UpdateAttributeActive = async (mapRegionId, active) => mainHTTPClient.put(
  MAIN_HTTP_API_ENDPOINTS.MAP_REGION_ACTIVE_ATTRIBUTE.replace(':id', mapRegionId),
  {
    active
  }
)

/**
 *
 * Search for map regions.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = async listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.MAP_REGIONS,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

const MapRegionService = {
  Create,
  GetMapRegionInfo,
  Update,
  Delete,
  UpdateAttributeActive,
  FindMany
}

export default MapRegionService
