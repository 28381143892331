import TrackingGroupManagementTrackingGroupListSlice from '../../slices/experiences/TrackingGroupManagementTrackingGroupListSlice'
import TRACKING_GROUP_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/TrackingGroupManagement'
import { makeActionCreator } from '../../../modules/utilities/store'
import TrackingGroupManagementTrackingGroupListExportSlice from '../../slices/experiences/TrackingGroupManagementTrackingGroupListExportSlice'

const TrackingGroupManagementExperienceActions = {
  list: TrackingGroupManagementTrackingGroupListSlice.actions,
  exportList: TrackingGroupManagementTrackingGroupListExportSlice.actions,
  /**
   * @param trackingGroupItem {StoreTrackingGroup}: StoreTrackingGroup instance containing the created tracking
   *                                                group data.
   * @param onSuccess {function}: executed if creation was successful.
   * @returns {{ type: string, data: { trackingGroupItem: StoreTrackingGroup, onSuccess: function } }}
   */
  create: makeActionCreator(TRACKING_GROUP_MANAGEMENT_EXPERIENCE_TYPES.CREATE, 'trackingGroupItem', 'onSuccess'),
  /**
   * @param trackingGroupId {string}: id of tracking group to delete.
   * @returns {{ type: string, data: { trackingGroupId: string } }}
   */
  delete: makeActionCreator(TRACKING_GROUP_MANAGEMENT_EXPERIENCE_TYPES.DELETE, 'trackingGroupId'),
  /**
   * @param trackingGroupId {string}: id of tracking group to update.
   * @param active {boolean}: active value the tracking group is updated with.
   * @returns {{ type: string, data: { trackingGroupId: string, active: boolean } }}
   */
  updateAttributeActive: makeActionCreator(TRACKING_GROUP_MANAGEMENT_EXPERIENCE_TYPES.UPDATE_ATTRIBUTE_ACTIVE, 'trackingGroupId', 'active')
}

export default TrackingGroupManagementExperienceActions
