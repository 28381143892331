import TrackingGroupService from '../../../services/TrackingGroup'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const TrackingGroupDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'trackingGroup',
  TrackingGroupService,
  RESTModelNormalizationSchemas.trackingGroup.schema,
  [
    RESTModelNormalizationSchemas.trackingGroup.entityName,
    RESTModelNormalizationSchemas.device.entityName
  ]
)

export default TrackingGroupDataStoreSagaHandlers
