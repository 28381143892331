import ChatGroupService from '../../../services/ChatGroup'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const ChatGroupDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'ChatGroup',
  ChatGroupService,
  RESTModelNormalizationSchemas.chatGroup.schema,
  [
    RESTModelNormalizationSchemas.chatGroup.entityName,
    RESTModelNormalizationSchemas.user.entityName,
    RESTModelNormalizationSchemas.device.entityName
  ]
)

export default ChatGroupDataStoreSagaHandlers
