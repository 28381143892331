const ZONE_MANAGEMENT_EXPERIENCE_TYPES = {
  CREATE: '[zoneManagementExperience] createZone',
  SET_CREATED: '[zoneManagementExperience] setCreated',
  EDIT: '[zoneManagementExperience] editZone',
  DELETE: '[zoneManagementExperience] deleteZone',
  UPDATE_ATTRIBUTE_ACTIVE: '[zoneManagementExperience] updateAttributeActive',
  UPDATE_ZONE_DEVICE_ASSIGNATION_STATE: '[zoneManagementExperience] updateZoneDeviceAssignationState'
}

export default ZONE_MANAGEMENT_EXPERIENCE_TYPES
