import useDataStoreItemsFetchedOnce from './helpers/useDataStoreItemsFetchedOnce'
import MessageCategoryDataStoreSelectors from '../selectors/data/MessageCategories'
import MessageCategoryDataStoreSlice from '../slices/data/MessageCategoryDataStoreSlice'
import StoreMessageCategory from '../models/MessageCategory'

const JSONStoreMessageCategoryToStoreMessageCategory = JSONStoreMessageCategory => StoreMessageCategory.FromJSON(JSONStoreMessageCategory)

/**
 * Returns requested-/all message category(s) from state.data.messageCategories.
 * @param messageCategoryIds {string | string[] | null}: id of requested message categories. If null, all message categories are requested.
 * @param asMap {boolean}: when multiple message categories are requested, return zone  types as an object mapping message categories by
 * their id.
 * @returns {StoreMessageCategory | StoreMessageCategory[]}
 */
const useNormalizedMessageCategories = (messageCategoryIds = null, asMap = false) => useDataStoreItemsFetchedOnce(
  messageCategoryIds,
  MessageCategoryDataStoreSelectors.messageCategories,
  MessageCategoryDataStoreSlice.actions.fetch,
  JSONStoreMessageCategoryToStoreMessageCategory,
  asMap
)

export default useNormalizedMessageCategories
