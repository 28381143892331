import DeviceAttributeValueService from '../../../services/DeviceAttributeValue'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const DeviceAttributeValueDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'deviceAttributeValue',
  DeviceAttributeValueService,
  RESTModelNormalizationSchemas.deviceAttributeValue.schema,
  [
    RESTModelNormalizationSchemas.deviceAttributeValue.entityName,
    RESTModelNormalizationSchemas.deviceAttribute.entityName,
    RESTModelNormalizationSchemas.device.entityName
  ]
)

export default DeviceAttributeValueDataStoreSagaHandlers
