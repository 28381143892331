import DeviceStatusService from '../../../services/DeviceStatus'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const DeviceStatusDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'deviceStatus',
  DeviceStatusService,
  RESTModelNormalizationSchemas.deviceStatus.schema,
  [RESTModelNormalizationSchemas.deviceStatus.entityName]
)

export default DeviceStatusDataStoreSagaHandlers
