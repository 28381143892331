import PredefinedEventDataStoreSelectors from '../selectors/data/PredefinedEvents'
import PredefinedEventDataStoreSlice from '../slices/data/PredefinedEventDataStoreSlice'
import StorePredefinedEvent from '../models/PredefinedEvent'
import useDataStoreItems from './helpers/useDataStoreItems'

const JSONStorePredefinedEventToStorePredefinedEvent = JSONStorePredefinedEvent => StorePredefinedEvent.FromJSON(JSONStorePredefinedEvent)

/**
 * Returns requested-/all predefined event(s) from state.data.predefinedEvents.
 * @param predefinedEventIds {string | string[]}: id of requested predefined events.
 * @param asMap {boolean}: when multiple predefined events are requested, return predefined events as an object mapping
 * predefined events by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all devices, when no device id is passed.
 * @returns {StorePredefinedEvent | StorePredefinedEvent[]}
 */
const useNormalizedPredefinedEvents = (
  predefinedEventIds = null,
  asMap = false,
  fetchAllEnabled = false
) => useDataStoreItems(
  predefinedEventIds,
  PredefinedEventDataStoreSelectors.predefinedEvents,
  PredefinedEventDataStoreSlice.actions.fetch,
  JSONStorePredefinedEventToStorePredefinedEvent,
  asMap,
  fetchAllEnabled
)

export default useNormalizedPredefinedEvents
