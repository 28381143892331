import MapRegionService from '../../../services/MapRegion'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const MapRegionDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'mapRegion',
  MapRegionService,
  RESTModelNormalizationSchemas.mapRegion.schema,
  [RESTModelNormalizationSchemas.mapRegion.entityName]
)

export default MapRegionDataStoreSagaHandlers
