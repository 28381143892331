import FamilyService from '../../../services/Family'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const FamilyDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'family',
  FamilyService,
  RESTModelNormalizationSchemas.family.schema,
  [RESTModelNormalizationSchemas.family.entityName]
)

export default FamilyDataStoreSagaHandlers
