import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import DeviceStatusDataStoreSagaHandlers from '../../sagas/data/DeviceStatusDataStore'

const sliceName = 'deviceStatusesStore'

const DeviceStatusDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  DeviceStatusDataStoreSagaHandlers
)

export default DeviceStatusDataStoreSlice
