import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import DeviceCoordinateDataStoreSagaHandlers from '../../sagas/data/DeviceCoordinateDataStore'

const sliceName = 'deviceCoordinatesStore'

const DeviceCoordinateDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  DeviceCoordinateDataStoreSagaHandlers
)

export default DeviceCoordinateDataStoreSlice
