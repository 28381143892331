import { useMemo } from 'react'
import useNormalizedDeviceStatuses from './useNormalizedDeviceStatuses'

const deviceStatusToSelectOption = ({ id, name }) => ({
  value: id,
  label: name
})

const useDeviceStatusesAsSelectOptions = () => {
  const deviceStatuses = useNormalizedDeviceStatuses()

  return useMemo(() => deviceStatuses.map(deviceStatusToSelectOption), [deviceStatuses])
}

export default useDeviceStatusesAsSelectOptions
