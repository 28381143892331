import { combineReducers } from 'redux'
import ThemeStore from './Theme'
import MessagesStore from './Messages'
import LayoutStore from './Layout'

const UIStore = combineReducers({
  theme: ThemeStore,
  messages: MessagesStore,
  layout: LayoutStore
})

export default UIStore
