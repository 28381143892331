class StoreZoneDevice {
  constructor (
    id = undefined,
    zoneId,
    deviceId
  ) {
    this.id = id
    this.zoneId = zoneId
    this.zone = null
    this.deviceId = deviceId
    this.device = null
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      // zoneId is read-only
      // deviceId is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    zoneId,
    deviceId
  }) {
    return new StoreZoneDevice(
      id,
      zoneId,
      deviceId
    )
  }

  toJSON () {
    return {
      id: this.id,
      zoneId: this.zoneId,
      deviceId: this.deviceId
    }
  }

  static FromJSON ({
    id,
    zoneId,
    deviceId
  }) {
    return new StoreZoneDevice(
      id,
      zoneId,
      deviceId
    )
  }
}

export default StoreZoneDevice
