import ZoneShapeTypeService from '../../../services/ZoneShapeType'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const ZoneShapeTypeDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'zoneShapeType',
  ZoneShapeTypeService,
  RESTModelNormalizationSchemas.zoneShapeType.schema,
  [RESTModelNormalizationSchemas.zoneShapeType.entityName]
)

export default ZoneShapeTypeDataStoreSagaHandlers
