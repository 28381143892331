import DeviceModelOutputService from '../../../services/DeviceModelOutput'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const DeviceModelOutputDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'deviceModelOutput',
  DeviceModelOutputService,
  RESTModelNormalizationSchemas.deviceModelOutput.schema,
  [
    RESTModelNormalizationSchemas.deviceModelOutput.entityName,
    RESTModelNormalizationSchemas.deviceModel.entityName
  ]
)

export default DeviceModelOutputDataStoreSagaHandlers
