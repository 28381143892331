import { useMemo } from 'react'
import useNormalizedMapRegions from './useNormalizedMapRegions'

const mapRegionToSelectOption = ({ id, name }) => ({
  value: id,
  label: name
})

const useMapRegionsAsSelectOptions = () => {
  const mapRegions = useNormalizedMapRegions(null, false)

  return useMemo(() => mapRegions.map(mapRegionToSelectOption), [mapRegions])
}

export default useMapRegionsAsSelectOptions
