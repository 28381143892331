import { combineReducers } from 'redux'
import DeviceManagementDeviceListSlice from '../../../slices/experiences/DeviceManagementDeviceListSlice'
import DeviceManagementDeviceListExportSlice from '../../../slices/experiences/DeviceManagementDeviceListExportSlice'
import DeviceManagementDevicePositionsSlice from '../../../slices/experiences/DeviceManagementDevicePositionsSlice'
import DeviceManagementDevicePositionsExportSlice from '../../../slices/experiences/DeviceManagementDevicePositionsExportSlice'
import DeviceControlPanelReducer from './ControlPanel'

const DeviceManagementExperienceStore = combineReducers({
  list: DeviceManagementDeviceListSlice.reducer,
  listExport: DeviceManagementDeviceListExportSlice.reducer,
  devicePositions: DeviceManagementDevicePositionsSlice.reducer,
  devicePositionsExport: DeviceManagementDevicePositionsExportSlice.reducer,
  controlPanel: DeviceControlPanelReducer
})

export default DeviceManagementExperienceStore
