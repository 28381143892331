import FormService from '../../../services/Form'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const FormDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'form',
  FormService,
  RESTModelNormalizationSchemas.form.schema,
  [RESTModelNormalizationSchemas.form.entityName]
)

export default FormDataStoreSagaHandlers
