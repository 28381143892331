export const MAIN_HTTP_API_CONFIG = {
  URL: process.env.REACT_APP_MAIN_HTTP_API_URL
}

export const MAIN_HTTP_API_ENDPOINTS = {
  LOG_IN: '/auth/login',
  VALIDATE_AUTH_CODE: '/auth/validate-code',
  USER: '/user/:id',
  USERS: '/user',
  FAMILY: '/family/:id',
  FAMILIES: '/family',
  LANGUAGE: '/language/:id',
  LANGUAGES: '/language',
  IMAGE: '/photo/:id',
  IMAGES: '/photo',
  DEVICE_TYPE: '/device-type/:id',
  DEVICE_TYPES: '/device-type',
  DEVICE_STATUS_CHANGE_CODE: '/device-status-change-code/:id',
  DEVICE_STATUS_CHANGE_CODES: '/device-status-change-code',
  DEVICE_STATUS: '/device-status/:id',
  DEVICE_STATUSES: '/device-status',
  DEVICE_MARKER_TYPE: '/device-marker-type/:id',
  DEVICE_MARKER_TYPES: '/device-marker-type',
  DEVICE_ATTRIBUTE: '/device-attribute/:id',
  DEVICE_ATTRIBUTES: '/device-attribute',
  DEVICE_ATTRIBUTE_VALUE: '/device-attribute-value/:id',
  DEVICE_ATTRIBUTE_VALUES: '/device-attribute-value',
  DEVICE_COORDINATE: '/device-coordinate/:id',
  DEVICE_COORDINATE_LOCATION_STRING: '/device-coordinate/:id/location-string',
  DEVICE_COORDINATES: '/device-coordinate',
  DEVICE_INPUTS: '/device-input',
  DEVICE_OUTPUTS: '/device-output',
  DEVICE_MODEL: '/device-model/:id',
  DEVICE_MODELS: '/device-model',
  DEVICE_MODEL_ATTRIBUTE: '/device-model-attribute/:id',
  DEVICE_MODEL_ATTRIBUTES: '/device-model-attribute',
  DEVICE_MODEL_INPUTS: '/device-model-input',
  DEVICE_MODEL_OUTPUTS: '/device-model-output',
  DEVICE_LISTEN: '/device/:id/listen',
  DEVICE_ACTIVE_ATTRIBUTE: '/device/:id/is-active',
  DEVICE_USER: '/device/:id/user',
  DEVICE_COMMAND: '/device/:id/command',
  DEVICE: '/device/:id',
  DEVICES: '/device',
  PROFILE: '/profile',
  ZONE_SHAPE_TYPE: '/zone-shape-type/:id',
  ZONE_SHAPE_TYPES: '/zone-shape-type',
  ZONE_TYPE: '/zone-type/:id',
  ZONE_TYPES: '/zone-type',
  ZONE: '/zone/:id',
  ZONES: '/zone',
  ZONE_ACTIVE_ATTRIBUTE: '/zone/:id/active',
  ZONE_DEVICE: '/zone-device/:zoneId',
  FORM: '/form/:id',
  FORMS: '/form',
  FORM_FILL_DATA: '/form-fill-data',
  FORM_ENABLED_ATTRIBUTE: '/form/:id/enabled',
  MESSAGE: '/message/:id',
  MESSAGES: '/message',
  MESSAGE_CATEGORY: '/message-category/:id',
  MESSAGE_CATEGORIES: '/message-category',
  CHAT_GROUP: '/chat-group/:id',
  CHAT_GROUPS: '/chat-group',
  SERVICE: '/service/:id',
  SERVICES: '/service',
  MISSION: '/mission/:id',
  MISSIONS: '/mission',
  MISSION_OBJECTIVES: '/mission-objective',
  PREDEFINED_EVENT: '/predefined-event/:id',
  PREDEFINED_EVENTS: '/predefined-event',
  AUDIOS: '/audio',
  USER_ACTIONS: '/user-action',
  USER_ACTION_TYPES: '/user-action-type',
  LOGIN_USER_ACTION_DETAILS: '/login-user-action-detail',
  MAP_REGION: '/map-region/:id',
  MAP_REGIONS: '/map-region',
  MAP_REGION_ACTIVE_ATTRIBUTE: '/map-region/:id/active',
  TRACKING_GROUP: '/tracking-group/:id',
  TRACKING_GROUP_ACTIVE_ATTRIBUTE: '/tracking-group/:id/active',
  TRACKING_GROUPS: '/tracking-group'
}
