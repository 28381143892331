import DeviceCoordinateDataStoreSelectors from '../selectors/data/DeviceCoordinates'
import DeviceCoordinateDataStoreSlice from '../slices/data/DeviceCoordinateDataStoreSlice'
import StoreDeviceCoordinate from '../models/DeviceCoordinate'
import useDataStoreItems from './helpers/useDataStoreItems'

const JSONStoreDeviceCoordinateToStoreDeviceCoordinate = JSONStoreDeviceCoordinate => StoreDeviceCoordinate.FromJSON(JSONStoreDeviceCoordinate)

/**
 * Returns requested-/all deviceCoordinate(s) from state.data.deviceCoordinates.
 * @param deviceCoordinateIds {string | string[]}: id of requested deviceCoordinates.
 * @param asMap {boolean}: when multiple deviceCoordinates are requested, return deviceCoordinates as an object mapping deviceCoordinates by their id.
 * @returns {StoreDeviceCoordinate | StoreDeviceCoordinate[]}
 */
const useNormalizedDeviceCoordinates = (deviceCoordinateIds = null, asMap = false) => useDataStoreItems(
  deviceCoordinateIds,
  DeviceCoordinateDataStoreSelectors.deviceCoordinates,
  DeviceCoordinateDataStoreSlice.actions.fetch,
  JSONStoreDeviceCoordinateToStoreDeviceCoordinate,
  asMap
)

export default useNormalizedDeviceCoordinates
