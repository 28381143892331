import { put, takeLatest, select } from 'redux-saga/effects'
import DeviceService from '../../../services/Device'
import ListMetaData from '../../../modules/utilities/list/ListMetaData'
import FilterExpression from '../../../modules/utilities/list/FilterExpression'
import DeviceDataStoreSlice from '../../slices/data/DeviceDataStoreSlice'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import DeviceManagementExperienceActions from '../../actions/experiences/DeviceManagement'
import DEVICE_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/DeviceManagement'
import LayoutActions from '../../actions/Layout'
import DeviceManagementDeviceListSlice from '../../slices/experiences/DeviceManagementDeviceListSlice'
import MessageActions from '../../actions/Messages'
import { AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT, DISPLAYED_MESSAGE_TYPES } from '../../../constants/general'
import DeviceManagementDevicePositionsSlice from '../../slices/experiences/DeviceManagementDevicePositionsSlice'
import DeviceCoordinateService from '../../../services/DeviceCoordinate'
import DeviceCoordinateDataStoreSlice from '../../slices/data/DeviceCoordinateDataStoreSlice'
import DeviceManagementDeviceListExportSlice from '../../slices/experiences/DeviceManagementDeviceListExportSlice'
import DeviceManagementDevicePositionsExportSlice
  from '../../slices/experiences/DeviceManagementDevicePositionsExportSlice'
import FETCH_IDS from '../../fetchIds'
import DeviceDataStoreSelectors from '../../selectors/data/Devices'
import StoreDevice from '../../models/Device'
import DeviceModelDataStoreSelectors from '../../selectors/data/DeviceModels'
import StoreDeviceModel from '../../models/DeviceModel'
import StoreDeviceCoordinate from '../../models/DeviceCoordinate'
import DataStoresActions from '../../actions/data'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'

function * fetchDevices (action) {
  const sliceActions = action.type === DeviceManagementDeviceListExportSlice.types.FETCH
    ? DeviceManagementExperienceActions.listExport
    : DeviceManagementExperienceActions.list
  try {
    const { data: { limit, page, sort, filters, filtersLinkingType } } = action
    yield put(sliceActions.setIsLoading(true))

    const result = yield DeviceService.FindMany(
      new ListMetaData(
        limit,
        page,
        sort,
        new FilterExpression(
          filtersLinkingType,
          ...filters
        )
      )
    )
    yield put(
      DeviceDataStoreSlice.actions.fetchResult(
        result,
        function * (storeValues) {
          yield put(
            sliceActions.setData(storeValues.result, storeValues.totalNrOfItems, false)
          )
        }
      )
    )
  } catch (e) {
    yield put(sliceActions.setIsLoading(false))
    yield put(ErrorActions.trigger(
      new OLError('[DeviceManagementExperience->fetchDevices]: Failed to fetch devices', 'Failed to fetch devices', e),
      true,
      true,
      true,
      false
    ))
  }
}

function * createDevice (action) {
  const fetchId = 'DeviceManagementExperienceSaga.createDevice'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { deviceItem, onSuccess } } = action

    const result = yield DeviceService.Create(deviceItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['CREATE_DEVICE_SUCCESS', 'Device successfully created'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[DeviceManagementExperience->createDevice]: Failed to create device', 'Failed to create device', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * editDevice (action) {
  const fetchId = 'DeviceManagementExperienceSaga.editDevice'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { deviceItem, onSuccess } } = action

    const result = yield DeviceService.Update(deviceItem.id, deviceItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['EDIT_DEVICE_SUCCESS', 'Device successfully updated'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[DeviceManagementExperience->editDevice]: Failed to update device', 'Failed to update device', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * deleteDevice (action) {
  const fetchId = 'DeviceManagementExperienceSaga.deleteDevice'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { deviceId } } = action

    const result = yield DeviceService.Delete(deviceId)
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['DELETE_DEVICE_SUCCESS', 'Device deleted'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[DeviceManagementExperience->deleteDevice]: Failed to delete device', 'Failed to delete device', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * sendOutputToDevice (action) {
  const fetchId = 'DeviceManagementExperienceSaga.sendOutputToDevice'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { deviceId, deviceOutputId } } = action

    const result = yield DeviceService.SendOutput(deviceId, deviceOutputId)
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['DEVICE_OUTPUT_SUCCESS', 'Output successfully sent'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[DeviceManagementExperience->sendOutputToDevice]: Failed to send output to device',
          'Failed to send output to the device',
          e
        ),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * listenDevice (action) {
  const fetchId = FETCH_IDS.DEVICE_MANAGEMENT_EXPERIENCE.LISTEN_DEVICE
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { deviceId, listeningPhoneNumber } } = action

    const result = yield DeviceService.Listen(deviceId, listeningPhoneNumber)
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['DEVICE_LISTEN_SUCCESS', 'Listening successfully started'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[DeviceManagementExperience->listenDevice]: Failed to initiate listening to the device',
          'Failed to listen to the device',
          e
        ),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * updateActiveAttribute (action) {
  const fetchId = 'DeviceManagementExperienceSaga.updateActiveAttribute'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { deviceId, isActive } } = action

    const result = yield DeviceService.UpdateIsActiveAttribute(deviceId, isActive)
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['DEVICE_ACTIVE_ATTRIBUTE_UPDATE_SUCCESS', 'Active state was successfully changed'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[DeviceManagementExperience->updateActiveAttribute]: Changing the active state has failed',
          'Failed to change the device\'s active state',
          e
        ),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * detachUser (action) {
  const fetchId = 'DeviceManagementExperienceSaga.detachUser'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { deviceId } } = action

    const result = yield DeviceService.DetachUser(deviceId)
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['DEVICE_DETACH_USER_SUCCESS', 'Device user successfully detached'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[DeviceManagementExperience->detachUser]: Failed to detach the device from the user',
          'Failed to detach the device from the user',
          e
        ),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * fetchDevicePositions (action) {
  const sliceActions = action.type === DeviceManagementDeviceListExportSlice.types.FETCH
    ? DeviceManagementExperienceActions.devicePositionsExport
    : DeviceManagementExperienceActions.devicePositions
  try {
    const { data: { limit, page, sort, filters, filtersLinkingType } } = action
    yield put(sliceActions.setIsLoading(true))

    const result = yield DeviceCoordinateService.FindMany(
      new ListMetaData(
        limit,
        page,
        sort,
        new FilterExpression(
          filtersLinkingType,
          ...filters
        )
      )
    )
    yield put(
      DeviceCoordinateDataStoreSlice.actions.fetchResult(
        result,
        function * (storeValues) {
          yield put(
            sliceActions.setData(storeValues.result, storeValues.totalNrOfItems, false)
          )
        }
      )
    )
  } catch (e) {
    yield put(sliceActions.setIsLoading(false))
    yield put(ErrorActions.trigger(
      new OLError('[DeviceManagementExperience->fetchDevicePositions]: Failed to fetch device positions', 'Failed to fetch device positions', e),
      true,
      true,
      true,
      false
    ))
  }
}

function * sendCommand (action) {
  const fetchId = 'DeviceManagementExperienceSaga.sendCommand'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { deviceId, command } } = action
    const devices = yield select(DeviceDataStoreSelectors.devices)
    const deviceModels = yield select(DeviceModelDataStoreSelectors.deviceModels)
    const device = StoreDevice.FromJSON(devices[deviceId])
    const deviceModel = StoreDeviceModel.FromJSON(deviceModels[device.modelId])

    const result = yield DeviceService.SendCommand(deviceId, `${deviceModel.smsCommandPrefix}${command}`)
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['DEVICE_SEND_COMMAND_SUCCESS', 'Command successfully sent'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[DeviceManagementExperience->sendCommand]: Failed to send command',
          'Failed to send command to device',
          e
        ),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * fetchLocationStringForCoordinate (action) {
  const fetchId = 'DeviceManagementExperienceSaga.fetchLocationStringForCoordinate'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { deviceCoordinateId } } = action

    const result = yield DeviceCoordinateService.GetLocationStringForCoordinate(deviceCoordinateId)
    if (result.status === 200) {
      const deviceCoordinate = StoreDeviceCoordinate.FromNormalizedRESTModel(result.data)
      // merge the fetched device coordinate data into the device coordinates store
      yield put(
        DataStoresActions.mergeEntitiesIntoStores({
          [RESTModelNormalizationSchemas.deviceCoordinate.entityName]: ({
            [deviceCoordinate.id]: deviceCoordinate
          })
        })
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[DeviceManagementExperience->fetchLocationStringForCoordinate]: Failed to fetch location string for device coordinate',
          'Failed to fetch location string for device coordinate',
          e
        ),
        true,
        true,
        true,
        true
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * DeviceManagementExperienceSaga () {
  yield takeLatest(DeviceManagementDeviceListSlice.types.FETCH, fetchDevices)
  yield takeLatest(DeviceManagementDeviceListExportSlice.types.FETCH, fetchDevices)
  yield takeLatest(DEVICE_MANAGEMENT_EXPERIENCE_TYPES.CREATE, createDevice)
  yield takeLatest(DEVICE_MANAGEMENT_EXPERIENCE_TYPES.EDIT, editDevice)
  yield takeLatest(DEVICE_MANAGEMENT_EXPERIENCE_TYPES.DELETE, deleteDevice)
  yield takeLatest(DEVICE_MANAGEMENT_EXPERIENCE_TYPES.SEND_OUTPUT_TO_DEVICE, sendOutputToDevice)
  yield takeLatest(DEVICE_MANAGEMENT_EXPERIENCE_TYPES.LISTEN, listenDevice)
  yield takeLatest(DEVICE_MANAGEMENT_EXPERIENCE_TYPES.UPDATE_ATTRIBUTE_ACTIVE, updateActiveAttribute)
  yield takeLatest(DEVICE_MANAGEMENT_EXPERIENCE_TYPES.DETACH_USER, detachUser)
  yield takeLatest(DEVICE_MANAGEMENT_EXPERIENCE_TYPES.SEND_COMMAND, sendCommand)
  yield takeLatest(DEVICE_MANAGEMENT_EXPERIENCE_TYPES.FETCH_LOCATION_STRING_FOR_COORDINATE, fetchLocationStringForCoordinate)
  yield takeLatest(DeviceManagementDevicePositionsSlice.types.FETCH, fetchDevicePositions)
  yield takeLatest(DeviceManagementDevicePositionsExportSlice.types.FETCH, fetchDevicePositions)
}

export default DeviceManagementExperienceSaga
