import DeviceAttributeService from '../../../services/DeviceAttribute'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const DeviceAttributeDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'deviceAttribute',
  DeviceAttributeService,
  RESTModelNormalizationSchemas.deviceAttribute.schema,
  [RESTModelNormalizationSchemas.deviceAttribute.entityName]
)

export default DeviceAttributeDataStoreSagaHandlers
