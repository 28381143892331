import useDataStoreItemsFetchedOnce from './helpers/useDataStoreItemsFetchedOnce'
import DeviceStatusDataStoreSelectors from '../selectors/data/DeviceStatuses'
import DeviceStatusDataStoreSlice from '../slices/data/DeviceStatusDataStoreSlice'
import StoreDeviceStatus from '../models/DeviceStatus'

const JSONStoreDeviceStatusToStoreDeviceStatus = JSONStoreDeviceStatus => StoreDeviceStatus.FromJSON(JSONStoreDeviceStatus)

/**
 * Returns requested-/all deviceStatus(s) from state.data.deviceStatuses.
 * @param deviceStatusIds {string | string[] | null}: id of requested deviceStatuses. If null, all deviceStatuses are requested.
 * @param asMap {boolean}: when multiple deviceStatuses are requested, return deviceStatuses as an object mapping deviceStatuses by their id.
 * @returns {StoreDeviceStatus | StoreDeviceStatus[]}
 */
const useNormalizedDeviceStatuses = (deviceStatusIds = null, asMap = false) => useDataStoreItemsFetchedOnce(
  deviceStatusIds,
  DeviceStatusDataStoreSelectors.deviceStatuses,
  DeviceStatusDataStoreSlice.actions.fetch,
  JSONStoreDeviceStatusToStoreDeviceStatus,
  asMap
)

export default useNormalizedDeviceStatuses
