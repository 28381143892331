import useDataStoreItems from './helpers/useDataStoreItems'
import MissionObjectiveDataStoreSelectors from '../selectors/data/MissionObjectives'
import MissionObjectiveDataStoreSlice from '../slices/data/MissionObjectiveDataStoreSlice'
import StoreMissionObjective from '../models/MissionObjective'

const JSONStoreMissionObjectiveToStoreMissionObjective = JSONStoreMissionObjective => StoreMissionObjective.FromJSON(JSONStoreMissionObjective)

/**
 * Returns requested-/all missionObjective(s) from state.data.missionObjectives.
 * @param missionObjectiveIds {string | string[]}: id of requested missionObjectives.
 * @param asMap {boolean}: when multiple missionObjectives are requested, return missionObjectives as an object mapping missionObjectives by their id.
 * @returns {StoreMissionObjective | StoreMissionObjective[]}
 */
const useNormalizedMissionObjectives = (missionObjectiveIds = null, asMap = false) => useDataStoreItems(
  missionObjectiveIds,
  MissionObjectiveDataStoreSelectors.missionObjectives,
  MissionObjectiveDataStoreSlice.actions.fetch,
  JSONStoreMissionObjectiveToStoreMissionObjective,
  asMap
)

export default useNormalizedMissionObjectives
