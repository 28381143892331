import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Create a new user.
 * @param deviceData {object}
 * @returns {Promise<AxiosResponse<object>>}: newly created device.
 * @constructor
 */
const Create = async deviceData => mainHTTPClient.post(
  MAIN_HTTP_API_ENDPOINTS.DEVICES,
  deviceData
)

/**
 * Get data of specified device.
 * @param deviceId {string}
 * @returns {Promise<AxiosResponse<object>>}: device data.
 * @constructor
 */
const GetDeviceInfo = async deviceId => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.DEVICE.replace(':id', deviceId)
)

/**
 * Update the specified device's data.
 * @param deviceId {string}
 * @param deviceData {object}
 * @returns {Promise<AxiosResponse<any>>}: updated device data.
 * @constructor
 */
const Update = async (deviceId, deviceData) => mainHTTPClient.put(
  MAIN_HTTP_API_ENDPOINTS.DEVICE.replace(':id', deviceId),
  deviceData
)

/**
 * Delete specified device.
 * @param deviceId {string}
 * @returns {Promise<AxiosResponse<string>>}: OK in case the deletion was successful.
 * @constructor
 */
const Delete = async deviceId => mainHTTPClient.delete(
  MAIN_HTTP_API_ENDPOINTS.DEVICE.replace(':id', deviceId)
)

/**
 * Send output to the specified device.
 * @param deviceId {string}
 * @param outputId {string}
 * @returns {Promise<AxiosResponse<string>>}: OK in case the output was successful.
 * @constructor
 */
const SendOutput = async (deviceId, outputId) => mainHTTPClient.post(
  MAIN_HTTP_API_ENDPOINTS.DEVICE_OUTPUTS,
  {
    id: outputId,
    deviceId
  }
)

/**
 * Listen to the microphone of the specified device.
 * @param deviceId {string}
 * @param listeningPhoneNumber {string}
 * @returns {Promise<AxiosResponse<string>>}: OK in case the call was successfully made.
 * @constructor
 */
const Listen = async (deviceId, listeningPhoneNumber) => mainHTTPClient.post(
  MAIN_HTTP_API_ENDPOINTS.DEVICE_LISTEN.replace(':id', deviceId),
  {
    listeningPhoneNumber
  }
)

/**
 * Update the isActive attribute of the device.
 * @param deviceId {string}
 * @param isActive {boolean}
 * @returns {Promise<AxiosResponse<string>>}: OK in case the attribute was successfully updated.
 * @constructor
 */
const UpdateIsActiveAttribute = async (deviceId, isActive) => mainHTTPClient.post(
  MAIN_HTTP_API_ENDPOINTS.DEVICE_ACTIVE_ATTRIBUTE.replace(':id', deviceId),
  {
    isActive
  }
)

/**
 * Delete the association between a device and its user.
 * @param deviceId {string}
 * @returns {Promise<AxiosResponse<string>>}: OK in case the association was successfully deleted.
 * @constructor
 */
const DetachUser = async deviceId => mainHTTPClient.delete(
  MAIN_HTTP_API_ENDPOINTS.DEVICE_USER.replace(':id', deviceId)
)

/**
 * Sends command to device.
 * @param deviceId {string}
 * @param command {string}
 * @returns {Promise<AxiosResponse<string>>}: OK in case the command was successfully sent.
 * @constructor
 */
const SendCommand = async (deviceId, command) => mainHTTPClient.post(
  MAIN_HTTP_API_ENDPOINTS.DEVICE_COMMAND.replace(':id', deviceId),
  {
    command
  }
)

/**
 * Search for devices.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.DEVICES,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

const DeviceService = {
  Create,
  GetDeviceInfo,
  Update,
  Delete,
  SendOutput,
  Listen,
  UpdateIsActiveAttribute,
  DetachUser,
  SendCommand,
  FindMany
}

export default DeviceService
