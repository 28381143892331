import { combineReducers } from 'redux'
import TrackingGroupManagementTrackingGroupListSlice from '../../slices/experiences/TrackingGroupManagementTrackingGroupListSlice'
import TrackingGroupManagementTrackingGroupListExportSlice from '../../slices/experiences/TrackingGroupManagementTrackingGroupListExportSlice'

const TrackingGroupManagementExperienceStore = combineReducers({
  list: TrackingGroupManagementTrackingGroupListSlice.reducer,
  exportList: TrackingGroupManagementTrackingGroupListExportSlice.reducer
})

export default TrackingGroupManagementExperienceStore
