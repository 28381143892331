import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Create a new predefined event.
 * @param predefinedEventData {object}: Predefined event data.
 * @returns {Promise<AxiosResponse<object>>}: newly created predefined event.
 * @constructor
 */
const Create = async predefinedEventData => mainHTTPClient.post(
  MAIN_HTTP_API_ENDPOINTS.PREDEFINED_EVENTS,
  predefinedEventData
)

/**
 * Get data of specified predefined event.
 * @param predefinedEventId {string}
 * @returns {Promise<AxiosResponse<object>>}: predefined event data.
 * @constructor
 */
const GetPredefinedEventInfo = async predefinedEventId => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.PREDEFINED_EVENT.replace(':id', predefinedEventId)
)

/**
 * Update the specified predefined event's data.
 * @param predefinedEventId {string}
 * @param predefinedEventData {object}
 * @returns {Promise<AxiosResponse<any>>}: updated predefined event data.
 * @constructor
 */
const Update = async (predefinedEventId, predefinedEventData) => mainHTTPClient.put(
  MAIN_HTTP_API_ENDPOINTS.PREDEFINED_EVENT.replace(':id', predefinedEventId),
  predefinedEventData
)

/**
 * Delete specified predefined event.
 * @param predefinedEventId {string}
 * @returns {Promise<AxiosResponse<string>>}: OK in case the deletion was successful.
 * @constructor
 */
const Delete = async predefinedEventId => mainHTTPClient.delete(
  MAIN_HTTP_API_ENDPOINTS.PREDEFINED_EVENT.replace(':id', predefinedEventId)
)

/**
 *
 * Search for predefined events.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = async listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.PREDEFINED_EVENTS,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

const PredefinedEventService = {
  Create,
  GetPredefinedEventInfo,
  Update,
  Delete,
  FindMany
}

export default PredefinedEventService
