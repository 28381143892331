import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import ZoneShapeTypeDataStoreSagaHandlers from '../../sagas/data/ZoneShapeTypeDataStore'

const sliceName = 'zoneShapeTypesStore'

const ZoneShapeTypeDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  ZoneShapeTypeDataStoreSagaHandlers
)

export default ZoneShapeTypeDataStoreSlice
