import { useMemo } from 'react'
import useNormalizedDeviceModelAttributes from './useNormalizedDeviceModelAttributes'
import useNormalizedDeviceModels from './useNormalizedDeviceModels'
import useNormalizedDeviceAttributes from './useNormalizedDeviceAttributes'

/**
 * Returns requested-/all deviceModelAttribute(s) from state.data.deviceModelAttributes in a non-normalized fashion.
 * @param deviceModelAttributeIds {string | string[] | null}: id of requested device-model-attributes. null means all
 * device-model-attributes are requested.
 * @param deviceModelIds {string | string[] | null}: request device-model-attributes having the `deviceModelIds` model
 * id(s). null means device-model-attributes are not filtered by `deviceModelAttribute.modelId`.
 * @param asMap {boolean}: when multiple device-model-attributes are requested, return device-model-attributes as an
 * object mapping device-model-attributes by their id.
 * @returns {StoreDeviceModelAttribute | StoreDeviceModelAttribute[]}
 */
const useDeviceModelAttributes = (deviceModelAttributeIds = null, deviceModelIds = null, asMap = false) => {
  const normalizedDeviceModelAttributes = useNormalizedDeviceModelAttributes(deviceModelAttributeIds, deviceModelIds, asMap)
  const deviceModels = useNormalizedDeviceModels(null, true)
  const deviceAttributes = useNormalizedDeviceAttributes(null, true)

  return useMemo(
    () => {
      const populateDeviceModelAttribute = deviceModelAttribute => {
        deviceModelAttribute.model = deviceModels[deviceModelAttribute.modelId]
        deviceModelAttribute.attribute = deviceAttributes[deviceModelAttribute.attributeId]
        return deviceModelAttribute
      }
      return asMap
        ? Object.keys(normalizedDeviceModelAttributes)
          .reduce((deviceModelAttributes, deviceModelAttributeId) => {
            deviceModelAttributes[deviceModelAttributeId] = populateDeviceModelAttribute(
              normalizedDeviceModelAttributes[deviceModelAttributeId]
            )
            return deviceModelAttributes
          }, {})
        : normalizedDeviceModelAttributes.map(populateDeviceModelAttribute)
    },
    [normalizedDeviceModelAttributes, deviceModels, deviceAttributes, asMap]
  )
}

export default useDeviceModelAttributes
