class StoreFormField {
  constructor (
    name,
    valueType,
    inputType,
    label,
    isRequired,
    position,
    options
  ) {
    if (!Object.values(StoreFormField.SUPPORTED_VALUE_TYPES).includes(valueType)) {
      throw new Error(`[StoreFormField] Invalid 'valueType' received: ${valueType}`)
    }

    if (!Object.values(StoreFormField.SUPPORTED_INPUT_TYPES).includes(inputType)) {
      throw new Error(`[StoreFormField] Invalid 'inputType' received: ${inputType}`)
    }

    this.name = name
    this.valueType = valueType
    this.inputType = inputType
    this.label = label
    this.isRequired = isRequired
    this.position = position
    this.options = options
  }

  toJSONSchema () {
    const uiOptions = {
      format: this.inputType !== StoreFormField.SUPPORTED_INPUT_TYPES.TEXT ? this.inputType : undefined
    }

    return ({
      name: this.name,
      propertySchema: {
        type: this.valueType,
        enum: this.options
      },
      uiSchema: {
        type: 'Control',
        label: this.label,
        scope: `#/properties/${this.name}`,
        options: uiOptions
      },
      required: this.isRequired ? [this.name] : [],
      position: this.position
    })
  }

  static FromJSONSchema (propertyName, position, propertySchema, uiSchema) {
    const property = propertySchema.properties[propertyName]
    const fieldUISchema = uiSchema.elements.find(({ scope }) => scope === `#/properties/${propertyName}`)

    return new StoreFormField(
      propertyName,
      property.type,
      fieldUISchema.options?.format || StoreFormField.SUPPORTED_INPUT_TYPES.TEXT,
      fieldUISchema.label,
      propertySchema.required.includes(propertyName),
      position,
      property.enum
    )
  }
}

StoreFormField.SUPPORTED_INPUT_TYPES = {
  TEXT: 'text',
  NUMBER: 'number',
  SELECT: 'select',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  SWITCH: 'switch'
}

StoreFormField.SUPPORTED_VALUE_TYPES = {
  STRING: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean'
}

export default StoreFormField
