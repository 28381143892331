import mainHTTPClient from '../clients/mainHTTPClient'
import { MAIN_HTTP_API_ENDPOINTS } from '../constants/api/mainHttp'

/**
 * Create a new message.
 * @param messageData {object}: Message data.
 * @returns {Promise<AxiosResponse<object>>}: newly created message.
 * @constructor
 */
const Create = async messageData => mainHTTPClient.post(
  MAIN_HTTP_API_ENDPOINTS.MESSAGES,
  messageData
)

/**
 *
 * Search for chats.
 * @param listMetaData {ListMetaData}
 * @returns {Promise<AxiosResponse<object[]>>}
 * @constructor
 */
const FindMany = async listMetaData => mainHTTPClient.get(
  MAIN_HTTP_API_ENDPOINTS.MESSAGES,
  {
    ...listMetaData.toAxiosRequestParams()
  }
)

const MessageService = {
  Create,
  FindMany
}

export default MessageService
