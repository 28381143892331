import { useMemo } from 'react'
import uniq from 'lodash/uniq'
import useNormalizedDeviceModels from './useNormalizedDeviceModels'
import useNormalizedDeviceModelInputs from './useNormalizedDeviceModelInputs'
import useNormalizedDeviceModelOutputs from './useNormalizedDeviceModelOutputs'
import reverse from 'lodash/reverse'

/**
 * Returns requested-/all device model(s) from state.data.deviceModels in a non-normalized fashion.
 * @param deviceModelIds {string | string[] | null}: id of requested devices.
 * @param asMap {boolean}: when multiple device models are requested, return device models as an object mapping device
 * models by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all device models, when no device model id is passed.
 * @returns {StoreDeviceModel | StoreDeviceModel[]}
 */
const useDeviceModels = (deviceModelIds = null, asMap = false, fetchAllEnabled = false) => {
  const normalizedDeviceModels = useNormalizedDeviceModels(deviceModelIds, asMap, fetchAllEnabled)
  const relatedIds = useMemo(
    () => {
      const reducedDeviceModels = typeof deviceModelIds === 'string'
        ? asMap
          ? normalizedDeviceModels && { [normalizedDeviceModels.id]: normalizedDeviceModels }
          : normalizedDeviceModels && [normalizedDeviceModels]
        : normalizedDeviceModels

      const relatedIds = (
        asMap
          ? Object.values(reducedDeviceModels || {})
          : reducedDeviceModels || []
      ).reduce(
        (relatedIds, deviceModel) => {
          deviceModel.inputIds && (
            relatedIds.deviceModelInputIds = relatedIds.deviceModelInputIds.concat(deviceModel.inputIds)
          )
          deviceModel.outputIds && (
            relatedIds.deviceModelOutputIds = relatedIds.deviceModelOutputIds.concat(deviceModel.outputIds)
          )
          return relatedIds
        },
        { deviceModelInputIds: [], deviceModelOutputIds: [] }
      )
      relatedIds.deviceModelInputIds = uniq(relatedIds.deviceModelInputIds)
      relatedIds.deviceModelOutputIds = uniq(relatedIds.deviceModelOutputIds)

      return relatedIds
    },
    [normalizedDeviceModels, asMap, deviceModelIds]
  )
  const deviceModelInputs = useNormalizedDeviceModelInputs(relatedIds?.deviceModelInputIds, true)
  const deviceModelOutputs = useNormalizedDeviceModelOutputs(relatedIds?.deviceModelOutputIds, true)

  return useMemo(
    () => {
      const populateDeviceModel = deviceModel => {
        /** TODO: arrays are just temporarily reversed because de-normalization reverses the order in the array.
         * Final solution would be an input/output.order attribute and ordering the items based on that */
        const inputIds = deviceModel.inputIds && reverse(deviceModel.inputIds)
        const outputIds = deviceModel.outputIds && reverse(deviceModel.outputIds)
        deviceModel.inputs = inputIds?.map(inputId => deviceModelInputs[inputId])
        deviceModel.outputs = outputIds?.map(outputId => deviceModelOutputs[outputId])

        return deviceModel
      }

      return normalizedDeviceModels && (
        typeof deviceModelIds === 'string'
          ? populateDeviceModel(normalizedDeviceModels)
          : asMap
            ? Object.keys(normalizedDeviceModels)
              .reduce((deviceModels, deviceModelId) => {
                deviceModels[deviceModelId] = populateDeviceModel(normalizedDeviceModels[deviceModelId])
                return deviceModels
              }, {})
            : normalizedDeviceModels?.map(populateDeviceModel)
      )
    },
    [normalizedDeviceModels, asMap, deviceModelIds, deviceModelInputs, deviceModelOutputs]
  )
}

export default useDeviceModels
