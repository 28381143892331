import { useMemo } from 'react'
import useNormalizedFamilies from './useNormalizedFamilies'

const familyToSelectOption = ({ id, name }) => ({
  value: id,
  label: name
})

const useFamiliesAsSelectOptions = () => {
  const families = useNormalizedFamilies()

  return useMemo(() => families.map(familyToSelectOption), [families])
}

export default useFamiliesAsSelectOptions
