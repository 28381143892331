import { useMemo } from 'react'
import useNormalizedPredefinedEvents from './useNormalizedPredefinedEvents'

/**
 * Returns requested-/all predefined event(s) from state.data.predefinedEvents in a non-normalized fashion.
 * @param predefinedEventIds {string | string[]}: id of requested predefined events.
 * @param asMap {boolean}: when multiple predefined events are requested, return predefined events as an object mapping
 * predefined events by their id.
 * @returns {StorePredefinedEvent | StorePredefinedEvent[]}
 */
const usePredefinedEvents = (predefinedEventIds = null, asMap = false) => {
  const normalizedPredefinedEvents = useNormalizedPredefinedEvents(predefinedEventIds, asMap)

  return useMemo(
    () => {
      const populatePredefinedEvent = predefinedEvent => {
        return predefinedEvent
      }
      return asMap
        ? Object.keys(normalizedPredefinedEvents)
          .reduce((predefinedEvents, predefinedEventId) => {
            predefinedEvents[predefinedEventId] = populatePredefinedEvent(normalizedPredefinedEvents[predefinedEventId])
            return predefinedEvents
          }, {})
        : normalizedPredefinedEvents.map(populatePredefinedEvent)
    },
    [normalizedPredefinedEvents, asMap]
  )
}

export default usePredefinedEvents
