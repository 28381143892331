import { put, takeLatest } from 'redux-saga/effects'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import LayoutActions from '../../actions/Layout'
import AUTH_EXPERIENCE_TYPES from '../../types/experiences/Auth'
import ProfileService from '../../../services/Profile'
import AuthExperienceActions from '../../actions/experiences/Auth'
import StoreProfile from '../../models/Profile'
import { normalize } from 'normalizr'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import DataStoresActions from '../../actions/data'
import StoreImage from '../../models/Image'
import MessageActions from '../../actions/Messages'
import {
  AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT,
  DISPLAYED_MESSAGE_TYPES,
  HTTP_RESPONSE_CODES
} from '../../../constants/general'
import AuthService from '../../../services/Auth'
import GlobalActions from '../../actions/Global'
import ThemeActions from '../../actions/Theme'
import {
  authorizeHttpClient,
  authorizeHttpClientWithAuth0,
  unAuthorizeHttpClient
} from '../../../clients/mainHTTPClient'

function * logIn (action) {
  const fetchId = 'AuthExperienceSaga.logIn'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { email, password } } = action
    const result = yield AuthService.LogIn(email, password)
    switch (result.status) {
      case HTTP_RESPONSE_CODES.SUCCESS: {
        // credentials are correct. the second step of the two-factor authentication process can begin
        const { authCodePrefix } = result.data
        // persist- and fetch the auth code prefix
        yield put(
          AuthExperienceActions.persistAuthCodePrefix(authCodePrefix)
        )
        yield put(
          AuthExperienceActions.fetchAuthCodePrefix()
        )
        break
      }
      case HTTP_RESPONSE_CODES.UNAUTHORIZED: {
        yield put(
          ErrorActions.trigger(
            new OLError('[AuthExperienceSaga->logIn]: Invalid credentials', 'Invalid username or password'),
            false,
            true,
            false,
            false
          )
        )
        break
      }
      default:
        throw new Error('Unsuccessful login')
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[AuthExperienceSaga->logIn]: Failed to log in user', 'Authentication failed', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * logOut () {
  const fetchId = 'AuthExperienceSaga.logOut'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))
    // firstly check if the user is authenticated with auth0
    const isAuthenticatedWithAuth0 = yield AuthService.IsAuthenticatedWithAuth0()
    if (isAuthenticatedWithAuth0) {
      // if the user is authenticated with auth0 it has to be logged out from auth0
      yield put(
        AuthExperienceActions.auth0Logout()
      )
    } else {
      // clear auth tokens
      yield put(AuthExperienceActions.removeAuthToken())
      // reset the entire redux store
      yield put(GlobalActions.resetStore())
      // un-authorize the http client because the token it is authorized with became invalid with the logout
      unAuthorizeHttpClient()
      // set authentication checked flag to true, because authentication is considered checked after logout
      yield put(
        AuthExperienceActions.setAuthenticationChecked(true)
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[AuthExperienceSaga->logOut]: Failed to log out user', 'Logout failed', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * auth0Login () {
  const fetchId = 'AuthExperienceSaga.auth0Login'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))
    // run auth0 authentication
    yield AuthService.Auth0Login()
    // check authentication state after auth0 authentication has been finished
    yield put(AuthExperienceActions.verifyAuthentication())
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[AuthExperienceSaga->auth0Login]: Failed to redirect user to the auth0 login page',
          'Failed to authenticate with WSS',
          e
        ),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * auth0Logout () {
  const fetchId = 'AuthExperienceSaga.auth0Logout'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))
    // run auth0 logout
    yield AuthService.Auth0Logout()
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[AuthExperienceSaga->auth0Logout]: Failed to log out user with auth0',
          'Failed to log out',
          e
        ),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * verifyAuthentication () {
  const fetchId = 'AuthExperienceSaga.verifyAuthentication'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    // firstly check if the user is authenticated with auth0
    const isAuthenticatedWithAuth0 = yield AuthService.IsAuthenticatedWithAuth0()
    if (isAuthenticatedWithAuth0) {
      // if the user is authenticated with auth0 the authentication will be validated
      yield put(
        AuthExperienceActions.validateAuth0Login()
      )
    } else {
      // if the user's not authenticated with auth0, check if it is authenticated through auth token(the old way)
      yield put(
        AuthExperienceActions.validateAuthToken()
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[AuthExperienceSaga->verifyAuthentication]: Failed to verify authentication',
          'Failed to verify an existing authentication',
          e
        ),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * validateAuth0Login () {
  const fetchId = 'AuthExperienceSaga.validateAuth0Login'

  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    // authorize http client to use auth0 auth mechanism, so it can be used for validating the token with ProfileService.GetProfileInfo
    // - clear any previous authorizations
    unAuthorizeHttpClient()
    // - authorize client
    authorizeHttpClientWithAuth0()
    // check login validity on the server by fetching the profile info of the logged-in user
    const result = yield ProfileService.GetProfileInfo()
    // if profile info is successfully fetched
    if (result.status === HTTP_RESPONSE_CODES.SUCCESS) {
      const normalizedData = normalize(result.data, RESTModelNormalizationSchemas.profile.schema)
      const profile = StoreProfile.FromNormalizedRESTModel(
        normalizedData.entities[RESTModelNormalizationSchemas.profile.entityName][normalizedData.result]
      )
      if (profile.photoId) {
        yield put(
          DataStoresActions.mergeEntitiesIntoStores({
            [RESTModelNormalizationSchemas.image.entityName]: StoreImage.FromNormalizedRESTModel(
              normalizedData.entities[RESTModelNormalizationSchemas.image.entityName][profile.photoId]
            ).toJSON()
          })
        )
      }
      if (profile.theme) { // change current theme to the theme selected by the user
        yield put(
          ThemeActions.selectTheme(profile.theme)
        )
      }
      // store profile info in the store
      yield put(
        AuthExperienceActions.setProfile(
          profile.toJSON()
        )
      )
      // token is valid so the user is authenticated
      yield put(
        AuthExperienceActions.setIsAuthenticated(true)
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError(
          '[AuthExperienceSaga->validateAuth0Login]: Failed to validate auth0 login',
          'Authentication failed',
          e
        ),
        true,
        false,
        false,
        false
      )
    )
  }
  // set authentication checked flag, because authentication check has been finished
  yield put(
    AuthExperienceActions.setAuthenticationChecked(true)
  )
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * validateAuthCode (action) {
  const fetchId = 'AuthExperienceSaga.validateAuthCode'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { authCode, rememberLogin, onLoginSuccess } } = action
    const result = yield AuthService.ValidateAuthCode(authCode)
    switch (result.status) {
      case HTTP_RESPONSE_CODES.SUCCESS: {
        // authentication was successful
        const { authToken } = result.data
        // remove the auth code prefix
        yield put(
          AuthExperienceActions.clearAuthCodePrefix()
        )
        // persist auth token
        yield put(
          AuthExperienceActions.persistAuthToken(authToken, rememberLogin)
        )
        // validate token
        yield put(
          AuthExperienceActions.validateAuthToken(onLoginSuccess)
        )
        break
      }
      case HTTP_RESPONSE_CODES.UNAUTHORIZED: {
        // auth code was invalid. a new prefix has been received
        const { authCodePrefix } = result.data
        // persist- and fetch the new prefix
        yield put(
          AuthExperienceActions.persistAuthCodePrefix(authCodePrefix)
        )
        yield put(
          AuthExperienceActions.fetchAuthCodePrefix()
        )
        yield put(
          ErrorActions.trigger(
            new OLError('[AuthExperienceSaga->validateAuthCode]: Invalid auth code', 'Invalid auth code. A new code has been sent.'),
            false,
            true,
            false,
            false
          )
        )
        break
      }
      case HTTP_RESPONSE_CODES.FORBIDDEN: {
        /** accessing the auth code validation endpoint is forbidden(validating auth code has failed many times or
         * the first step was completed a long time ago...) */
        // clear the auth code prefix allowing the 2fa to restart from step 1
        yield put(
          AuthExperienceActions.clearAuthCodePrefix()
        )
        yield put(
          ErrorActions.trigger(
            new OLError('[AuthExperienceSaga->validateAuthCode]: Invalid auth code', 'Validating authentication code has been forbidden. You have to authenticate again.'),
            true,
            true,
            true,
            false
          )
        )
        break
      }
      default:
        throw new Error('Unsuccessful auth code validation')
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[AuthExperienceSaga->validateAuthCode]: Failed to validate auth code', 'Failed to validate auth code', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * persistAuthToken (action) {
  const fetchId = 'AuthExperienceSaga.persistAuthToken'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { authToken, rememberToken } } = action
    yield AuthService.PersistAuthToken(authToken, rememberToken)
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[AuthExperienceSaga->persistAuthToken]: Failed to persist the logged-in user\'s auth token', '', e),
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * validateAuthToken (action) {
  const fetchId = 'AuthExperienceSaga.validateAuthToken'

  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    // trying to validate an existing persisted auth token
    const persistedToken = yield AuthService.GetAuthToken()
    if (persistedToken) {
      const { data: { onValidationSuccess } } = action
      // authorize http client with the persisted token so it can be used for validating the token with ProfileService.GetProfileInfo
      // - clear any previous authorizations because old tokens shouldn't be used for authenticating requests
      unAuthorizeHttpClient()
      // - authorize client
      authorizeHttpClient(persistedToken)
      // check token validity on the server by fetching the profile info of the logged-in user
      const result = yield ProfileService.GetProfileInfo()
      // if profile info is successfully fetched
      if (result.status === HTTP_RESPONSE_CODES.SUCCESS) {
        const normalizedData = normalize(result.data, RESTModelNormalizationSchemas.profile.schema)
        const profile = StoreProfile.FromNormalizedRESTModel(
          normalizedData.entities[RESTModelNormalizationSchemas.profile.entityName][normalizedData.result]
        )
        if (profile.photoId) {
          yield put(
            DataStoresActions.mergeEntitiesIntoStores({
              [RESTModelNormalizationSchemas.image.entityName]: StoreImage.FromNormalizedRESTModel(
                normalizedData.entities[RESTModelNormalizationSchemas.image.entityName][profile.photoId]
              ).toJSON()
            })
          )
        }
        if (profile.theme) { // change current theme to the theme selected by the user
          yield put(
            ThemeActions.selectTheme(profile.theme)
          )
        }
        // store profile info in the store
        yield put(
          AuthExperienceActions.setProfile(
            profile.toJSON()
          )
        )
        // token is valid so the user is authenticated
        yield put(
          AuthExperienceActions.setIsAuthenticated(true)
        )
        // execute onValidationSuccess callback
        onValidationSuccess && onValidationSuccess()
      }
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[AuthExperienceSaga->validateAuthToken]: Failed to validate auth token', 'Authentication failed', e),
        true,
        false,
        false,
        false
      )
    )
    // if validation has failed, delete the stored auth token from everywhere
    yield put(
      AuthExperienceActions.removeAuthToken()
    )
  }
  // set authentication checked flag, because authentication check has been finished
  yield put(
    AuthExperienceActions.setAuthenticationChecked(true)
  )
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * removeAuthToken () {
  const fetchId = 'AuthExperienceSaga.removeAuthToken'

  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))
    // remove persisted auth token
    yield AuthService.RemoveAuthToken()
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[AuthExperienceSaga->removeAuthToken]: Failed to remove auth token', '', e),
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * fetchAuthCodePrefix (action) {
  const fetchId = 'AuthExperienceSaga.fetchAuthCodePrefix'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const result = yield AuthService.GetAuthCodePrefix()
    if (result) {
      yield put(AuthExperienceActions.setAuthCodePrefix(result))
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[AuthExperienceSaga->fetchAuthCodePrefix]: Failed to fetch auth code prefix', 'Failed to fetch auth code prefix', e),
        false,
        false,
        false,
        false
      )
    )
  }
  yield put(AuthExperienceActions.setAuthCodePrefixFetched(true))
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * persistAuthCodePrefix (action) {
  const fetchId = 'AuthExperienceSaga.persistAuthCodePrefix'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { authCodePrefix } } = action
    yield AuthService.PersistAuthCodePrefix(authCodePrefix)
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[AuthExperienceSaga->persistAuthCodePrefix]: Failed to persist the auth code prefix', '', e),
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * clearAuthCodePrefix () {
  const fetchId = 'AuthExperienceSaga.clearAuthCodePrefix'

  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))
    // remove persisted auth code prefix
    yield AuthService.RemoveAuthCodePrefix()
    yield put(AuthExperienceActions.setAuthCodePrefix(null))
    // reset auth code prefix fetched state
    yield put(AuthExperienceActions.setAuthCodePrefixFetched(false))
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[AuthExperienceSaga->clearAuthCodePrefix]: Failed to clear auth code prefix', '', e),
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * editProfile (action) {
  const fetchId = 'AuthExperienceSaga.editProfile'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { profile, onSuccess } } = action

    const result = yield ProfileService.Update(profile.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['EDIT_PROFILE_SUCCESS', 'Profile successfully updated'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[AuthExperienceSaga->editProfile]: Failed to update profile', 'Failed to update profile', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * AuthExperienceSaga () {
  yield takeLatest(AUTH_EXPERIENCE_TYPES.LOG_IN, logIn)
  yield takeLatest(AUTH_EXPERIENCE_TYPES.LOG_OUT, logOut)
  yield takeLatest(AUTH_EXPERIENCE_TYPES.AUTH0_LOGIN, auth0Login)
  yield takeLatest(AUTH_EXPERIENCE_TYPES.AUTH0_LOGOUT, auth0Logout)
  yield takeLatest(AUTH_EXPERIENCE_TYPES.VALIDATE_AUTH0_LOGIN, validateAuth0Login)
  yield takeLatest(AUTH_EXPERIENCE_TYPES.VERIFY_AUTHENTICATION, verifyAuthentication)
  yield takeLatest(AUTH_EXPERIENCE_TYPES.VALIDATE_AUTH_CODE, validateAuthCode)
  yield takeLatest(AUTH_EXPERIENCE_TYPES.PERSIST_AUTH_TOKEN, persistAuthToken)
  yield takeLatest(AUTH_EXPERIENCE_TYPES.VALIDATE_AUTH_TOKEN, validateAuthToken)
  yield takeLatest(AUTH_EXPERIENCE_TYPES.REMOVE_AUTH_TOKEN, removeAuthToken)
  yield takeLatest(AUTH_EXPERIENCE_TYPES.FETCH_AUTH_CODE_PREFIX, fetchAuthCodePrefix)
  yield takeLatest(AUTH_EXPERIENCE_TYPES.PERSIST_AUTH_CODE_PREFIX, persistAuthCodePrefix)
  yield takeLatest(AUTH_EXPERIENCE_TYPES.CLEAR_AUTH_CODE_PREFIX, clearAuthCodePrefix)
  yield takeLatest(AUTH_EXPERIENCE_TYPES.EDIT_PROFIlE, editProfile)
}

export default AuthExperienceSaga
