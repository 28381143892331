import DeviceOutputService from '../../../services/DeviceOutput'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const DeviceOutputDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'deviceOutput',
  DeviceOutputService,
  RESTModelNormalizationSchemas.deviceOutput.schema,
  [
    RESTModelNormalizationSchemas.deviceOutput.entityName,
    RESTModelNormalizationSchemas.deviceModelOutput.entityName,
    RESTModelNormalizationSchemas.predefinedEvent.entityName
  ]
)

export default DeviceOutputDataStoreSagaHandlers
