import { makeActionCreator } from '../../index'

/**
 * Creates actions for list data fetch management.
 * @param listManagementTypes {{FETCH: string, SET_IS_LOADING:string, SET_LIST_DATA:string, RESET_LIST_DATA:string}}
 * @returns {{fetch: (function(int, int, SortParameter[], FilterParameter[], FilterExpression.EXPRESSION_TYPE): object), setIsLoading: (function(boolean): object), setData: (function([], int, boolean): object), reset: (function(): object)}}
 */
const createListDataFetchManagementActions = listManagementTypes => ({
  fetch: makeActionCreator(listManagementTypes.FETCH, 'limit', 'page', 'sort', 'filters', 'filtersLinkingType'),
  setIsLoading: makeActionCreator(listManagementTypes.SET_IS_LOADING, 'isLoading'),
  setData: makeActionCreator(listManagementTypes.SET_LIST_DATA, 'data', 'totalNumberOfItems', 'isLoading'),
  reset: makeActionCreator(listManagementTypes.RESET_LIST_DATA)
})

export default createListDataFetchManagementActions
