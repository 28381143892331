import ZoneDataStoreSelectors from '../selectors/data/Zones'
import ZoneDataStoreSlice from '../slices/data/ZoneDataStoreSlice'
import StoreZone from '../models/Zone'
import useDataStoreItems from './helpers/useDataStoreItems'

const JSONStoreZoneToStoreZone = JSONStoreZone => StoreZone.FromJSON(JSONStoreZone)

/**
 * Returns requested-/all zone(s) from state.data.zones.
 * @param zoneIds {string | string[]}: id of requested zones.
 * @param asMap {boolean}: when multiple zones are requested, return zones as an object mapping zones by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all items, when no itemId is passed.
 * @returns {StoreZone | StoreZone[]}
 */
const useNormalizedZones = (zoneIds = null, asMap = false, fetchAllEnabled = false) => useDataStoreItems(
  zoneIds,
  ZoneDataStoreSelectors.zones,
  ZoneDataStoreSlice.actions.fetch,
  JSONStoreZoneToStoreZone,
  asMap,
  fetchAllEnabled
)

export default useNormalizedZones
