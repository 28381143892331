import FormManagementFormListSlice from '../../slices/experiences/FormManagementFormListSlice'
import FORM_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/FormManagement'
import { makeActionCreator } from '../../../modules/utilities/store'

const FormManagementExperienceActions = {
  list: FormManagementFormListSlice.actions,
  /**
   * @param formItem {StoreForm}: StoreForm instance containing edited form data.
   * @param onSuccess {function}: executed if creation was successful.
   * @returns {{ type: string, data: { formItem: StoreForm, onSuccess: function } }}
   */
  create: makeActionCreator(FORM_MANAGEMENT_EXPERIENCE_TYPES.CREATE, 'formItem', 'onSuccess'),
  /**
   * @param formItem {StoreForm}: StoreForm instance containing edited form data.
   * @param onSuccess {function}: executed if editing was successful.
   * @returns {{ type: string, data: { formItem: StoreForm, onSuccess: function } }}
   */
  edit: makeActionCreator(FORM_MANAGEMENT_EXPERIENCE_TYPES.EDIT, 'formItem', 'onSuccess'),
  /**
   * @param formId {string}: id of form the "enabled" attribute will be updated for.
   * @param enabled {boolean}: the value the form's "enabled" attribute will be updated with.
   * @returns {{ type: string, data: { formId: string, enabled: boolean } }}
   */
  updateEnabledAttribute: makeActionCreator(FORM_MANAGEMENT_EXPERIENCE_TYPES.UPDATE_ENABLED_ATTRIBUTE, 'formId', 'enabled'),
  /**
   * @param formId {string}: id of form to delete.
   * @returns {{ type: string, data: { formId: string } }}
   */
  delete: makeActionCreator(FORM_MANAGEMENT_EXPERIENCE_TYPES.DELETE, 'formId')
}

export default FormManagementExperienceActions
