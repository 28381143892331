import DeviceModelAttributeService from '../../../services/DeviceModelAttribute'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'

const DeviceModelAttributeDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'deviceModelAttribute',
  DeviceModelAttributeService,
  RESTModelNormalizationSchemas.deviceModelAttribute.schema,
  [RESTModelNormalizationSchemas.deviceModelAttribute.entityName]
)

export default DeviceModelAttributeDataStoreSagaHandlers
