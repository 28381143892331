import useDataStoreItemsFetchedOnce from './helpers/useDataStoreItemsFetchedOnce'
import ZoneShapeTypeDataStoreSelectors from '../selectors/data/ZoneShapeTypes'
import ZoneShapeTypeDataStoreSlice from '../slices/data/ZoneShapeTypeDataStoreSlice'
import StoreZoneShapeType from '../models/ZoneShapeType'

const JSONStoreZoneShapeTypeToStoreZoneShapeType = JSONStoreZoneShapeType => StoreZoneShapeType.FromJSON(JSONStoreZoneShapeType)

/**
 * Returns requested-/all zone shape type(s) from state.data.zoneShapeTypes.
 * @param zoneShapeTypeIds {string | string[] | null}: id of requested zone shape types. If null, all zone shape types
 * are requested.
 * @param asMap {boolean}: when multiple zone shape types are requested, return zone shape types as an object mapping
 * zone shape types by their id.
 * @returns {StoreZoneShapeType | StoreZoneShapeType[]}
 */
const useNormalizedZoneShapeTypes = (zoneShapeTypeIds = null, asMap = false) => useDataStoreItemsFetchedOnce(
  zoneShapeTypeIds,
  ZoneShapeTypeDataStoreSelectors.zoneShapeTypes,
  ZoneShapeTypeDataStoreSlice.actions.fetch,
  JSONStoreZoneShapeTypeToStoreZoneShapeType,
  asMap
)

export default useNormalizedZoneShapeTypes
