import useDataStoreItems from './helpers/useDataStoreItems'
import DeviceDataStoreSelectors from '../selectors/data/Devices'
import DeviceDataStoreSlice from '../slices/data/DeviceDataStoreSlice'
import StoreDevice from '../models/Device'

const JSONStoreDeviceToStoreDevice = JSONStoreDevice => StoreDevice.FromJSON(JSONStoreDevice)

/**
 * Returns requested-/all device(s) from state.data.devices.
 * @param deviceIds {string | string[]}: id of requested devices.
 * @param asMap {boolean}: when multiple devices are requested, return devices as an object mapping devices by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all devices, when no device id is passed.
 * @returns {StoreDevice | StoreDevice[]}
 */
const useNormalizedDevices = (deviceIds = null, asMap = false, fetchAllEnabled = false) => useDataStoreItems(
  deviceIds,
  DeviceDataStoreSelectors.devices,
  DeviceDataStoreSlice.actions.fetch,
  JSONStoreDeviceToStoreDevice,
  asMap,
  fetchAllEnabled
)

export default useNormalizedDevices
