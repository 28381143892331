import { put, takeLatest } from 'redux-saga/effects'
import PredefinedEventService from '../../../services/PredefinedEvent'
import ListMetaData from '../../../modules/utilities/list/ListMetaData'
import FilterExpression from '../../../modules/utilities/list/FilterExpression'
import PredefinedEventDataStoreSlice from '../../slices/data/PredefinedEventDataStoreSlice'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import PredefinedEventManagementExperienceActions from '../../actions/experiences/PredefinedEventManagement'
import PREDEFINED_EVENT_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/PredefinedEventManagement'
import LayoutActions from '../../actions/Layout'
import PredefinedEventManagementPredefinedEventListSlice
  from '../../slices/experiences/PredefinedEventManagementPredefinedEventListSlice'
import MessageActions from '../../actions/Messages'
import { AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT, DISPLAYED_MESSAGE_TYPES } from '../../../constants/general'

function * fetchPredefinedEvents (action) {
  try {
    const { data: { limit, page, sort, filters, filtersLinkingType } } = action
    yield put(PredefinedEventManagementExperienceActions.list.setIsLoading(true))

    const result = yield PredefinedEventService.FindMany(
      new ListMetaData(
        limit,
        page,
        sort,
        new FilterExpression(
          filtersLinkingType,
          ...filters
        )
      )
    )
    yield put(
      PredefinedEventDataStoreSlice.actions.fetchResult(
        result,
        function * (storeValues) {
          yield put(
            PredefinedEventManagementExperienceActions.list.setData(storeValues.result, storeValues.totalNrOfItems, false)
          )
        }
      )
    )
  } catch (e) {
    yield put(PredefinedEventManagementExperienceActions.list.setIsLoading(false))
    yield put(ErrorActions.trigger(
      new OLError('[PredefinedEventManagementExperience->fetchPredefinedEvents]: Failed to fetch predefined events',
        'Failed to fetch predefined events', e),
      true,
      true,
      true,
      false
    ))
  }
}

function * createPredefinedEvent (action) {
  const fetchId = 'PredefinedEventManagementExperienceSaga.createPredefinedEvent'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { predefinedEventItem, onSuccess } } = action

    const result = yield PredefinedEventService.Create(predefinedEventItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['CREATE_PREDEFINED_EVENT_SUCCESS', 'PredefinedEvent successfully created'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[PredefinedEventManagementExperience->createPredefinedEvent]: Failed to create predefined event',
          'Failed to create predefined event', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * editPredefinedEvent (action) {
  const fetchId = 'PredefinedEventManagementExperienceSaga.editPredefinedEvent'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { predefinedEventItem, onSuccess } } = action

    const result = yield PredefinedEventService.Update(predefinedEventItem.id, predefinedEventItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['EDIT_PREDEFINED_EVENT_SUCCESS', 'PredefinedEvent successfully updated'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[PredefinedEventManagementExperience->editPredefinedEvent]: Failed to update predefined event',
          'Failed to update predefined event', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * deletePredefinedEvent (action) {
  const fetchId = 'PredefinedEventManagementExperienceSaga.deletePredefinedEvent'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { predefinedEventId } } = action

    const result = yield PredefinedEventService.Delete(predefinedEventId)
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['DELETE_PREDEFINED_EVENT_SUCCESS', 'PredefinedEvent deleted'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[PredefinedEventManagementExperience->deletePredefinedEvent]: Failed to delete predefined event',
          'Failed to delete predefined event', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * PredefinedEventManagementExperienceSaga () {
  yield takeLatest(PredefinedEventManagementPredefinedEventListSlice.types.FETCH, fetchPredefinedEvents)
  yield takeLatest(PREDEFINED_EVENT_MANAGEMENT_EXPERIENCE_TYPES.CREATE, createPredefinedEvent)
  yield takeLatest(PREDEFINED_EVENT_MANAGEMENT_EXPERIENCE_TYPES.EDIT, editPredefinedEvent)
  yield takeLatest(PREDEFINED_EVENT_MANAGEMENT_EXPERIENCE_TYPES.DELETE, deletePredefinedEvent)
}

export default PredefinedEventManagementExperienceSaga
