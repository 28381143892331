class StoreDeviceModel {
  constructor (
    id = undefined,
    name,
    inputIds,
    outputIds,
    hasRemoteListeningCapability,
    hasSMSCommandCapability,
    smsCommandPrefix
  ) {
    this.id = id
    this.name = name
    this.inputIds = inputIds
    this.outputIds = outputIds
    this.hasRemoteListeningCapability = hasRemoteListeningCapability
    this.hasSMSCommandCapability = hasSMSCommandCapability
    this.smsCommandPrefix = smsCommandPrefix
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      name: this.name,
      inputIds: this.inputIds,
      outputIds: this.outputIds,
      hasRemoteListeningCapability: this.hasRemoteListeningCapability,
      hasSMSCommandCapability: this.hasSMSCommandCapability,
      smsCommandPrefix: this.smsCommandPrefix
    })
  }

  static FromNormalizedRESTModel ({
    id,
    name,
    inputIds,
    outputIds,
    hasRemoteListeningCapability,
    hasSMSCommandCapability,
    smsCommandPrefix
  }) {
    return new StoreDeviceModel(
      id,
      name,
      inputIds,
      outputIds,
      hasRemoteListeningCapability,
      hasSMSCommandCapability,
      smsCommandPrefix
    )
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name,
      inputIds: this.inputIds,
      outputIds: this.outputIds,
      hasRemoteListeningCapability: this.hasRemoteListeningCapability,
      hasSMSCommandCapability: this.hasSMSCommandCapability,
      smsCommandPrefix: this.smsCommandPrefix
    }
  }

  static FromJSON ({
    id,
    name,
    inputIds,
    outputIds,
    hasRemoteListeningCapability,
    hasSMSCommandCapability,
    smsCommandPrefix
  }) {
    return new StoreDeviceModel(
      id,
      name,
      inputIds,
      outputIds,
      hasRemoteListeningCapability,
      hasSMSCommandCapability,
      smsCommandPrefix
    )
  }
}

export default StoreDeviceModel
