import UserActionService from '../../../services/UserAction'
import RESTModelNormalizationSchemas from '../../models/RESTModelNormalizationSchemas'
import createDataStoreSliceFetchHandlers from './helpers/createDataStoreFetchHandlers'
import FilterParameter from '../../../modules/utilities/list/FilterParameter'

const UserActionDataStoreSagaHandlers = createDataStoreSliceFetchHandlers(
  'userAction',
  UserActionService,
  RESTModelNormalizationSchemas.userAction.schema,
  [
    RESTModelNormalizationSchemas.userAction.entityName,
    RESTModelNormalizationSchemas.userActionType.entityName,
    RESTModelNormalizationSchemas.loginUserActionDetail.entityName
  ],
  'id',
  action => {
    const {
      data: {
        extraParams: {
          userIds,
          actionTypeNames
        }
      }
    } = action
    const extraParams = []
    userIds && extraParams.push(FilterParameter.CONTAINS('userId', userIds))
    actionTypeNames && extraParams.push(FilterParameter.CONTAINS('actionTypeNames', actionTypeNames))
  }
)

export default UserActionDataStoreSagaHandlers
