import DeviceManagementDeviceListSlice from '../../slices/experiences/DeviceManagementDeviceListSlice'
import DEVICE_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/DeviceManagement'
import { makeActionCreator } from '../../../modules/utilities/store'
import DeviceManagementDevicePositionsSlice from '../../slices/experiences/DeviceManagementDevicePositionsSlice'
import DeviceManagementDeviceListExportSlice from '../../slices/experiences/DeviceManagementDeviceListExportSlice'
import DeviceManagementDevicePositionsExportSlice
  from '../../slices/experiences/DeviceManagementDevicePositionsExportSlice'

const DeviceManagementExperienceActions = {
  list: DeviceManagementDeviceListSlice.actions,
  listExport: DeviceManagementDeviceListExportSlice.actions,
  /**
   * @param deviceItem {StoreDevice}: StoreDevice instance containing edited device data.
   * @param onSuccess {function}: executed if creation was successful.
   * @returns {{ type: string, data: { deviceItem: StoreDevice, onSuccess: function } }}
   */
  create: makeActionCreator(DEVICE_MANAGEMENT_EXPERIENCE_TYPES.CREATE, 'deviceItem', 'onSuccess'),
  /**
   * @param deviceItem {StoreDevice}: StoreDevice instance containing edited device data.
   * @param onSuccess {function}: executed if editing was successful.
   * @returns {{ type: string, data: { deviceItem: StoreDevice, onSuccess: function } }}
   */
  edit: makeActionCreator(DEVICE_MANAGEMENT_EXPERIENCE_TYPES.EDIT, 'deviceItem', 'onSuccess'),
  /**
   * @param deviceId {string}: id of device to delete.
   * @returns {{ type: string, data: { deviceId: string } }}
   */
  delete: makeActionCreator(DEVICE_MANAGEMENT_EXPERIENCE_TYPES.DELETE, 'deviceId'),
  devicePositions: DeviceManagementDevicePositionsSlice.actions,
  devicePositionsExport: DeviceManagementDevicePositionsExportSlice.actions,
  /**
   * @param deviceId {string}: ID of device the control panel is opened for.
   * @returns {{ type: string, data: { deviceId: string } }}
   */
  openDeviceControlPanel: makeActionCreator(DEVICE_MANAGEMENT_EXPERIENCE_TYPES.OPEN_DEVICE_CONTROL_PANEL, 'deviceId'),
  /**
   * @param deviceId {string}: Id of device the control panel is hidden for.
   * @returns {{ type: string, data: { deviceId: string } }}
   */
  closeDeviceControlPanel: makeActionCreator(DEVICE_MANAGEMENT_EXPERIENCE_TYPES.CLOSE_DEVICE_CONTROL_PANEL, 'deviceId'),
  /**
   * @param deviceId {string}: Id of device the output is sent to.
   * @param deviceOutputId {string}: Id of the activated(sent) device output.
   * @returns {{ type: string, data: { deviceId: string, deviceOutputId: string } }}
   */
  sendOutputToDevice: makeActionCreator(DEVICE_MANAGEMENT_EXPERIENCE_TYPES.SEND_OUTPUT_TO_DEVICE, 'deviceId', 'deviceOutputId'),
  /**
   * @param deviceId {string}: Id of the listened device.
   * @param listeningPhoneNumber {string}: Phone number on which the device can be listened.
   * @returns {{ type: string, data: { deviceId: string, listeningPhoneNumber: string } }}
   */
  listenDevice: makeActionCreator(DEVICE_MANAGEMENT_EXPERIENCE_TYPES.LISTEN, 'deviceId', 'listeningPhoneNumber'),
  /**
   * @param deviceId {string}: Id of the updated device.
   * @param isActive {boolean}: Updated value of the "isActive" attribute.
   * @returns {{ type: string, data: { deviceId: string, isActive: boolean } }}
   */
  updateAttributeActive: makeActionCreator(DEVICE_MANAGEMENT_EXPERIENCE_TYPES.UPDATE_ATTRIBUTE_ACTIVE, 'deviceId', 'isActive'),
  /**
   * @param deviceId {string}: Id of the device the user will be detached from.
   * @returns {{ type: string, data: { deviceId: string } }}
   */
  detachUser: makeActionCreator(DEVICE_MANAGEMENT_EXPERIENCE_TYPES.DETACH_USER, 'deviceId'),
  /**
   * @param deviceId {string}: Id of the device the command will be sent to.
   * @param command {string}: Sent command.
   * @returns {{ type: string, data: { deviceId: string, command: string } }}
   */
  sendCommand: makeActionCreator(DEVICE_MANAGEMENT_EXPERIENCE_TYPES.SEND_COMMAND, 'deviceId', 'command'),
  /**
   * @param deviceCoordinateId {string}: id of the device coordinate the location string will be fetched for.
   * @returns {{ type: string, data: { deviceCoordinateId: string } }}
   */
  fetchLocationStringForCoordinate: makeActionCreator(
    DEVICE_MANAGEMENT_EXPERIENCE_TYPES.FETCH_LOCATION_STRING_FOR_COORDINATE,
    'deviceCoordinateId'
  )
}

export default DeviceManagementExperienceActions
