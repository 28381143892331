import createDataStoreSlice from '../../../modules/utilities/store/slices/dataStore'
import ImageDataStoreSagaHandlers from '../../sagas/data/ImageDataStore'

const sliceName = 'imagesStore'

const ImageDataStoreSlice = createDataStoreSlice(
  sliceName,
  undefined,
  ImageDataStoreSagaHandlers
)

export default ImageDataStoreSlice
