import { makeActionCreator } from '../../../modules/utilities/store'
import CHAT_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/ChatManagement'
import ChatManagementMessageListSlice from '../../slices/experiences/ChatManagementMessageListSlice'
import ChatManagementChatGroupListSlice from '../../slices/experiences/ChatManagementChatGroupListSlice'

const ChatManagementExperienceActions = {
  messageList: ChatManagementMessageListSlice.actions,
  chatGroupList: ChatManagementChatGroupListSlice.actions,
  /**
   * @param messageItem {StoreMessage}: StoreMessage instance containing created message data.
   * @param onSuccess {function}: executed if creation was successful.
   * @returns {{ type: string, data: { messageItem: StoreMessage, onSuccess: function } }}
   */
  create: makeActionCreator(CHAT_MANAGEMENT_EXPERIENCE_TYPES.CREATE_MESSAGE, 'messageItem', 'onSuccess'),
  /**
   * @param messageCategoryItem {StoreMessageCategory}: StoreMessageCategory instance containing edited message category
   * data.
   * @param onSuccess {function}: executed if editing was successful.
   * @returns {{ type: string, data: { messageCategoryItem: StoreMessageCategory, onSuccess: function } }}
   */
  editMessageCategory: makeActionCreator(
    CHAT_MANAGEMENT_EXPERIENCE_TYPES.EDIT_MESSAGE_CATEGORY,
    'messageCategoryItem',
    'onSuccess'
  ),
  /**
   * @param chatGroupItem {StoreChatGroup}: StoreChatGroup instance containing edited chat group data.
   * @param onSuccess {function}: executed if creation was successful.
   * @returns {{ type: string, data: { chatGroupItem: StoreChatGroup, onSuccess: function } }}
   */
  createChatGroup: makeActionCreator(CHAT_MANAGEMENT_EXPERIENCE_TYPES.CREATE_CHAT_GROUP, 'chatGroupItem', 'onSuccess'),
  /**
   * @param chatGroupItem {StoreChatGroup}: StoreChatGroup instance containing edited chat group data.
   * @param onSuccess {function}: executed if editing was successful.
   * @returns {{ type: string, data: { chatGroupItem: StoreChatGroup, onSuccess: function } }}
   */
  editChatGroup: makeActionCreator(CHAT_MANAGEMENT_EXPERIENCE_TYPES.EDIT_CHAT_GROUP, 'chatGroupItem', 'onSuccess'),
  /**
   * @param chatGroupId {string}: id of chatGroup to delete.
   * @returns {{ type: string, data: { chatGroupId: string } }}
   */
  deleteChatGroup: makeActionCreator(CHAT_MANAGEMENT_EXPERIENCE_TYPES.DELETE_CHAT_GROUP, 'chatGroupId')
}

export default ChatManagementExperienceActions
