import { put, takeLatest } from 'redux-saga/effects'
import FamilyService from '../../../services/Family'
import ErrorActions from '../../actions/Error'
import OLError from '../../../modules/errorHandling/models/OLError'
import FAMILY_MANAGEMENT_EXPERIENCE_TYPES from '../../types/experiences/FamilyManagement'
import LayoutActions from '../../actions/Layout'
import MessageActions from '../../actions/Messages'
import { AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT, DISPLAYED_MESSAGE_TYPES } from '../../../constants/general'

function * createFamily (action) {
  const fetchId = 'FamilyManagementExperienceSaga.createFamily'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { familyItem, onSuccess } } = action

    const result = yield FamilyService.Create(familyItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['CREATE_FAMILY_SUCCESS', 'Family successfully created'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[FamilyManagementExperience->createFamily]: Failed to create family', 'Failed to create family', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * editFamily (action) {
  const fetchId = 'FamilyManagementExperienceSaga.editFamily'
  try {
    yield put(LayoutActions.setIsLoading(true, fetchId))

    const { data: { familyItem, onSuccess } } = action

    const result = yield FamilyService.Update(familyItem.id, familyItem.toNormalizedRESTModel())
    if (result.status === 200) {
      // display success message
      yield put(
        MessageActions.add(
          DISPLAYED_MESSAGE_TYPES.SUCCESS,
          undefined,
          ['EDIT_FAMILY_SUCCESS', 'Family successfully updated'],
          AUTO_HIDE_MESSAGE_AFTER_SEC_DEFAULT
        )
      )
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(
      ErrorActions.trigger(
        new OLError('[FamilyManagementExperience->editFamily]: Failed to update family', 'Failed to update family', e),
        true,
        true,
        true,
        false
      )
    )
  }
  yield put(LayoutActions.setIsLoading(false, fetchId))
}

function * FamilyManagementExperienceSaga () {
  yield takeLatest(FAMILY_MANAGEMENT_EXPERIENCE_TYPES.CREATE, createFamily)
  yield takeLatest(FAMILY_MANAGEMENT_EXPERIENCE_TYPES.EDIT, editFamily)
}

export default FamilyManagementExperienceSaga
