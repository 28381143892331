class StoreDeviceCoordinate {
  constructor (
    id = null,
    date = undefined,
    lat,
    lng,
    speed,
    direction,
    horizontalAccuracy,
    locationString,
    deviceId
  ) {
    this.id = id
    this.date = new Date(date)
    this.lat = lat
    this.lng = lng
    this.speed = speed
    this.direction = direction
    this.horizontalAccuracy = horizontalAccuracy
    this.locationString = locationString
    this.deviceId = deviceId
    this.device = null
  }

  toNormalizedRESTModel () {
    return ({
      // id is read-only
      // date is read-only
      lat: this.lat,
      lng: this.lng,
      speed: this.speed,
      direction: this.direction,
      horizontalAccuracy: this.horizontalAccuracy
      // locationString is read-only
      // deviceId is read-only
    })
  }

  static FromNormalizedRESTModel ({
    id,
    date,
    lat,
    lng,
    speed,
    direction,
    horizontalAccuracy,
    locationString,
    deviceId
  }) {
    return new StoreDeviceCoordinate(
      id,
      date,
      lat,
      lng,
      speed,
      direction,
      horizontalAccuracy,
      locationString,
      deviceId
    )
  }

  toJSON () {
    return {
      id: this.id,
      date: this.date,
      lat: this.lat,
      lng: this.lng,
      speed: this.speed,
      direction: this.direction,
      horizontalAccuracy: this.horizontalAccuracy,
      locationString: this.locationString,
      deviceId: this.deviceId
    }
  }

  static FromJSON ({
    id,
    date,
    lat,
    lng,
    speed,
    direction,
    horizontalAccuracy,
    locationString,
    deviceId
  }) {
    return new StoreDeviceCoordinate(
      id,
      date,
      lat,
      lng,
      speed,
      direction,
      horizontalAccuracy,
      locationString,
      deviceId
    )
  }
}

export default StoreDeviceCoordinate
